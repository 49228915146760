import axios from 'axios';
import environment from '../../environment';

export const searchBarService = {
    searchByDescription,
};

 function searchByDescription(description: string) {
    return axios.get(`${environment.apiUri}/item/SearchDescription/${description}`)
  };
