import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { Offer } from '../models/offer';
import { SkeletonComponent } from '../../../components/SkeletonComponet';
import { offerActions } from '../actions/offer.actions';
import { OfferType } from '../models/offer_type';
import { TypeItemOffer } from '../models/type_item_offer';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DialogSelectItem } from '../../items/components/dialog.select-item';
import { Item } from '../../items/models/item';
import { itemActions } from '../../items/actions/item.actions';
import { OfferDetail } from '../models/offer_detail';
import { InputNumber } from 'primereact/inputnumber';
import { User } from '../../settings/users/models/user';
import { DialogProps } from '../../../models/dialog.props';
import { DialogSelectCategory } from '../../category/components/dialog.select-category';
import { Category } from '../../category/models/category';
import { categoryActions } from '../../category/actions/category.actions';
import { DialogSelectProductGroup } from '../../category/components/dialog.select-product_group';
import { ProductGroup } from '../../category/models/product_group';
import { TemplatePaginator } from '../../../components/TemplatePaginator';

  function DialogOffer(props: DialogProps<string>) {
    const dispatch = useDispatch();    
    const { onClose} = props;
    const openOffer  = useSelector((state: any) => state.offers.openOffer as DataDialogProps<Offer>);
    const offerTypes: OfferType[] = useSelector((state:any) => state.offers.offerTypes as OfferType[]);
    const typeItemOffers: TypeItemOffer[] = useSelector((state:any) => state.offers.typeItemOffers as TypeItemOffer[]);
    const user  = useSelector((state: any) => state.authentication.user as User);
    const [inputs, setInputs] = React.useState<Offer>(openOffer?.select);
    const [submitted, setSubmitted] = React.useState(false);
    const loadingTypeItem: boolean= useSelector((state:any) => state.offers.loadingTypeItem);
    

    const handleClose = () => {
      setInputs(new Offer())
      dispatch(offerActions.openModalOffer({
        open : false,
        select : new Offer(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
        onClose!("Adios");
    };

    useEffect(() => { 
        dispatch(offerActions.getOfferType()); 
        dispatch(offerActions.getTypeItem()); 
    },[]);

    useEffect(() => { 
       setInputs(openOffer?.select);
    },[openOffer?.select]);

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.description ) {//TODO 
          if(openOffer?.type ===0){
            inputs.userId = user.userId;
            dispatch(offerActions.add(inputs));
          }
          if(openOffer?.type ===1){
            dispatch(offerActions.edit(inputs));
          }
      }
    }

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
   }
    function handleChangeCheck(e:any) {
        const { name, checked } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: checked }));
    }
    const footer = (
        <div>
            <Button onClick={handleSubmit} label={openOffer?.type ===0 ? "Crear Oferta": "Editar Oferta"} icon="pi pi-check"/>
        </div>
    ); 
     const renderHeader = () => {
        let isNotSelect = !inputs.typeItemOfferId || inputs.typeItemOfferId ==0;
        let typeItem: string = "Item";
        if(!isNotSelect){
            typeItem = (typeItemOffers.find(x=> x.typeItemOfferId == inputs.typeItemOfferId)?.description??"").toString();
        }

        const handAddItem = () =>{
            if(inputs.typeItemOfferId == 1){//Articulo
                dispatch(itemActions.openModalSearchItem({
                    open : true,
                    select :[],
                    type : 0,
                    loading: false
                }));
            }
                if(inputs.typeItemOfferId == 2)//Product Group
                {
                dispatch(categoryActions.openModalSearchProductGroup({
                    open: true,
                    select: [],
                    type:0,
                    loading: false
                }))
            }
            if(inputs.typeItemOfferId == 3)//Category
            {
                dispatch(categoryActions.openModalSearchCategory({
                    open: true,
                    select: [],
                    type:0,
                    loading: false
                }))
            }

        }
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">{!isNotSelect? typeItem: "Seleccione un tipo de item"}</h5>
                    <Button style={{width: "205px"}} onClick={handAddItem} disabled={isNotSelect} label={"Agregar " + typeItem} icon="pi pi-plus" />
            </div>
        )
    }

    const onSelectItem = (item: Item)=>{

        let newItem = new OfferDetail({
            active: true,
            itemCode : item.itemId,
            lineNumber: 0,
            offerId: 0,
            description: item.name,
            percentDiscount:0,
            priceDiscount:0,
            priceGroup: "03",
            typeDiscountId: 1,
            typeItemOfferId: 1
        });
        let currentItem = (inputs?.detail??[]);
        let detail = [newItem, ...currentItem]
        setInputs(inputs => ({ ...inputs, ["detail"]: detail }));
    }
    const onSelectCategory = (category: Category)=>{

        let newItem = new OfferDetail({
            active: true,
            itemCode : category.categoryId,
            lineNumber: 0,
            offerId: 0,
            description: category.description,
            percentDiscount:0,
            priceDiscount:0,
            priceGroup: "03",
            typeDiscountId: 1,
            typeItemOfferId: 3
        });
        let currentItem = (inputs?.detail??[]);
        let detail = [newItem, ...currentItem]
        setInputs(inputs => ({ ...inputs, ["detail"]: detail }));
    }
    const onSelectProductGroup = (productGroup: ProductGroup)=>{

        let newItem = new OfferDetail({
            active: true,
            itemCode : productGroup.productGroupId,
            lineNumber: 0,
            offerId: 0,
            description: productGroup.description,
            percentDiscount:0,
            priceDiscount:0,
            priceGroup: "03",
            typeDiscountId: 1,
            typeItemOfferId: 2
        });
        let currentItem = (inputs?.detail??[]);
        let detail = [newItem, ...currentItem]
        setInputs(inputs => ({ ...inputs, ["detail"]: detail }));
    }

    const header = renderHeader();

    const typeBodyTemplate = (row:OfferDetail, options:any) => {
        let currenType = typeItemOffers.find(x=> x.typeItemOfferId == inputs.typeItemOfferId);
        function handleChangeItem(e:any) {
            const { value } = e.target;
            row.typeDiscountId = value;
            row.priceDiscount = 0;
            row.percentDiscount = 0;
            setInputs(inputs => ({ ...inputs, ["detail"]: inputs.detail }));
        }   
        return (
            <span className={options.className}>
                <Dropdown inputId="dropdownTypeItem" name="typeDiscountId" value={row.typeDiscountId} options={(currenType?.typeDiscounts??[])} onChange={handleChangeItem} optionLabel="description" optionValue="typeDiscountId" />
            </span>
        )
    }

    const percentBodyTemplate = (row:OfferDetail, options:any) => {
        function handleChangeItem(e:any) {
            const { value } = e.target;
            if(parseFloat(value) >=100){
                row.percentDiscount = 0;
            }
            else{
                row.percentDiscount = value;
            }
            row.priceDiscount = 0;
            setInputs(inputs => ({ ...inputs, ["detail"]: inputs.detail }));
        }   
        const onFocus = (event: any)=>{
            event.target.select();
        }
        return (
            <span className={options.className}>
                <InputText onFocus={onFocus} id="priceDiscount" disabled={row.typeDiscountId == 2} name="percentDiscount" type={"number"} value={(row.percentDiscount??0).toString()} onChange={handleChangeItem} />
            </span>
        )
    }


    const priceBodyTemplate = (row:OfferDetail, options:any) => {
        function handleChangeItem(e:any) {
            const { value } = e.target;
            row.priceDiscount = value;
            row.percentDiscount = 0;
            setInputs(inputs => ({ ...inputs, ["detail"]: inputs.detail }));
        }
        const onFocus = (event: any)=>{
            event.target.select();
        }
        return (
            <span className={options.className}>
                <InputNumber onFocus={onFocus} inputId="locale-us" disabled={row.typeDiscountId == 1} value={row.priceDiscount} onValueChange={handleChangeItem} mode="decimal" locale="en-US" minFractionDigits={2}/>
                {/* <InputText id="priceDiscount" disabled={row.typeDiscountId == 1} name="priceDiscount" type={"number"} value={row.priceDiscount} onChange={handleChangeItem} /> */}
            </span>
        )
    }

  
    return (
        <Dialog header={openOffer?.type ===0 ? "Agregar Oferta": "Editar Oferta : " + inputs.offerId } position="top" footer={footer} visible={openOffer?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '75vw'}}>
            <div>
                {openOffer.loading ? <SkeletonComponent/> :
                <div className="p-fluid grid  mt-3">
                    <div className="field col-12 md:col-12 xl:col-12">
                        <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                            <InputText id="description" name="description" value={inputs.description || ""} onChange={handleChange} />
                            <label htmlFor="description">Descripción</label>
                        </span>
                    </div>
                    <div className="field col-12 md:col-6 xl:col-3">
                            <label htmlFor="dropdownOfferType">Tipo de Oferta</label>
                            <Dropdown inputId="dropdownOfferType" name="offerTypeId" value={inputs.offerTypeId} options={offerTypes} onChange={handleChange} optionLabel="description" optionValue="offerTypeId" />
                    </div> 
                    <div className="field col-12 md:col-6 xl:col-3">
                            {loadingTypeItem? "Cargando": ""}
                            <label htmlFor="dropdownTypeItem">Tipo de Item:</label>

                            <Dropdown disabled={(inputs.detail??[]).length > 0} inputId="dropdownTypeItem" name="typeItemOfferId" value={inputs.typeItemOfferId} options={typeItemOffers} onChange={handleChange} optionLabel="description" optionValue="typeItemOfferId" />
                    </div> 
                    <div className="field col-12 md:col-6 xl:col-3">      
                        <label htmlFor="validFromDate">Valida Desde</label>
                        <Calendar id="validFromDate"  name="validFromDate"  value={ inputs?.validFromDate!= undefined ?  new Date(inputs?.validFromDate??"") :undefined} onChange={handleChange} showIcon />
                    </div>
                    <div className="field col-12 md:col-6 xl:col-3">
                        <label htmlFor="validToDate">Valida Hasta</label>
                        <Calendar id="validToDate" name="validToDate" value={ inputs?.validToDate!= undefined ?  new Date(inputs?.validToDate??"") :undefined} onChange={handleChange} showIcon />
                    </div>
                    {openOffer?.type ===1 ?
                        <React.Fragment>
                            <div className="field col-12 md:col-6 xl:col-4">
                                <i className="pi pi-users" />
                                    <label htmlFor="priority">Prioridad</label>
                                    <InputText id="priority" name="priority" disabled value={(inputs.priority||"").toString()} onChange={handleChange} />
                            </div>
                            <div className="field col-12 md:col-6 xl:col-4">
                                    <i className="pi pi-users" />
                                <label htmlFor="createdDate">Fecha Creación</label>
                                <Calendar id="createdDate" name="createdDate" disabled  value={ inputs?.createdDate!= undefined ?  new Date(inputs?.createdDate??"") : undefined} onChange={handleChange} />
                            </div>
                            <div className="field col-12 md:col-6 xl:col-4 mt-5">
                                <span className="p-float-label p-input-icon-left">
                                    <Checkbox inputId="cbActive" name="active" value="Activo" onChange={handleChangeCheck} checked={inputs.active??false}></Checkbox>
                                    <label htmlFor="cbActive" className="p-checkbox-label">Activo</label>
                                </span>
                            </div>
                        </React.Fragment>
                        : <div></div>
                    }
               
                    <div className="field col-12 md:col-12 xl:col-12">
                        <DataTable emptyMessage="Sin resultados" value={(inputs?.detail??[])} paginatorTemplate={TemplatePaginator as any}
                                responsiveLayout="scroll" paginator rows={6} size="small" stripedRows header={header} >
                                <Column sortable filter filterType="contains" field="itemCode" header="No"></Column>
                                <Column sortable filter filterType="contains" field="description" header="Descripción"></Column>
                                {/* <Column field="createdDate" header="Fecha Creación" body={dateBodyTemplate}></Column> */}
                                <Column sortable field="typeDiscountId" style={{width: "150px"}} header="Tipo de Oferta" body={typeBodyTemplate}></Column>
                                <Column field="percentDiscount" style={{width: "150px"}} header="Porcentaje Descuento" body={percentBodyTemplate}></Column>
                                <Column field="priceDiscount" style={{width: "150px"}} header="Precio Descuento" body={priceBodyTemplate}></Column>
                                {/* <Column field="active" body={statusBodyTemplate} header="Estado"></Column> */}
                                {/* <Column header="Acción" body={actionBodyTemplate} /> */}
                        </DataTable>
                    </div>
                   
                </div>
                }
            </div>
            <DialogSelectItem onClose={onSelectItem}></DialogSelectItem>
            <DialogSelectCategory onClose={onSelectCategory}></DialogSelectCategory>
            <DialogSelectProductGroup onClose={onSelectProductGroup}></DialogSelectProductGroup>
        </Dialog>
    );
  }
    
  export { DialogOffer };