import { authConstants } from "../constants/auth.constants";
import { AuthState } from "../models/authState";
import { User } from "../../pages/settings/users/models/user";
    let user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')?? ""): null;
    // const initialState = user ? { loggedIn: false, validId: 2, tempUser: undefined, user } : {};

const initialState: AuthState = { 
    loading: false, 
    loggedIn: false,
    validId: 0, 
    tempUser: new User(),
    user: user,
    openRestartExternal: {
        open: false,
        select: "",
        type:0,
        loading: false
    }
} ;
export function authentication(state = initialState, action:any) {
    switch (action.type) {
        case authConstants.OPEN_MODAL_RESTART_EXTERNAL:
            return {
                openRestartExternal: action.openRestartExternal,
            };

        case authConstants.LOGIN_REQUEST:
            return {
                loggingIn: false,
                loading: true,
                user: action.user
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                user: action.user
            };
        case authConstants.LOGIN_FAILURE:
            return {
                loading: false,
            };
        case authConstants.LOGOUT:
            return {
                loggingIn: false,
                validId: 0,
                loading: false,
            };

        case authConstants.VALIDATE_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                validId: 0,
                loading: true,
            };
        case authConstants.VALIDATE_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,   
                tempUser: action.user,
                validId: 1,
                loading: false,
            };
        case authConstants.VALIDATE_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                validId: 2
            };

        case authConstants.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loggingIn: false,
                loading: true,
            };
        case authConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loading: false,
                user: action.user,
                validId: 1
            };
        case authConstants.CHANGE_PASSWORD_FAILURE:
            return {
                loading: false,
                loggingIn: false,
            };

        case authConstants.SEND_LINK_USER_REQUEST:
            return {
                ...state,
                openRestartExternal:{
                    open: true,
                    select: "",
                    type:0,
                    loading: true
                }
            };
        case authConstants.SEND_LINK_USER_SUCCESS:
            return {
                ...state,
                openRestartExternal:{
                    open: false,
                    select: "",
                    type:0,
                    loading: false
                }
            };
        case authConstants.SEND_LINK_USER_FAILURE:
            return {
                ...state,
                openRestartExternal:{
                    open: true,
                    select: "",
                    type:0,
                    loading: false
                }
            };

        default:
            return state
    }
}