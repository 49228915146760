export class DocumentSize {
    documentSize?: number;
    name?: string;
    
    constructor(datos?: DocumentSize) {
        if (datos != null) {
        this.documentSize = datos.documentSize;
        this.name = datos.name;
        return;
        }
    }

}
