import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { customerActions } from './actions/customer.actions';
import { User } from '../settings/users/models/user';
import { Column } from 'primereact/column';
import { DialogCustomer } from './components/dialog.customer';
import { DataTable } from 'primereact/datatable';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import Logo from'../../assets/images/logo.png';
import { ImageSrc } from '../../components/image-src';
import { CustomerInClock } from './models/customer_in_clock';
import { getDateFormat } from '../../helpers/format';

function CustomerInClockListPage() {
    const loading: boolean= useSelector((state:any) => state.customer.loading);
    const customersInClock: CustomerInClock[]= useSelector((state:any) => state.customer.customersInClock);
    const dispatch = useDispatch();
    const user  = useSelector((state: any) => state.authentication.user as User);

    useEffect(() => { 
       dispatch(customerActions.getCustomersInClock()); 
    },[]);



    const onClose = ()=>{
    }

    const photoBodyTemplate = (customer: CustomerInClock)=>{
        return  (customer.photoUri) ?
                                <ImageSrc height="25px" width="25px" src={customer.photoUri} />
                
                                :<img height="25px" width="25px"src={ Logo || ""} alt="" />
        }
    const removeBodyTemplate = (customer: CustomerInClock)=>{
        return  (customer.removed) ?
                                <span>Si</span>
                
                                :<span>No</span>
        }
    const endingBodyTemplate = (customer: CustomerInClock)=>{
        return  <span>{getDateFormat(customer.endingDate)}</span>
                
        }

    return (
        <div className="grid mt-3">
                <div className="card col-12">
                    {/* {header} */}
                   <DataTable  emptyMessage="Sin resultados" paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={customersInClock??[]} responsiveLayout="scroll"  
                        size="small" stripedRows  >
                        <Column filter filterType="contains" field="photoId" header="Foto" body={photoBodyTemplate}></Column>
                        <Column filter filterType="contains" field="customerId" header="No"></Column>
                        <Column sortable filter filterType="contains" field="customer" header="Nombre"></Column>
                        <Column sortable filter filterType="contains" field="removed" header="Removido" body={removeBodyTemplate} ></Column>
                        <Column sortable filter filterType="contains" field="endingDate" header="Fecha Finaliza" body={endingBodyTemplate}></Column>
                    </DataTable>
                </div>
            <DialogCustomer onClose={onClose}/>
        </div>
    )
}

export { CustomerInClockListPage };