import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { DataDialogProps } from "../../../models/data.dialog.props";
import { categoryService } from "../category.service";
import { categoryConstants } from "../constants/category.constants";
import { Category } from "../models/category";
import { ProductGroup } from "../models/product_group";

export const categoryActions = {
    openModalSearchCategory,
    openModalSearchProductGroup,
    searchCategoryByDescription,
    searchProductGroupByDescription
};

function openModalSearchCategory(openSelectCategory:DataDialogProps<(Category[])>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: categoryConstants.OPEN_MODAL_SEARCH_CATEGORY, openSelectCategory });
    };
}
function openModalSearchProductGroup(openSelectProductGroup:DataDialogProps<(Category[])>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: categoryConstants.OPEN_MODAL_SEARCH_PRODUCT_GROUP, openSelectProductGroup });
    };
}
 

function searchCategoryByDescription(description: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        categoryService.searchCategoryByDescription(description)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: categoryConstants.SEARCH_CATEGORY_REQUEST } }
    function success(categories:Category[]) { return { type: categoryConstants.SEARCH_CATEGORY_SUCCESS, categories } }
    function failure(error:any) { return { type: categoryConstants.SEARCH_CATEGORY_FAILURE, error } }
}

function searchProductGroupByDescription(description: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        categoryService.searchProductGroupByDescription(description)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: categoryConstants.SEARCH_PRODUCT_GROUP_REQUEST } }
    function success(productGroups:ProductGroup[]) { return { type: categoryConstants.SEARCH_PRODUCT_GROUP_SUCCESS, productGroups } }
    function failure(error:any) { return { type: categoryConstants.SEARCH_PRODUCT_GROUP_FAILURE, error } }
}

