export class Item { 
    itemId?: string;
    type?: number;
    name?: string;
    price?: number;
    priceVat?: number;
    barCode?: string;
    durationId?: number;
    duration?: number;
    vatId?: string;
    fileId?: number;
    photoUri?: string;
    companyId?: string;
    createdBy?: number;
    createdDate?: Date;
    active ?: boolean;

    
    constructor(datos?: Item) {
        if (datos != null) {
        this.itemId = datos.itemId;
        this.type = datos.type;
        this.name = datos.name;
        this.price = datos.price;
        this.priceVat = datos.priceVat;
        this.barCode = datos.barCode;
        this.durationId = datos.durationId;
        this.duration = datos.duration;
        this.vatId = datos.vatId;
        this.fileId = datos.fileId;
        this.photoUri = datos.photoUri;
        this.companyId = datos.companyId;
        this.createdBy = datos.createdBy;
        this.createdDate = datos.createdDate;
        this.active = datos.active;
        return;
        }
    }
}