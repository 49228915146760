import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';
import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { TypeDialog } from '../../../helpers/type_dialog';
import { Item } from '../models/item';
import { itemActions } from '../actions/item.actions';
import { durationActions } from '../../durations/duration.actions';
import { Duration } from '../../durations/models/duration';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { VatItem } from '../../vat-item/models/vat-item';
import { vatItemActions } from '../../vat-item/vat-item.actions';
import { TypeItems } from '../../../helpers/consts';
import { FileUpload } from '../../file_picker_in_house/models/file_upload';
import FilePickerInHouse from '../../file_picker_in_house/FilePickerInHouse';


  function DialogItem(props: DialogProps<Item>) {
    const { onClose} = props;
    const dispatch = useDispatch();    
    const openItem  = useSelector((state: any) => state.items.openItem as DataDialogProps<Item>);
    const durations:Duration[]  = useSelector((state: any) => state.durations.durations as Duration[]);
    const vatItems:VatItem[]  = useSelector((state: any) => state.vatItems.vatItems as VatItem[]);
    const loading:boolean  = useSelector((state: any) => state.vatItems.loading as boolean);
    const loadingDuration: boolean  = useSelector((state: any) => state.durations.loading);
    const [inputs, setInputs] = React.useState<Item>(openItem?.select);

    useEffect(() => {
         setInputs(openItem?.select)
    }, [openItem]);

    useEffect(() => {
        dispatch(durationActions.get()); 
        dispatch(vatItemActions.get()); 
    }, []);

    const handleClose = () => {
      dispatch(itemActions.openModalItem({
        open : false,
        select :new Item(),
        type : TypeDialog.add,
        loading: false
      }));
    };

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e:any) {
        e.preventDefault();
        inputs.duration = inputs.type == 0? inputs.duration:1 ;
        inputs.durationId = inputs.type == 0?inputs.durationId: 1;
        if (inputs.name && inputs.priceVat  && inputs.vatId) {
            if(inputs.type ===0 && !inputs.duration  && !inputs.durationId ) return;
            if(openItem?.type ===TypeDialog.add){
                dispatch(itemActions.add(inputs));
            }
            if(openItem?.type ===TypeDialog.edit){
               dispatch(itemActions.edit(inputs));
            }
        }
    }

    const onImagePicker =  async (result: FileUpload)=>{
        setInputs(inputs => ({ ...inputs, ["photoUri"]: result.path }));
        setInputs(inputs => ({ ...inputs, ["fileId"]: result.fileId }));
    }    
    function handleChangeCheck(e:any) {
        const { name, checked } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: checked }));
    }
    const footer = (
        <div>
            <Button loading={openItem.loading} disabled={openItem.loading} label="Guardar" onClick={handleSubmit} icon="pi pi-save"/>
        </div>
    );

    
    return (
        <Dialog header={openItem.type == TypeDialog.add? "Agregar": "Producto: "+ (inputs.name || "")} footer={footer} visible={openItem?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div className='p-fluid grid'>   
                <div className="col-12">
                    <div className="grid">
                        <div className="col-8 md:col-9">
                            <div className="grid">
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="itemId" className="block">No</label>
                                    <InputText id="itemId" disabled={openItem.type == TypeDialog.add} value={inputs.itemId || ""} readOnly={true} className="block"/>
                                </div>
                        
                                <div className="field col-12 md:col-8">
                                    <label htmlFor="name" className="block">Nombre</label>
                                    <InputText id="name" name="name" value={inputs.name || ""}  onChange={handleChange} className={(!inputs?.name) ? "block p-invalid":"block"}/>
                                    {(!inputs?.name) ?<small id="name-help" className="block p-error">Ingrese el nombre</small>: <></> }
                                </div>
                                <div className="field col-6 md:col-4">
                                    <label htmlFor="priceVat" className="block">Precio Final</label>
                                    <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">L</span>
                                            <InputNumber placeholder="priceVat" name='priceVat' mode="decimal" locale="en-US" minFractionDigits={2} maxFractionDigits={2}
                                            value={inputs.priceVat || 0}   onValueChange={handleChange}  className={(!inputs?.priceVat ) ? "p-invalid":""}/>
                                        </div>  
                                    {(!inputs?.priceVat) ?<small id="priceVat-help" className="block p-error">Ingrese el precio final</small>: <></> }
                                </div>
                                <div className="field col-6 md:col-4">
                                    <label htmlFor="dropdownVatId">Impuesto</label>                    
                                    <Dropdown inputId="dropdownVatId" name="vatId" value={inputs.vatId} options={vatItems} onChange={handleChange} optionLabel="name" 
                                    optionValue="vatId" className={(!inputs?.vatId) ? "p-invalid":""}/>
                                    {loading? <ProgressSpinner  style={{width: '20px', height: '20px'}}/>: ""}
                                    {(!inputs?.vatId) ?<small id="vatId-help" className="block p-error">Ingrese el impuesto</small>: <></> }
                                </div> 
                        
                                <div className="field col-6 md:col-4">
                                    <label htmlFor="dropdownType">Tipo de Producto</label>                    
                                    <Dropdown disabled={openItem?.type ===TypeDialog.edit } inputId="dropdownType" name="type" value={inputs.type} options={TypeItems} onChange={handleChange} optionLabel="name" 
                                    optionValue="type" className={(inputs?.type != 0 && inputs?.type !=1 && inputs?.type !=2) ? "p-invalid":""}/>
                                    {(inputs?.type != 0 && inputs?.type !=1 && inputs?.type !=2) ?<small id="type-help" className="block p-error">Ingrese el tipo</small>: <></> }
                                </div> 
                                {inputs.type == 1? //Item
                                    <div className="field col-6 md:col-4">
                                        <label htmlFor="barCode" className="block">Código de Barra</label>
                                        <InputText id="barCode" name="barCode" value={inputs.barCode || ""}  onChange={handleChange} className={(!inputs?.barCode) ? "block p-invalid":"block"}/>
                                        {(!inputs?.barCode) ?<small id="barCode-help" className="block p-error">Ingrese el código de barra</small>: <></> }
                                    </div>
                                    :<div></div>
                                }
                                {inputs.type == 0? //Suscripcion
                                    <div className="field col-6 md:col-4">
                                        <label htmlFor="duration" className="block">Duración</label>
                                        <InputText id="duration" name="duration" type="number" value={(inputs.duration || "").toString()} onChange={handleChange} className={(!inputs?.duration) ? "block p-invalid":"block"}/>
                                        {(!inputs?.duration) ?<small id="duration-help" className="block p-error">Ingrese la duración</small>: <></> }
                                    </div>
                                    :<div></div>
                                }
                                {inputs.type == 0? //Suscripcion
                                    <div className="field col-6 md:col-4">
                                        <label htmlFor="dropdownDurationId">Tipo de Duración</label>                    
                                        <Dropdown inputId="dropdownDurationId" name="durationId" value={inputs.durationId} options={durations} 
                                        onChange={handleChange} optionLabel="name" optionValue="durationId" className={(!inputs?.durationId) ? "p-invalid":""} />
                                        {loadingDuration? <ProgressSpinner  style={{width: '20px', height: '20px'}}/>: ""}
                                        {(!inputs?.durationId) ?<small id="durationid-help" className="block p-error">Ingrese el tipo de duración</small>: <></> }
                                    </div>
                                    :<div></div>
                                }

                                {openItem?.type ===TypeDialog.edit ?
                                    <div className="field col-12  md:col-12">
                                        <span className="p-float-label p-input-icon-left">
                                            <Checkbox inputId="cbActive" name="active" value="Activo" onChange={handleChangeCheck} checked={inputs.active??false}></Checkbox>
                                            <label htmlFor="cbActive" className="p-checkbox-label">Activo</label>
                                        </span>
                                    </div>
                                    :<div></div>
                                }
                            </div>
                        </div>
                        <div className="col-4 md:col-3">
                            <FilePickerInHouse onClose={onImagePicker} photoUri={inputs.photoUri??""}></FilePickerInHouse>
                        </div>
                    </div>
                    
                </div>
            </div>
        </Dialog>
    );
  }
    
  export { DialogItem };

