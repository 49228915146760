import { useDispatch, useSelector } from 'react-redux';
import { Item } from './models/item';
import { useEffect } from 'react';
import { itemActions } from './actions/item.actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { DebounceInput } from 'react-debounce-input';
import { InputText } from 'primereact/inputtext';
import { DialogItem } from './components/dialog.item';
import { TypeDialog } from '../../helpers/type_dialog';
import { format } from '../../helpers/format';
import { ImageSrc } from '../../components/image-src';
import Logo from'../../assets/images/logo.png';

function ListItemPage() {
    const items: Item[]= useSelector((state:any) => state.items.items);
    const loading: boolean= useSelector((state:any) => state.items.loading);
    const dispatch = useDispatch();    
 
    useEffect(() => { 
       dispatch(itemActions.get()); 
    },[]);

    const  handAdd = () =>{
         dispatch(itemActions.openModalItem({
                    open : true,
                    select :new Item(),
                    type : TypeDialog.add,
                    loading: false
                }));
    }

    const actionBodyTemplate = (item:Item) => {
            const showDialogItem =()=>{
                dispatch(itemActions.openModalItem({
                    open : true,
                    select :item,
                    type : TypeDialog.edit,
                    loading: false
                }));
            }
         return <span className="p-buttonset">
            <Button label="Editar" icon="pi pi-pencil" iconPos="left" onClick={showDialogItem} />
            {/* <Button label="Restablecer" className="p-button-success" icon="pi pi-info-circle" iconPos="left" onClick={showDialogRestart} /> */}
        </span>

    }
    const activeBodyTemplate = (item:Item) => {
        return <span className="p-buttonset">
            {item.active ? "Activo": "Inactivo"}
        </span>
    }

 const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                    <span className="p-input-icon-left" >
                    { loading ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                     <DebounceInput
                        placeholder="Buscar"
                        minLength={1}
                        style={{width:"100%"}}
                        onChange={event => {
                            if((event.target?.value??"").length > 0){
                               dispatch(itemActions.searchByDescriptionList(event.target.value));
                            }
                        }} 
                        value=""
                        debounceTimeout={400}
                        element={InputText as any} />
                </span>
                  <span className="p-input-icon-left">
                    <Button label="Agregar nuevo" onClick={handAdd} icon="pi pi-pencil" iconPos="left"  />
                </span>




            </div>
        )
    }
    const header = renderHeader();

    const photoBodyTemplate = (item: Item)=>{
        return  (item.fileId != 0) ?
                                <ImageSrc height="25px" width="25px" src={item.photoUri} />
                
                                :<img height="25px" width="25px"src={ Logo || ""} alt="" />
        }
    
    const priceBodyTemplate = (row:Item, options:any) => {
        return (
            <span className={options.className}>
                <span>L {format((row.priceVat??0),2)}</span>
            </span>
        )
    }
    return (
         <div className="grid mt-3">
                <div className="card col-12">
                    {header}
                   <DataTable  emptyMessage="Sin resultados" paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={items??[]} responsiveLayout="scroll"  
                        size="small" stripedRows  >  
                        <Column filter filterType="contains" field="photoId" header="Foto" body={photoBodyTemplate}></Column>
                        <Column filter filterType="contains" field="itemId" header="No"></Column>
                        <Column sortable filter filterType="contains" field="name" header="Nombre"></Column>
                        <Column sortable filter filterType="contains" field="duration" header="Duración" ></Column>
                        <Column sortable filter filterType="contains" field="priceVat  " header="Precio" body={priceBodyTemplate}></Column>
                        <Column sortable filter filterType="contains" field="vatId" header="Impuesto" ></Column>
                        <Column sortable filter filterType="contains" field="active" header="Activo" body={activeBodyTemplate}  ></Column>
                        <Column header="Acción" body={actionBodyTemplate} />
                    </DataTable>
                </div>
            <DialogItem onClose={()=>{}}/>
        </div>
       
    );
}

export { ListItemPage };