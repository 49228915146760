import { printPdfConstants } from "../constants/print-pdf.constants";
import { PrintPdfState } from "../models/print-pdf.state";

const initialState: PrintPdfState = { 

    openPrintPdf: {
        open: false,
        select: "",
        type: 0,
        loading: false,
    },
};

export function printPdf(state:PrintPdfState = initialState, action:any) {
    switch (action.type) {
        case printPdfConstants.OPEN_MODAL_PRINT_PDF:
            return {
                ...state,
                openPrintPdf: action.openPrintPdf
            };
        default:
            return state
    }
}