import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import { Item } from '../items/models/item';
import { useNavigate } from 'react-router-dom';
import { ItemCard } from '../../components/ItemCard';
import { User } from '../settings/users/models/user';
import { homeActions } from './actions/home.actions';
import { WeekData } from './models/week-data';
import { Chart } from 'primereact/chart';
import { WeekDataWeeks } from './models/week-data-weeks';
import { WeekDataWeek } from './models/week-data-week';

function HomePage() {
    const items: Item[] = useSelector((state:any) => state.home.items as Item[]);
    // const categories: TreeNodeDto[] = useSelector((state:any) => state.home.categories as TreeNodeDto[]);
    const loading: boolean= useSelector((state:any) => state.home.loading);
    const data: WeekData= useSelector((state:any) => state.home.data as WeekData);
    const dispatch = useDispatch();
    const user:User  = useSelector((state: any) => state.authentication.user as User);


    
    useEffect(()=>{
        if(user.homePath == "diary_sales"){
            dispatch(homeActions.getHomeData());
        }
    }, [])


    const getDataFormatted = (week: WeekDataWeek)=>{
        let weeka = {
            labels: week.days?.map(c=> c.day),
            datasets: [
                {  
                    label: "Venta Díaria",
                    data: week.days?.map(c=> c.lps),
                    backgroundColor:week.days?.map(c=>'#3d8aca'),
                    borderColor:week.days?.map(c=>'#3d8aca'),
                    borderWidth: 1
                }
            ]
            
        }
        return weeka;
    }

    return (
        <div className="p-fluid grid mt-1">
            <div className="col-12 card">
            <h4>Hola {user.name}</h4>
                {loading ? <SkeletonComponent/> :
                    <div className="p-fluid grid ">
                        {  (data?.detail??[]).map((detail)=>{
                                return <div key={detail.title} className="col-4">
                                    <div style={{border:"1px solid #8a8a8a", padding:"10px"}}>                                        
                                        <h5 style={{fontSize:"20px"}}>{detail.value}</h5>
                                        <h6 style={{color:"#0078d4", fontWeight: 400}}>{detail.title}</h6>
                                    </div>
                                </div>
                            })
                        }

                    {  (data?.weeks??[]).map((week)=>{
                                return <div key={week.title} className="col-12 md:col-6">
                                        <h5>{week.title}</h5>
                                        <Chart type="bar" data={getDataFormatted(week)} 
                                        
                                        options={{
                                        scales: {
                                            y: {
                                                beginAtZero: true
                                            }
                                        }
                                    }} />
                                </div>
                            })
                        }



                    </div>
                }
            </div>
        </div>
    )
}

export { HomePage };