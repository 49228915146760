import { alertActions } from './alert.actions';
import { Dispatch } from 'redux';
import { authService } from '../auth.service';
import { authConstants } from '../constants/auth.constants';
import { DataDialogProps } from '../../models/data.dialog.props';

export const authActions = {
    login,
    logout,
    validateChangePasswordId,
    openModalRestartExternal,
    sendLink,
    changePassword
};
function openModalRestartExternal(openRestartExternal:DataDialogProps<string>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: authConstants.OPEN_MODAL_RESTART_EXTERNAL, openRestartExternal });
    };
}



function login(userName: string, password: string, from: any) {
    return (dispatch: Dispatch) => {
        dispatch(request({ userName }));
        authService.login(userName, password)
            .then((user)=> { 
                    if (user.data.token) {
                        localStorage.setItem("user", JSON.stringify(user.data));
                    }
                    dispatch(success(user.data));
                    // let navigate = useNavigate();
                    // // let navigate = useNavigate();
                    // // myHistory.push(from);
                    // navigate(from);
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request(user:any) { return { type: authConstants.LOGIN_REQUEST, user } }
    function success(user:any) { return { type: authConstants.LOGIN_SUCCESS, user } }
    function failure(error:any) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function changePassword(changePassword: any, token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        authService.changePassword(changePassword, token)
            .then((result)=> { 
                    if (result.data.token) {
                        localStorage.setItem("user", JSON.stringify(result.data));
                    }
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: authConstants.CHANGE_PASSWORD_REQUEST, } }
    function success(user:any) { return { type: authConstants.CHANGE_PASSWORD_SUCCESS, user } }
    function failure(error:any) { return { type: authConstants.CHANGE_PASSWORD_FAILURE, error } }
}


function validateChangePasswordId(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        authService.validateChangePasswordId(id)
            .then((result)=> {
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: authConstants.VALIDATE_CHANGE_PASSWORD_REQUEST } }
    function success(user:any) { return { type: authConstants.VALIDATE_CHANGE_PASSWORD_SUCCESS, user } }
    function failure(error:any) { return { type: authConstants.VALIDATE_CHANGE_PASSWORD_FAILURE, error } }
}

function logout() {
    authService.logout();
    return { type: authConstants.LOGOUT };
}

function sendLink(userName: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        authService.sendLink(userName)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: authConstants.SEND_LINK_USER_REQUEST } }
    function success(message: string) { return { type: authConstants.SEND_LINK_USER_SUCCESS, message } }
    function failure(error:any) { return { type: authConstants.SEND_LINK_USER_FAILURE, error } }
}

