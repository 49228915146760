import Swal from 'sweetalert2'
import { alertConstants } from '../constants/alert.constants';

export const ToastError = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    customClass:{
        container:'zIndex'
    },
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

export function alert(state = {}, action:any) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            ToastError.fire({
                icon: 'success',
                title: action.message
              })
            return {
                type: 'alert-success',
                message: action.message
            };
        case alertConstants.ERROR:
            ToastError.fire({
                icon: 'error',
                title: action.message
              })
            return {
                type: 'alert-danger',
                message: action.message
            };
        case alertConstants.CLEAR:
            return {};
        default:
            return state
    }
}