import axios from 'axios';
import environment from '../../environment';

export const vatItemService = {
    get
};

function get() {
    return axios.get(`${environment.apiUri}/item/VatItem`)
  };

