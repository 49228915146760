import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../../auth/actions/alert.actions";
import { User } from "../models/user";
import { DataDialogProps } from "../../../../models/data.dialog.props";
import { userConstants } from "../constants/user.constants";
import { userService } from "../user.service";

export const userActions = {
    openModalUser,
    openModalRestart,
    getUsers,
    generateLink,
    sendLinkEmail,  
    sendLinkSms,
    add,
    edit
};

function openModalUser(openUser:DataDialogProps<User>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: userConstants.OPEN_MODAL_USER, openUser });
    };
}
function openModalRestart(openRestart:DataDialogProps<User>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: userConstants.OPEN_MODAL_RESTART, openRestart });
    };
}

function getUsers() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        userService.getUsers()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: userConstants.GET_USERS_REQUEST } }
    function success(users:User[]) { return { type: userConstants.GET_USERS_SUCCESS, users } }
    function failure(error:any) { return { type: userConstants.GET_USERS_FAILURE, error } }
}
function generateLink(userId: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        userService.generateLink(userId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: userConstants.GENERATE_LINK_REQUEST } }
    function success(link: string) { return { type: userConstants.GENERATE_LINK_SUCCESS, link } }
    function failure(error:any) { return { type: userConstants.GENERATE_LINK_FAILURE, error } }
}

function sendLinkEmail(userId: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        userService.sendLinkEmail(userId)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: userConstants.SEND_LINK_EMAIL_REQUEST } }
    function success(message: string) { return { type: userConstants.SEND_LINK_EMAIL_SUCCESS, message } }
    function failure(error:any) { return { type: userConstants.SEND_LINK_EMAIL_FAILURE, error } }
}
function sendLinkSms(userId: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        userService.sendLinkSms(userId)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(result.data));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: userConstants.SEND_LINK_SMS_REQUEST } }
    function success(message: string) { return { type: userConstants.SEND_LINK_SMS_SUCCESS, message } }
    function failure(error:any) { return { type: userConstants.SEND_LINK_SMS_FAILURE, error } }
}


function add(user: User) {
    return (dispatch: Dispatch) => {
        dispatch(request(user));
        userService.add(user)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, user));
                dispatch(alertActions.error(message));
            });
    };

    function request(user: User) { return { type: userConstants.ADD_USER_REQUEST, user } }
    function success(users:User[]) { return { type: userConstants.ADD_USER_SUCCESS, users} }
    function failure(error:any, user: User) { return { type: userConstants.ADD_USER_FAILURE, error, user } }
}

function edit(user: User) {
    return (dispatch: Dispatch) => {
        dispatch(request(user));
        userService.edit(user)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, user));
                dispatch(alertActions.error(message));
            });
    };

    function request(user: User) { return { type: userConstants.EDIT_USER_REQUEST, user } }
    function success(users:User[]) { return { type: userConstants.EDIT_USER_SUCCESS, users } }
    function failure(error:any, user: User) { return { type: userConstants.EDIT_USER_FAILURE, error, user } }
}
