import { useDispatch, useSelector } from 'react-redux';
import { User } from './models/user';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { userActions } from './actions/auth.actions';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../components/SkeletonComponet';
import { DialogUser } from './components/dialog.user';
import { DialogRestartPassword } from './components/dialog.restart-password';
import { alertActions } from '../../../auth/actions/alert.actions';
import { TemplatePaginator } from '../../../components/TemplatePaginator';

function ListUserPage() {
    const users: User[] = useSelector((state:any) => state.users.users as User[]);
    const loading: boolean= useSelector((state:any) => state.users.loading);
    const user  = useSelector((state: any) => state.authentication.user as User);
    const dispatch = useDispatch();

    useEffect(() => { 
        dispatch(userActions.getUsers());
        dispatch(userActions.openModalRestart({
            open : false,
            select : new User(),
            type : 0,
            loading: false
        }));
    },[]);

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">Listado de Usuarios</h5>
                <span className="p-input-icon-left">
                    <Button label="Agregar nuevo" onClick={handAdd} icon="pi pi-pencil" iconPos="left"  />
                </span>
            </div>
        )
    }
    const  handAdd = () =>{
         dispatch(userActions.openModalUser({
                open : true,
                select : new User(),
                type : 0,
                loading: false
            }));
    }
    const header = renderHeader();

    const actionBodyTemplate = (rowData:User) => {
        const showDialog =()=>{
            dispatch(userActions.openModalUser({
                open : true,
                select : rowData,
                type : 1,
                loading: false
            }));
        }
        const showDialogRestart =()=>{
            var permissions = user.permissions.findIndex(x=> x.path == "btn_restart_password");
            if(permissions== -1) {
                dispatch(alertActions.error("No tiene acceso a esta acción, por favor solicitar"));
                return;
            }
            dispatch(userActions.openModalRestart({
                open : true,
                select : rowData,
                type : 0,
                loading: false
            }));
        }
        return  <span className="p-buttonset">
                    <Button label="Editar" icon="pi pi-pencil" iconPos="left" onClick={showDialog} />
                    {/* <Button label="Restablecer" className="p-button-success" icon="pi pi-info-circle" iconPos="left" onClick={showDialogRestart} /> */}
                </span>
    }
    return ( 
        <div className="grid mt-1">
            <div className="col-12 card">
                {loading ? <SkeletonComponent/> :
                <DataTable  emptyMessage="Sin resultados" value={users} paginatorTemplate={TemplatePaginator as any} 
                        responsiveLayout="scroll"  rows={10}  size="small" stripedRows   header={header} >
                        <Column field="userId" header="No"></Column>
                        <Column field="name" header="Nombre"></Column>
                        <Column field="userName" header="Usuario"></Column>
                        <Column field="email" header="Correo"></Column>
                        <Column field="role" header="Rol"></Column>
                        <Column header="Acción" body={actionBodyTemplate} />
                    </DataTable>
                }
                <DialogUser></DialogUser>
                <DialogRestartPassword ></DialogRestartPassword>
            </div>
        </div>
    );
}

export { ListUserPage };