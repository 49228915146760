import axios from 'axios';
import environment from '../../environment';

export const categoryService = {
    searchCategoryByDescription,
    searchProductGroupByDescription,
};

 function searchCategoryByDescription(description: string) {
    return axios.get(`${environment.apiUri}/category/CategoryByDescription/${description}`)
  };

 function searchProductGroupByDescription(description: string) {
    return axios.get(`${environment.apiUri}/category/ProductGroupByDescription/${description}`)
  };
