import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';
import { Customer } from '../models/customer';
import { customerActions } from '../actions/customer.actions';
import { InputText } from 'primereact/inputtext';
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { TypeDialog } from '../../../helpers/type_dialog';
import EnrolFingerLogo from'../../../assets/images/enrolFinger.png';
import StepLogo from'../../../assets/images/step.gif';
import environment from '../../../environment';

  function DialogEnrolFinger(props: DialogProps<string>) {
    const { onClose} = props;
    const dispatch = useDispatch();    
    const openEnrolFinger  = useSelector((state: any) => state.customer.openEnrolFinger as DataDialogProps<Customer>);
    const [inputs, setInputs] = React.useState<Customer>(openEnrolFinger?.select);

    useEffect(() => {
         setInputs(openEnrolFinger?.select)
    }, [openEnrolFinger]);

    const handleClose = () => {
    onClose!("OK");
      dispatch(customerActions.closeEnrolFinger({
            open : false,
            select :"",
            type : TypeDialog.add,
            loading: false
        }));
    };
   
  

    const footer = (
        <div>
           <Button loading={openEnrolFinger.loading} disabled={openEnrolFinger.loading} label="Confirmar" onClick={handleClose} icon="pi pi-arrow-right"/>
        </div>
    );


  
    return (
        <Dialog  header={"Asignar Huella"}  footer={footer} visible={openEnrolFinger?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div className='p-fluid grid'>   
                <div className="col-12 mt-5">
                    {/* <p>Hemos enviado la configuración al lector de huellas, por favor que el cliente coloque su huella sobre el lector <strong>3</strong> veces </p> */}
                    <div className="grid">
                        {/* <div className="col-3">
                            <img height="40%" width={"40%"} src={ FingerLogo || ""} alt="" />          
                        </div> */}
                        <div className="col-12">
                            <img height="100%" width={"100%"} src={ EnrolFingerLogo || ""} alt="" />                                        
                        </div>
                    </div>
                </div>
  
            </div>
        </Dialog>
    );
  }
    
  export { DialogEnrolFinger };