import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Logo from'../../assets/images/logo.png';
import { Button } from 'primereact/button';
import { authActions } from '../../auth/actions/auth.actions';
import { InputText } from 'primereact/inputtext';
import { useLocation, useNavigate } from 'react-router-dom';
import { DialogRestartPasswordExternal } from './components/dialog.restart-password-external';

function LoginPage() {
    const [inputs, setInputs] = useState({ userName: '', password: ''});
    const { userName, password } = inputs;
    const loading = useSelector((state:any) => state.authentication.loading);
    const loggedIn = useSelector((state:any) => state.authentication.loggedIn);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(()=>{
        loggedIn && navigate('/home')
    }, [loggedIn])

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e:any) {
        e.preventDefault();
        if (userName && password) {
            const { from } = location.pathname ? { from: { pathname: location.pathname }} : { from: { pathname: "/" } };
            dispatch(authActions.login(userName, password, from));
        }
    }

    function fogetPassword() {
        dispatch(authActions.openModalRestartExternal({
                open: true,
                select: "",
                type:0,
                loading: false
            }));
    }

    return (
        <form onSubmit={handleSubmit}  autoComplete="false">
            <img width="20%" src={Logo} alt="Logo Diunsa"/>
            <h4>Bienvenido</h4>
            <p className="font-light">Hola bienvenido al portal de tu gimnasio</p>
            <span className="p-float-label mt-5">
                <InputText id="userName"  name="userName" value={userName || ""} onChange={handleChange} disabled={loading} />
                <label htmlFor="userName">Usuario</label>
            </span>
            <span className="p-float-label mt-5 mb-4">
                <InputText id="passWord" type="password" name="password" value={password || ""} onChange={handleChange} disabled={loading}  />
                <label htmlFor="passWord">Contraseña</label>
            </span>
            <a onClick={fogetPassword}>Olvide mi contraseña</a>
            <br />
            <br />
            <Button label="Ingresar" type="submit" disabled={loading || (!userName || !password)} loading={loading}/>
            <DialogRestartPasswordExternal/>
        </form>
     
    );
}

export { LoginPage };

