import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { DataDialogProps } from "../../../models/data.dialog.props";
import { Item } from "../../../pages/items/models/item";
import { searchBarConstants } from "../constants/search_bar.constants";
import { searchBarService } from "../search_bar.service";


export const searchBarActions = {
    openModalSearchItem,
    searchByDescription,
    changeShowPreview
};

function openModalSearchItem(openSelectItem:DataDialogProps<(Item[])>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: searchBarConstants.OPEN_MODAL_SEARCH_ITEM, openSelectItem });
    };
}
function changeShowPreview(showResultPreview:boolean) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: searchBarConstants.CHANGE_PREVIEW, showResultPreview });
    };
}
 

function searchByDescription(description: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        searchBarService.searchByDescription(description)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: searchBarConstants.SEARCH_ITEM_REQUEST } }
    function success(items:Item[]) { return { type: searchBarConstants.SEARCH_ITEM_SUCCESS, items } }
    function failure(error:any) { return { type: searchBarConstants.SEARCH_ITEM_FAILURE, error } }
}

