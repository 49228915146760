import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '../auth/actions/auth.actions';
import { Outlet } from 'react-router-dom';

function LoginLayout(){
    const dispatch = useDispatch();

    useEffect(() => { 
        dispatch(authActions.logout()); 
    }, []);
  
    return(
        <div className="container grid grid-nogutter">
            <div className="sm:col-12 md:col-7 lg:col-7">
                <img width="60%" aria-disabled="true" src="https://preview.colorlib.com/theme/bootstrap/login-form-07/images/undraw_remotely_2j6y.svg" alt="" />
            </div>
            <div className="sm:col-12 md:col-5 lg:col-5">
                <Outlet />
            </div>
        </div>
    )
}

export default LoginLayout;