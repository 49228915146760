export const roleConstants = {
    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

    GET_STORES_REQUEST: 'GET_STORES_REQUEST',
    GET_STORES_SUCCESS: 'GET_STORES_SUCCESS',
    GET_STORES_FAILURE: 'GET_STORES_FAILURE',

    GET_ROLE_DETAIL_REQUEST: 'GET_ROLE_DETAIL_REQUEST',
    GET_ROLE_DETAIL_SUCCESS: 'GET_ROLE_DETAIL_SUCCESS',
    GET_ROLE_DETAIL_FAILURE: 'GET_ROLE_DETAIL_FAILURE',

    ADD_ROLE_REQUEST: 'ADD_ROLE_REQUEST',
    ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
    ADD_ROLE_FAILURE: 'ADD_ROLE_FAILURE',

    EDIT_ROLE_REQUEST: 'EDIT_ROLE_REQUEST',
    EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
    EDIT_ROLE_FAILURE: 'EDIT_ROLE_FAILURE',

    OPEN_MODAL_ROLE: 'OPEN_MODAL_ROLE',
};