import { FinancialDetail } from "./financial-detail";

export class Financial {
    customer?: string;
    creditLimit?: number;
    available?: number;
    balance?: number;
    dueBalance?: number;
    noDueBalance?: number;
    dueBalance30?: number;
    dueBalance60?: number;
    dueBalance90?: number;
    dueBalance120?: number;
    dueBalancePlus120?: number;
    detail?: FinancialDetail[];

    
    constructor(datos?: Financial) {
        if (datos != null) {
        this.customer = datos.customer;
        this.creditLimit = datos.creditLimit;
        this.available = datos.available;
        this.balance = datos.balance;
        this.dueBalance = datos.dueBalance;
        this.noDueBalance = datos.noDueBalance;
        this.dueBalance30 = datos.dueBalance30;
        this.dueBalance60 = datos.dueBalance60;
        this.dueBalance90 = datos.dueBalance90;
        this.dueBalance120 = datos.dueBalance120;
        this.dueBalancePlus120 = datos.dueBalancePlus120;
        this.detail = datos.detail;
        return;
        this.detail = [];
        }
    }
}