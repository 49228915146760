export const format = (num: number, decimals: number) =>
  num.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

export const getDateFormat = (date?:Date)=>{
      if(date == null || !date ) return 'No Aplica'
      let dateFormat = new Date(date!);
      return dateFormat?.toLocaleDateString("es-HN");
  }
export const getDateFormatNew = (date?:Date)=>{
      if(date == null || !date ) return new Date()
      let dateFormat = new Date(date!);
      return dateFormat;
  }

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
}
