import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { DataDialogProps } from "../../../models/data.dialog.props";
import { offerConstants } from "../constants/role.constants";
import { Offer } from "../models/offer";
import { OfferType } from "../models/offer_type";
import { TypeItemOffer } from "../models/type_item_offer";
import { offerService } from "../offer.service";

export const offerActions = {
    openModalOffer,
    getTypeItem,
    getOfferType,
    get,
    add,
    edit
};

function openModalOffer(openOffer:DataDialogProps<Offer>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: offerConstants.OPEN_MODAL_OFFER, openOffer });
    };
}
 

function get() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        offerService.get()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: offerConstants.GET_OFFERS_REQUEST } }
    function success(offers:Offer[]) { return { type: offerConstants.GET_OFFERS_SUCCESS, offers } }
    function failure(error:any) { return { type: offerConstants.GET_OFFERS_FAILURE, error } }
}

function getTypeItem() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        offerService.getTypeItem()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: offerConstants.GET_TYPE_ITEM_OFFER_REQUEST } }
    function success(typeItemOffers:TypeItemOffer[]) { return { type: offerConstants.GET_TYPE_ITEM_OFFER_SUCCESS, typeItemOffers } }
    function failure(error:any) { return { type: offerConstants.GET_TYPE_ITEM_OFFER_FAILURE, error } }
}

function getOfferType() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        offerService.getOfferType()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: offerConstants.GET_OFFER_TYPE_REQUEST } }
    function success(offerTypes:OfferType[]) { return { type: offerConstants.GET_OFFER_TYPE_SUCCESS, offerTypes } }
    function failure(error:any) { return { type: offerConstants.GET_OFFER_TYPE_FAILURE, error } }
}

function add(offer: Offer) {
    return (dispatch: Dispatch) => {
        dispatch(request(offer));
        offerService.add(offer)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success("Agregado"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, offer));
                dispatch(alertActions.error(message));
            });
    };

    function request(offer: Offer) { return { type: offerConstants.ADD_OFFER_REQUEST, offer} }
    function success(offer:Offer) { return { type: offerConstants.ADD_OFFER_SUCCESS, offer } }
    function failure(error:any, offer: Offer) { return { type: offerConstants.ADD_OFFER_FAILURE, error , offer} }
}

function edit(role: Offer) {
    return (dispatch: Dispatch) => {
        dispatch(request(role));
        offerService.edit(role)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success("Editado"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, role));
                dispatch(alertActions.error(message));
            });
    };

    function request(offer: Offer) { return { type: offerConstants.EDIT_OFFER_REQUEST, offer } }
    function success(offer: Offer) { return { type: offerConstants.EDIT_OFFER_SUCCESS, offer } }
    function failure(error:any, offer: Offer) { return { type: offerConstants.EDIT_OFFER_FAILURE, error, offer } }
}
