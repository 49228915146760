export class OfferDetail {
    lineNumber?: number;
    offerId?: number;
    itemCode?: string;
    description?: string;
    typeItemOfferId?: number;
    priceGroup?: string;
    typeDiscountId?: number;
    percentDiscount?: number;
    priceDiscount?: number;
    active?: boolean;
    
    constructor(datos?: OfferDetail) {
        if (datos != null) {
        this.lineNumber = datos.lineNumber;
        this.offerId = datos.offerId;
        this.itemCode = datos.itemCode;
        this.description = datos.description;
        this.typeItemOfferId = datos.typeItemOfferId;
        this.priceGroup = datos.priceGroup;
        this.typeDiscountId = datos.typeDiscountId;
        this.percentDiscount = datos.percentDiscount;
        this.priceDiscount = datos.priceDiscount;
        this.active = datos.active;
        return;
        }
    }
}
