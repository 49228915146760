export class Customer {
    customerId?: string;
    name?: string;
    dni?: string;
    rtn?: string;
    phone?: string;
    birthday?: Date;
    email?: string;
    fileId?: number;
    photoUri?: string;
    storeId?: number;
    companyId?: string;
    createdDate?: Date;
    createdBy?: number;
    fingerPrint?:string;
    fingerPrintSize?:number;
    active?: boolean;
    isEmployee?: boolean;

    
    constructor(datos?: Customer) {
        if (datos != null) {
        this.customerId = datos.customerId;
        this.name = datos.name;
        this.dni = datos.dni;
        this.rtn = datos.rtn;
        this.phone = datos.phone;
        this.birthday = datos.birthday;
        this.email = datos.email;
        this.fileId = datos.fileId;
        this.photoUri = datos.photoUri;
        this.storeId = datos.storeId;
        this.companyId = datos.companyId;
        this.createdDate = datos.createdDate;
        this.createdBy = datos.createdBy;
        this.fingerPrint = datos.fingerPrint;
        this.fingerPrintSize = datos.fingerPrintSize;
        this.active = datos.active;
        this.isEmployee = datos.isEmployee;
        return;
        }
    }
}