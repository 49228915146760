import { offerConstants } from "../constants/role.constants";
import { Offer } from "../models/offer";
import { OfferState } from "../models/offer.state";
import { OfferType } from "../models/offer_type";
import { TypeItemOffer } from "../models/type_item_offer";


const initialState: OfferState = { 
    loading: true, 
    loadingTypeItem: true, 
    error: "",
    offers:  Array<Offer>(), 
    typeItemOffers: Array<TypeItemOffer>(),
    offerTypes: Array<OfferType>(),
    openOffer: {
        open: false,
        select: new Offer(),
        type: 0,
        loading: false,
    }
} ;

export function offers(state:OfferState = initialState, action:any) {
    switch (action.type) {

        case offerConstants.OPEN_MODAL_OFFER:
            return {
                ...state,
                openOffer: action.openOffer
            };

        case offerConstants.GET_OFFERS_REQUEST:
            return {
                ...state,
                loading: true,
                offers:[]
            };
        case offerConstants.GET_OFFERS_SUCCESS:
            return {
                ...state,
                loading: false,
                offers: action.offers
            };
        case offerConstants.GET_OFFERS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case offerConstants.GET_TYPE_ITEM_OFFER_REQUEST:
            return {
                ...state,
                loadingTypeItem: true,
                typeItemOffers: []
            };
        case offerConstants.GET_TYPE_ITEM_OFFER_SUCCESS:
            return {
                ...state,
                loadingTypeItem: false,
                typeItemOffers: action.typeItemOffers
            };
        case offerConstants.GET_TYPE_ITEM_OFFER_FAILURE:
            return {
                ...state,
                loadingTypeItem: false,
            };

        case offerConstants.GET_OFFER_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
                offerTypes: []
            };
        case offerConstants.GET_OFFER_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                offerTypes: action.offerTypes
            };
        case offerConstants.GET_OFFER_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case offerConstants.ADD_OFFER_REQUEST:
            return {
                ...state,
                openOffer: {
                    open: true,
                    select: state.openOffer.select,
                    type: 0,
                    loading: true,
                }
            };
        case offerConstants.ADD_OFFER_SUCCESS:
            return {
                ...state,
                openOffer: {
                    open: true,
                    select: action.offer,
                    type: 1,
                    loading: false,
                }
            };
        case offerConstants.ADD_OFFER_FAILURE:
            return {
                ...state,
                openOffer: {
                    open: true,
                    select: action.offer,
                    type: 0,
                    loading: false,
                }
            };

        case offerConstants.EDIT_OFFER_REQUEST:
            return {
                ...state,
                openOffer: {
                    open: true,
                    select: action.offer,
                    type: 1,
                    loading: true,
                }
            };
        case offerConstants.EDIT_OFFER_SUCCESS:
            return {
                ...state,
                openOffer: {
                    open: true,
                    select: action.offer,
                    type: 1,
                    loading: false,
                }
            };
        case offerConstants.EDIT_OFFER_FAILURE:    
            return {
                ...state,
                openOffer: {
                    open: true,
                    select: action.offer,
                    type: 1,
                    loading: false,
                }
            };
        default:
            return state
    }
}