import axios from 'axios';
import environment from '../../environment';
import { Item } from './models/item';

export const itemService = {
    get,
    getTops,
    edit,
    searchDescriptionWithOffer,
    searchDescription,
    searchItemById,
    add
};

function get() {
    return axios.get(`${environment.apiUri}/item`)
  };

function getTops() {
    return axios.get(`${environment.apiUri}/item/top`)
  };
  function edit(item:Item) {
    return axios.put(`${environment.apiUri}/item`, item)
  };
  function add(item:Item) {
    return axios.post(`${environment.apiUri}/item`, item)
  };

 function searchDescriptionWithOffer(description: string) {
    return axios.get(`${environment.apiUri}/item/SearchDescription/${description}`)
  };
 function searchDescription(description: string) {
    return axios.get(`${environment.apiUri}/item/SearchByDescription?description=${description}`)
  };

 function searchItemById(itemCode: string) {
    return axios.get(`${environment.apiUri}/item/SearchById/${itemCode}`)
  };

