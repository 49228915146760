import { TypeDialog } from "../../../helpers/type_dialog";
import { customerConstants } from "../constants/customer.constants";
import { Customer } from "../models/customer";
import { CustomerState } from "../models/customer.state";
import { CustomerInClock } from "../models/customer_in_clock";
import { Financial } from "../models/financial";

const initialState: CustomerState = { 
    loading: false, 
    loadingFinancial: false,
    error: "",
    customers: Array<Customer>(),
    customersInClock: Array<CustomerInClock>(),
    financial: new Financial(),
    financialHistoric: new Financial(),
    devices: [],
    openCustomer:{
        open:false,
        select: new Customer(),
        type: 0,
        loading: false
    },
 
    openFinancialHistoric:{
        open:false,
        select: new Customer(),
        type: 0,
        loading: false
    },
    openSelectCustomer: {
        open: false,
        select:[],
        type: 0,
        loading: false,
    },
    openEnrolFinger: {
        open: false,
        select: "",
        type: 0,
        loading: false,
    },
    openNewEnrolFinger: {
        open: false,
        select: "",
        type: 0,
        loading: false,
    }
};

export function customer(state:CustomerState = initialState, action:any) {
    switch (action.type) {

    
        case customerConstants.OPEN_MODAL_SEARCH_CUSTOMER_REQUEST:
            return {
                ...state,
                openSelectCustomer: action.openSelectCustomer
            };
         case customerConstants.OPEN_MODAL_SEARCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                 openSelectCustomer: {
                    open: state.openSelectCustomer.open,
                    select:action.customers,
                    type: state.openSelectCustomer.type,
                    loading: false,
                }
            };

         case customerConstants.OPEN_MODAL_SEARCH_CUSTOMER_FAILURE:
            return {
                ...state,
                openSelectCustomer: {
                    open: state.openSelectCustomer.open,
                    select:state.openSelectCustomer.select,
                    type: state.openSelectCustomer.type,
                    loading: false,
                }
            };

         case customerConstants.OPEN_MODAL_SEARCH_CUSTOMER_REQUEST:
            return {
                ...state,
                openSelectCustomer: action.openSelectCustomer
            };

         case customerConstants.OPEN_MODAL_CUSTOMER:
            return {
                ...state,
                openCustomer: action.openCustomer
            };

      
         case customerConstants.CLOSE_MODAL_ENROL_FINGER:
            return {
                ...state,
                openEnrolFinger: action.openEnrolFinger
            };



         case customerConstants.OPEN_MODAL_NEW_ENROL_FINGER:
            return {
                ...state,
                openNewEnrolFinger: action.openNewEnrolFinger
            };

      
         case customerConstants.CLOSE_MODAL_NEW_ENROL_FINGER:
            return {
                ...state,
                openNewEnrolFinger: action.openNewEnrolFinger
            };



         case customerConstants.UPDATE_SELECT_CUSTOMER:
            return {
                ...state,
                openCustomer:{
                    open: state.openCustomer.open,
                    select: action.customer,
                    type: state.openCustomer.type,
                    loading: state.openCustomer.loading
                },
            };

         case customerConstants.OPEN_MODAL_ENROL_FINGER_REQUEST:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select: state.openCustomer.select,
                    type: state.openCustomer.type,
                    loading: true
                }
            };
        case customerConstants.OPEN_MODAL_ENROL_FINGER_SUCCESS:
            return {
                ...state,
                openEnrolFinger: action.openEnrolFinger,
                openCustomer: {
                    open: state.openCustomer.open,
                    select: state.openCustomer.select,
                    type: state.openCustomer.type,
                    loading: false
                }
                
            };  
        case customerConstants.OPEN_MODAL_ENROL_FINGER_FAILURE:
            return {
                ...state,
                 openCustomer: {
                    open: state.openCustomer.open,
                    select: state.openCustomer.select,
                    type: state.openCustomer.type,
                    loading: false
                }
            };

        case customerConstants.GET_DEVICES_REQUEST:
            return {
                ...state,
                devices: [],
            };
        case customerConstants.GET_DEVICES_SUCCESS:
            return {
                ...state,
                devices: action.devices,
                
            };
        case customerConstants.GET_DEVICES_FAILURE:
            return {
                ...state,
                
            };

        case customerConstants.SAVE_FINGERPRINT_REQUEST:
            return {
                ...state,
                openNewEnrolFinger: {
                    open: true,
                    select: state.openNewEnrolFinger.select,
                    type: 0,
                    loading: true,
                }
            };
        case customerConstants.SAVE_FINGERPRINT_SUCCESS:
            return {
                ...state,
                openCustomer: {
                    open: false,
                    select: new Customer(),
                    type: 0,
                    loading: false
                },
                openNewEnrolFinger: { open: false,
                    select: "",
                    type: 0,
                    loading: false,
                }
            };
        case customerConstants.SAVE_FINGERPRINT_FAILURE:
            return {
                ...state,
                openNewEnrolFinger: {
                    open: false,
                    select: "",
                    type: 0,
                    loading: false,
                }
            };


                
         case customerConstants.ADD_CUSTOMER_REQUEST:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select: action.customer,
                    type: state.openCustomer.type,
                    loading: true
                }
            };
            
        case customerConstants.ADD_CUSTOMER_SUCCESS:
            debugger
            let newCustomers = [action.customer,...state.customers]
            return {
                ...state,
                customers: [...newCustomers],
                openCustomer: {
                    open: true,
                    select: action.customer,
                    type: TypeDialog.edit,
                    loading: false
                }
            };  
        case customerConstants.ADD_CUSTOMER_FAILURE:
            return {
                ...state,
                 openCustomer: {
                    open: true,
                    select: action.customer,
                    type: state.openCustomer.type,
                    loading: false
                }
            };

        case customerConstants.EDIT_CUSTOMER_REQUEST:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select: action.customer,
                    type: state.openCustomer.type,
                    loading: true
                }
            };
        case customerConstants.EDIT_CUSTOMER_SUCCESS:
            debugger
            let currentCustomerIndex = [...state.customers].findIndex(c=> c.customerId == action.customer.customerId);
            let newCustomersEdited = [...state.customers]
            if(currentCustomerIndex != -1){
                newCustomersEdited[currentCustomerIndex] = action.customer;
            }
            else{
                newCustomersEdited = [action.customer,...state.customers];
            }

            return {
                ...state,
                customers: [...newCustomersEdited],
                openCustomer: {
                    open: true,
                    select: action.customer,
                    type: TypeDialog.edit,
                    loading: false
                }
            };  
        case customerConstants.EDIT_CUSTOMER_FAILURE:
            return {
                ...state,
                 openCustomer: {
                    open: state.openCustomer.open,
                    select: action.customer,
                    type: state.openCustomer.type,
                    loading: false
                }
            };

        case customerConstants.UPLOAD_IMAGE_REQUEST:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select:  action.customer,
                    type: state.openCustomer.type,
                    loading: true
                }
            };
        case customerConstants.UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select: action.customer,
                    type: state.openCustomer.type,
                    loading: false
                }
            };  
        case customerConstants.UPLOAD_IMAGE_FAILURE:
            return {
                ...state,
                 openCustomer: {
                    open: state.openCustomer.open,
                    select: action.customer,
                    type: state.openCustomer.type,
                    loading: false
                }
            };


        case customerConstants.GET_TOP_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
                customers: []
            };
        case customerConstants.GET_TOP_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.customers
            };
        case customerConstants.GET_TOP_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false
            };

        case customerConstants.GET_EMPLOYEES_REQUEST:
            return {
                ...state,
                loading: true,
                employees: []
            };
        case customerConstants.GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                employees: action.employees
            };
        case customerConstants.GET_EMPLOYEES_FAILURE:
            return {
                ...state,
                loading: false
            };
        case customerConstants.GET_CUSTOMERS_IN_CLOCK_REQUEST:
            return {
                ...state,
                loading: true,
                customersInClock: []
            };
        case customerConstants.GET_CUSTOMERS_IN_CLOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                customersInClock: action.customersInClock
            };
        case customerConstants.GET_CUSTOMERS_IN_CLOCK_FAILURE:
            return {
                ...state,
                loading: false
            };

  
        case customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                customers : action.customers,
            };
        case customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_FAILURE:
            return {
                ...state,
                loading: false
            };
        case customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_REQUEST:
            return {
                ...state,
                 openSelectCustomer: {
                    open: state.openSelectCustomer.open,
                    select:[],
                    type: state.openSelectCustomer.type,
                    loading: true,
                }
            };
        case customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_SUCCESS:
            return {
                ...state,
                openSelectCustomer: {
                    open: state.openSelectCustomer.open,
                    select:action.customers,
                    type: state.openSelectCustomer.type,
                    loading: false,
                }
            };
        case customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_FAILURE:
            return {
                ...state,
                openSelectCustomer: {
                    open: state.openSelectCustomer.open,
                    select:state.openSelectCustomer.select,
                    type: state.openSelectCustomer.type,
                    loading: false,
                }
            };

        case customerConstants.SYNC_FINGER_REQUEST:
            return {
                ...state,
                 openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: true,
                }
            };
        case customerConstants.SYNC_FINGER_SUCCESS:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: false,
                }
            };
        case customerConstants.SYNC_FINGER_FAILURE:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                       select:action.customer,
                    type: state.openCustomer.type,
                    loading: false,
                }
            };
        case customerConstants.REMOVE_FINGER_REQUEST:
            return {
                ...state,
                 openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: true,
                }
            };
        case customerConstants.REMOVE_FINGER_SUCCESS:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: false,
                }
            };
        case customerConstants.REMOVE_FINGER_FAILURE:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: false,
                }
            };
        case customerConstants.REMOVE_FINGER_PERMANENT_REQUEST:
            return {
                ...state,
                 openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: true,
                }
            };
        case customerConstants.REMOVE_FINGER_PERMANENT_SUCCESS:
            return {
                ...state,
                openCustomer: {
                    open: false,
                    select:new Customer(),
                    type: state.openCustomer.type,
                    loading: false,
                }
            };
        case customerConstants.REMOVE_FINGER_PERMANENT_FAILURE:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: false,
                }
            };

        

        case customerConstants.MARK_AS_EMPLOYEE_REQUEST:
            return {
                ...state,
                    openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: true,
                }
            };
        case customerConstants.MARK_AS_EMPLOYEE_SUCCESS:
            return {
                ...state,
                customers: action.customers,
                openCustomer: {
                    open: false,
                    select:new Customer(),
                    type: state.openCustomer.type,
                    loading: false,
                }
            };
        case customerConstants.MARK_AS_EMPLOYEE_FAILURE:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: false,
                }
            };

        
        case customerConstants.REMOVE_MARK_AS_EMPLOYEE_REQUEST:
            return {
                ...state,
                    openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: true,
                }
            };
        case customerConstants.REMOVE_MARK_AS_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employess: action.customers,
                openCustomer: {
                    open: false,
                    select:new Customer(),
                    type: state.openCustomer.type,
                    loading: false,
                }
            };
        case customerConstants.REMOVE_MARK_AS_EMPLOYEE_FAILURE:
            return {
                ...state,
                openCustomer: {
                    open: state.openCustomer.open,
                    select:action.customer,
                    type: state.openCustomer.type,
                    loading: false,
                }
            };

        default:
            return state
    }
}