import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from "./reducers";

import { combineReducers, configureStore } from '@reduxjs/toolkit'

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
    )
);




export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']