import axios from 'axios';
import environment from '../../../environment';
import { Customer } from '../models/customer';

export const customerService = {
    enrolFinger,
    syncFinger,
    removeFinger,
    removeFingerPermanent,
    searchByDescription,
    getCustomersInClock,
    add,
    getTopTenCustomers,
    getDevices,
    edit,
    saveFingePrint,
    getEmployees,
    markAsEmployee,
    unmarkAsEmployee
};

 function enrolFinger(customerId: string) {
    return axios.post<number>(`${environment.apiUri}/customer/EnrolFinger/${customerId}`)
  };
 function syncFinger(customerId: string) {
    return axios.post<number>(`${environment.apiUri}/customer/SyncFinger/${customerId}`)
  };
 function removeFinger(customerId: string) {
    return axios.post<number>(`${environment.apiUri}/customer/RemoveFinger/${customerId}`)
  };
 function removeFingerPermanent(customerId: string) {
    return axios.post(`${environment.apiUri}/customer/RemoveFingerPermanent/${customerId}`);
  };
 function searchByDescription(description: string) {
    return axios.get(`${environment.apiUri}/customer/searchByDescription?description=${description}`)
  };

 function add(customer: Customer) {
    return axios.post(`${environment.apiUri}/customer/add`, customer)
  };
  function edit(customer: Customer) {
    return axios.put(`${environment.apiUri}/customer/edit`, customer)
  };
  
  function getDevices() {
    return axios.get(`${environment.apiUri}/device/devices`)
  };
  
  
  function getTopTenCustomers() {
    return axios.get(`${environment.apiUri}/customer`)
  };

  function getEmployees() {
    return axios.get(`${environment.apiUri}/customer/employees`)
  };

  function getCustomersInClock() {
    return axios.get(`${environment.apiUri}/customer/InClock`)
  };

  

  async function saveFingePrint(customerId:string, fingerPrint:string) {
     var result = await axios.post(`${environment.apiUri}/finger`, {
      customerId,
      fingerPrint,
      fingerPrintSize: 1024//TODO 
     })
     return result;
   };

   function markAsEmployee(customerId: string) {
    return axios.post(`${environment.apiUri}/customer/MarkAsEmployee/${customerId}`);
  };

  function unmarkAsEmployee(customerId: string) {
    return axios.post(`${environment.apiUri}/customer/UnmarkAsEmployee/${customerId}`);
  };