import { User } from "../models/user";
import { userConstants } from "../constants/user.constants";
import { UserState } from "../models/user.state";

const initialState: UserState = { 
    loading: true, 
    error: "",
    users:  Array<User>(), 
    link: "",
    openUser: {
        open: false,
        select: new User(),
        type: 0,
        loading: false,
    },
    openRestart: {
        open: false,
        select: new User(),
        type:0,
        loading: false
    }
} ;

export function users(state:UserState = initialState, action:any) {
    switch (action.type) {

        case userConstants.OPEN_MODAL_USER:
            return {
                ...state,
                openUser: action.openUser
            }
        case userConstants.OPEN_MODAL_RESTART:
            return {
                ...state,
                link: "",
                openRestart: action.openRestart
            }
        case userConstants.GET_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                users:[]
            };
        case userConstants.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.users
            };
        case userConstants.GET_USERS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case userConstants.ADD_USER_REQUEST:
            return {
                ...state,
                 openUser: {
                    open: state.openUser.open,
                    select: action.user,
                    type: state.openUser.type,
                    loading: true,
                }
            };
        case userConstants.ADD_USER_SUCCESS:
            return {
                ...state,
                users: action.users,
                openUser: {
                    open: false,
                    select: state.openUser.select,
                    type: state.openUser.type,
                    loading: false,
                }
            };
        case userConstants.ADD_USER_FAILURE:
            return {
                ...state,
                openUser: {
                    open: true,
                    select: action.user,
                    type: state.openUser.type,
                    loading: false,
                }
            };

        case userConstants.EDIT_USER_REQUEST:
            return {
                ...state,
                 openUser: {
                    open: state.openUser.open,
                    select: action.user,
                    type: state.openUser.type,
                    loading: true,
                }
            };
        case userConstants.EDIT_USER_SUCCESS:
            return {
                ...state,
                users: action.users,
                openUser: {
                    open: false,
                    select: state.openUser.select,
                    type: state.openUser.type,
                    loading: false,
                }
            };
        case userConstants.EDIT_USER_FAILURE:
            return {
                ...state,
                openUser: {
                    open: true,
                    select: action.user,
                    type: state.openUser.type,
                    loading: false,
                }
            };
        case userConstants.GENERATE_LINK_REQUEST:
            return {
                ...state,
                 openRestart: {
                    open: true,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: true,
                }
            };
        case userConstants.GENERATE_LINK_SUCCESS:
            return {
                ...state,
                link: action.link,
                openRestart: {
                    open: true,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: false,
                }
            };
        case userConstants.GENERATE_LINK_FAILURE:
            return {
                ...state,
                openRestart: {
                    open: true,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: false,
                }
            };
        case userConstants.SEND_LINK_EMAIL_REQUEST:
            return {
                ...state,
                 openRestart: {
                    open: true,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: true,
                }
            };
        case userConstants.SEND_LINK_EMAIL_SUCCESS:
            return {
                ...state,
                openRestart: {
                    open: false,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: false,
                }
            };
        case userConstants.SEND_LINK_EMAIL_FAILURE:
            return {
                ...state,
                openRestart: {
                    open: true,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: false,
                }
            };
        case userConstants.SEND_LINK_SMS_REQUEST:
            return {
                ...state,
                 openRestart: {
                    open: true,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: true,
                }
            };
        case userConstants.SEND_LINK_SMS_SUCCESS:
            return {
                ...state,
                openRestart: {
                    open: false,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: false,
                }
            };
        case userConstants.SEND_LINK_SMS_FAILURE:
            return {
                ...state,
                openRestart: {
                    open: true,
                    select: state.openRestart.select,
                    type: state.openRestart.type,
                    loading: false,
                }
            };


        default:
            return state
    }
}