import "primereact/resources/themes/fluent-light/theme.css";
//import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../assets/layout/layout.scss';
import './App.css';
import { Navigate, Route,  Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AdminLayout from "../layout/AdminLayout";
import LoginLayout from "../layout/LoginLayout";
import { useEffect } from "react";
import { Button } from "primereact/button";
import { ListUserPage } from "../pages/settings/users/ListUserPage";
import { ListRolePage } from "../pages/settings/roles/ListRolePage";
import { ListPermissionPage } from "../pages/settings/permissions/ListPermissionPage";
import { LoginPage } from "../pages/login/LoginPage";
import { RestartPasswordPage } from "../pages/login/RestartPasswordPage";
import axios from "axios";
import { authActions } from "../auth/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { OfferListPage } from "../pages/offers/OfferListPage";
import { searchBarActions } from "../components/search_bar/actions/search_bar.actions";
import { ItemDetailPage } from "../pages/items/ItemDetailPage";
import { ListItemPage } from "../pages/items/ListItemPage";

import { CustomerListPage } from "../pages/customer/CustomerListPage";
import { SuscriptionPage } from "../pages/suscriptions/SuscriptionPage";
import { ActiveSuscriptionPage } from "../pages/suscriptions/ActiveSuscriptionPage";
import { ListDeviceTaskPage } from "../pages/settings/device-task/ListDeviceTaskPage";
import { DiarySalesPage } from "../pages/reports/DiarySalesPage";
import { SalesByItemPage } from "../pages/reports/SalesByItemPage";
import { MonthSalesPage } from "../pages/reports/MonthSalesPage";
import { reportActions } from "../pages/reports/actions/report.actions";
import { CustomerInClockListPage } from "../pages/customer/CustomerInClockListPage";
import { CustomerLossPage } from "../pages/reports/CustomerLossPage";
import { EmployeerListPage } from "../pages/customer/EmployeerListPage";
import { HomePage } from "../pages/home/HomePage";


function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.authentication.user);
    document.documentElement.style.fontSize = 14 + 'px';
    useEffect(() => {
      //TODO   console.log(location);
        //dispatch(alertActions.clear());
        //dispatch(inventoryActions.locationChange());
      dispatch(searchBarActions.changeShowPreview(false));
      dispatch(reportActions.cleanReports());
    },[location]);

    axios.interceptors.response.use(
    response => response,
    error => {
      const {status} = error?.response??0;
      if (status ===401) {
        dispatch(authActions.logout());
      }
      return Promise.reject(error);
    }
    );
    axios.interceptors.response.use(undefined, (err) => {
      const { config, message } = err;
      if (!config || !config.retry) {
        return Promise.reject(err);
      }
      // retry while Network timeout or Network Error
      if (!(message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(err);
      }
      config.retry -= 1;
      const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
          console.log("retry the request", config.url);
          resolve();
        }, config.retryDelay || 1000);
      });
      return delayRetryRequest.then(() => axios(config));
    });
    
    axios.defaults.headers.common['Authorization'] = `Bearer ${(user?.token??"")}` 


  
  return (
        <Routes>
            <Route path="/" element={<PrivateRoute path="/" component={<AdminLayout />} />} > 
                <Route path="/home" element={<HomePage/>} />
                <Route path="/" element={<HomePage/>} />
                <Route path="/users" element={<ListUserPage/>} />
                <Route path="/roles" element={<ListRolePage/>} />
                <Route path="/device-task" element={<ListDeviceTaskPage/>} />
                <Route path="/customers" element={<CustomerListPage/>} />
                <Route path="/customers-in-clock" element={<CustomerInClockListPage/>} />
                <Route path="/listado-objetos" element={<ListPermissionPage/>} />
                <Route path="/listado-ofertas" element={<OfferListPage/>} />
                <Route path="/producto/:id" element={<ItemDetailPage />} />
                <Route path="/lista-productos" element={<ListItemPage />} />
                <Route path="/items" element={<ListItemPage />} />

                
                <Route path="/diary-sales" element={<DiarySalesPage />} />
                <Route path="/monthly-sales" element={<MonthSalesPage />} />
                <Route path="/sales-by-item" element={<SalesByItemPage />} />

                <Route path="/clientes" element={<CustomerListPage />} />
                <Route path="/employees" element={<EmployeerListPage />} />
                <Route path="/suscription" element={<SuscriptionPage />} />
                <Route path="/active-suscription" element={<ActiveSuscriptionPage />} />
                <Route path="/customers-loss" element={<CustomerLossPage message="asda" />} />
                <Route path="/*" element={<Navigate state={{ from: "*" }}to="/home" />} />
            </Route>
            <Route path="/login" element={<LoginLayout />}> 
                <Route path="/login/ingresar" element={<LoginPage />} />
                <Route path="/login/reiniciar/:id" element={<RestartPasswordPage />} />
                <Route path="/login" element={<Navigate state={{ from: "*" }}to="/login/ingresar" />} />  
            </Route>
            <Route path="/*" element={<Navigate state={{ from: "*" }}to="/" />} /> 
        </Routes>        
  );
}

export default App;
