export const authConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    VALIDATE_CHANGE_PASSWORD_REQUEST: 'VALIDATE_CHANGE_PASSWORD_REQUEST',
    VALIDATE_CHANGE_PASSWORD_SUCCESS: 'VALIDATE_CHANGE_PASSWORD_SUCCESS',
    VALIDATE_CHANGE_PASSWORD_FAILURE: 'VALIDATE_CHANGE_PASSWORD_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    SEND_LINK_USER_REQUEST: 'SEND_LINK_USER_REQUEST',
    SEND_LINK_USER_SUCCESS: 'SEND_LINK_USER_SUCCESS',
    SEND_LINK_USER_FAILURE: 'SEND_LINK_USER_FAILURE',

    OPEN_MODAL_RESTART_EXTERNAL: 'OPEN_MODAL_RESTART_EXTERNAL', 
    LOGOUT: 'USERS_LOGOUT', 
};