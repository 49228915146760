import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DialogProps } from '../../../models/dialog.props';
import { InputText } from 'primereact/inputtext';
import {DebounceInput} from 'react-debounce-input';
import { Category } from '../models/category';
import { categoryActions } from '../actions/category.actions';
import { ProductGroup } from '../models/product_group';
import { TemplatePaginator } from '../../../components/TemplatePaginator';

  function DialogSelectProductGroup(props: DialogProps<ProductGroup>) {
    const { onClose} = props;
    const dispatch = useDispatch();    
    const openSelectProductGroup  = useSelector((state: any) => state.categories.openSelectProductGroup as DataDialogProps<ProductGroup[]>);

    const handleClose = () => {
      dispatch(categoryActions.openModalSearchProductGroup({
        open : false,
        select :[],
        type : 0,
        loading: false
      }));
    };

     const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                    <span className="p-input-icon-left" style={{width:"100%"}}>
                    { openSelectProductGroup.loading ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                     <DebounceInput
                        placeholder="Buscar"
                        minLength={1}
                        style={{width:"100%"}}
                        onChange={event => {
                            if((event.target?.value??"").length > 1){
                                dispatch(categoryActions.searchProductGroupByDescription(event.target.value));
                            }
                        }} 
                        value=""
                        debounceTimeout={400}
                        element={InputText as any} />
                </span>
                {/* <h5 className="m-0">{ "Seleccione un tipo de item"}</h5>
                    <Button style={{width: "170px"}} label={"Agregar " + typeItem} icon="pi pi-plus" /> */}
            </div>
        )
    }

    const handOnSelectItem = (category: Category)=>{
        onClose!(category);
        handleClose();
    }

    const header = renderHeader();
  
    return (
        <Dialog header={"Listado de Grupo de Articulos"} position="top" visible={openSelectProductGroup?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '55vw'}}>
            <div>  
                <DataTable  emptyMessage="Sin resultados" loading={openSelectProductGroup.loading} 
                    paginatorTemplate={TemplatePaginator as any} rows={10}  disabled={openSelectProductGroup.loading} selectionMode="single" 
                    value={openSelectProductGroup.select} responsiveLayout="scroll" 
                    onSelectionChange={e => handOnSelectItem(e.value)} size="small" stripedRows header={header} >
                    <Column field="productGroupId" header="No"></Column>
                    <Column field="description" header="Grupo"></Column>
                    <Column field="category" header="Categoría"></Column>
                    {/* <Column field="active" body={statusBodyTemplate} header="Estado"></Column>
                    <Column header="Acción" body={actionBodyTemplate} /> */}
                </DataTable>
            </div>
        </Dialog>
    );
  }
    
  export { DialogSelectProductGroup };