import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { customerActions } from './actions/customer.actions';
import { User } from '../settings/users/models/user';
import { Column } from 'primereact/column';
import { Customer } from './models/customer';
import { DebounceInput } from 'react-debounce-input';
import { InputText } from 'primereact/inputtext';
import { DialogCustomer } from './components/dialog.customer';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { TypeDialog } from '../../helpers/type_dialog';
import Logo from'../../assets/images/logo.png';
import { ImageSrc } from '../../components/image-src';
import { Badge } from 'primereact/badge';

function CustomerListPage() {
    const loading: boolean= useSelector((state:any) => state.customer.loading);
    const customers: Customer[]= useSelector((state:any) => state.customer.customers);
    const dispatch = useDispatch();
    const user  = useSelector((state: any) => state.authentication.user as User);

    useEffect(() => { 
       dispatch(customerActions.getTopTenCustomers()); 
    },[]);



    const onClose = ()=>{
    }
 const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                    <span className="p-input-icon-left" >
                    { loading ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                     <DebounceInput
                        placeholder="Buscar"
                        minLength={1}
                        style={{width:"100%"}}
                        onChange={event => {
                            if((event.target?.value??"").length > 1){
                                dispatch(customerActions.searchByDescription(event.target.value));
                            }
                        }} 
                        value=""
                        debounceTimeout={400}
                        element={InputText as any} />
                </span>
                  <span className="p-input-icon-left">
                    <Button label="Agregar nuevo" onClick={handAdd} icon="pi pi-pencil" iconPos="left"  />
                </span>
            </div>
        )
    }

       const  handAdd = () =>{
         dispatch(customerActions.openModalCustomer({
                open : true,
                select : new User(),
                type : TypeDialog.add,
                loading: false
            }));
    }

    const header = renderHeader();

  const actionBodyTemplate = (customer:Customer) => {
            const showDialogCustomer =()=>{
                dispatch(customerActions.openModalCustomer({
                    open : true,
                    select :customer,
                    type : TypeDialog.edit,
                    loading: false
                }));
            }
        // return  <span className="p-buttonset">
        //             <Button  tooltip="Editar" tooltipOptions={{position: 'left'}} icon="pi pi-pencil" iconPos="left" className='p-button-text' onClick={showDialogCustomer} />
        //             {/* <Button  tooltip="Estado de Cuenta" tooltipOptions={{position: 'left'}} icon="pi pi-book" iconPos="left" className="p-button-text p-button-info" onClick={showDialogFinancial} />
        //             <Button  tooltip="Estado de Cuenta Historico"  tooltipOptions={{position: 'left'}} icon="pi pi-book" iconPos="left" className="p-button-text p-button-danger" onClick={showDialogFinancialHistoric} /> */}
        //         </span>
          return   <span className="p-buttonset">
                    <Button label="Editar" icon="pi pi-pencil" iconPos="left" onClick={showDialogCustomer} />
                    {/* <Button label="Restablecer" className="p-button-success" icon="pi pi-info-circle" iconPos="left" onClick={showDialogRestart} /> */}
                </span>
    }


        const photoBodyTemplate = (customer: Customer)=>{
        return  (customer.photoUri) ?
            <ImageSrc height="40px" width="60px" src={customer.photoUri} />
            :<img height="40px" width="40px"src={ Logo || ""} alt="" />
        }

        const nameTemplate = (customer: Customer)=>{
            return  <span>{customer.name} { customer.isEmployee ?  <Badge value="Empleado" severity="success"></Badge> : <></> } </span>
        }


    return (
        <div className="grid mt-3">
                <div className="card col-12">
                    {header}
                   <DataTable  emptyMessage="Sin resultados" paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={customers??[]} responsiveLayout="scroll"  
                        size="small" stripedRows  >
                        <Column filter filterType="contains" field="photoId" header="Foto" body={photoBodyTemplate}></Column>
                        <Column filter filterType="contains" field="customerId" header="No"></Column>
                        <Column filter filterType="contains" field="name" header="Nombre" body={nameTemplate}></Column>
                        <Column sortable filter filterType="contains" field="dni" header="Identidad" ></Column>
                        <Column sortable filter filterType="contains" field="phone" header="Teléfono"></Column>
                        {/* <Column sortable filter filterType="contains" field="email" header="Correo" ></Column> */}
                        <Column header="Acción" body={actionBodyTemplate} />
                    </DataTable>
                </div>
            <DialogCustomer onClose={onClose}/>
        </div>
    )
}

export { CustomerListPage };