import { useDispatch, useSelector } from "react-redux";
import { filepickerActions } from "./filepicker.action";
import { useEffect } from 'react';
import environment from "../../environment";
import React from "react";
import { FileUpload } from "./models/file_upload";
import { ImageSrc } from "../../components/image-src";
import { DialogCamera } from "./components/dialog.camera";
import { Button } from "primereact/button";
import { ToastError } from "../../auth/reducers/alert.reducer";
import { dataUrlToFile } from "../../helpers/format";
import Logo from'../../assets/images/logo.png';

    export interface FileParams {
        onClose: (value: FileUpload) => void;
        photoUri: string;
    }

    const FilePickerInHouse = (props:FileParams) => {
    const { onClose, photoUri } = props;
    const user = useSelector((state:any) => state.authentication.user);
    const loading = useSelector((state:any) => state.filePicker.loading as boolean);
    const file = useSelector((state:any) => state.filePicker.file);
    const dispatch = useDispatch();


    useEffect(() => {
        if(file.path){
            onClose(file);
            dispatch(filepickerActions.cleanFile());
            console.log("Change");
        }
    }, [file]);

 const onImageSelect =  async (image: string)=>{
        var file2 = await dataUrlToFile(image, "ejemplo.png")
         const ACCEPTED_UPLOAD_SIZE = 5120; // 5MB
        if ( bytesToSize(file2.size) > ACCEPTED_UPLOAD_SIZE) 
            {
                ToastError.fire({
                    icon: 'warning',
                    title: 'El archivo es demasiado grande'
                    });
                return;
            }
        dispatch(filepickerActions.uploadFile(file2));
    }
 

    const bytesToSize = (bytes: any) => {
        // var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return 0;
        // var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / 1024);
      }

  
    const onCamera = () =>{
            dispatch(filepickerActions.openModalCamera({
                open : true,
                select :"",
                type : 0,
                loading: false
            }));
        }


    const customBase64Uploader = async (event:any) => {
        let file = event.target.files[0];  
        const ACCEPTED_UPLOAD_SIZE = 5120; // 5MB
        if ( bytesToSize(file.size) > ACCEPTED_UPLOAD_SIZE) 
            {
                ToastError.fire({
                    icon: 'warning',
                    title: 'El archivo es demasiado grande'
                    });
                return;
            }
        dispatch(filepickerActions.uploadFile(file));
        event.files =null;
        event.value = null;

    }


  return (

     <div className='p-fluid grid'> 
        {
            (loading)  ?
            <i className="pi pi-spin pi-spinner" />
            : (photoUri) ?
            <ImageSrc  src={photoUri} />

            :<img height="100%" width={"100%"} style={{maxHeight:"210px", objectFit: "cover"}} src={ Logo || ""} alt="" />
        }

        <div className="flex flex-wrap">
            <Button icon="pi pi-camera" label='Camara'onClick={onCamera} className="p-button-danger p-button-outlined" /> 
            <label className="custom-file-upload">
                <input type="file" name="file" placeholder="Seleccione un archivo" onChange={customBase64Uploader} accept=".png,.jpg,.jpge"/>                                  
                <i className="pi pi-upload mr-3" ></i> Galería
            </label>
        </div> 
        <DialogCamera onClose={onImageSelect} ></DialogCamera>
    </div>

    // <Grid container spacing={3}>
    //     <Grid item xs={1}>
    //         <Tooltip title="Agregar" placement="top" arrow>
    //             <label className="custom-file-upload">
    //                 <input type="file" name="file" placeholder="Seleccione un archivo" onChange={handleChangeFile} accept=".pdf,.xlsx,.png,.jpg,.jpge"/>
    //                 {
    //                     loading ? <CircularProgress size="1.5rem"  disableShrink />: <AddCircleIcon/>
    //                 }                    

    //             </label>
    //         </Tooltip>
    //     </Grid>

    //     {
    //         files.map((item: FilePickerFile)=>{
    //             return <Grid item xs={1} >
    //                 <Tooltip title={item.description || ''} placement="top" arrow>
    //                     <label onClick={() => goTo(item.path || '')} className={"custom-file-upload " + 
    //                         (   
    //                             item.extension == 'pdf' ? 'red' : 
    //                             item.extension == 'xlsx' ? 'green' :
    //                             item.extension == 'xlsx1' ? 'example' :
    //                             item.extension == 'png' || item.extension == 'jpg' || item.extension == 'jpeg'? 'orange' :
    //                             ''  
    //                         )}>
    //                         {
    //                             item.extension == 'pdf' ? 
    //                             <PictureAsPdfIcon/>
    //                             :item.extension == 'xlsx' ?
    //                             <ExplicitIcon/>
    //                             :item.extension == 'xlsx1' ?
    //                             <React.Fragment>
    //                                 {/* <NotificationImportantIcon/> */}
    //                                 <small>Ejemplo</small>

    //                             </React.Fragment>

    //                             :item.extension == 'png' || item.extension == 'jpg' || item.extension == 'jpeg' ?
    //                             <InsertPhotoIcon/>
    //                             :<AttachFileIcon/>
    //                         }

                            
    //                     </label>
    //                 </Tooltip>
    //             </Grid>
    //         })
    //     }
    // </Grid>    
  );

};

export default FilePickerInHouse;

