import axios from 'axios';
import environment from '../environment';

export const authService = {
    login,
    logout,
    validateChangePasswordId,   
    sendLink,
    changePassword
};

 function login(userName:string, password:string) {
    return axios.post(`${environment.apiUri}/auth`, { userName: userName, password: password})
  };


 function changePassword(changePassword: any, token: string) {
    return axios.post(`${environment.apiUri}/user/ChangePassword`, changePassword,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    )
  };
  

 function validateChangePasswordId(id:string) {
    return axios.get(`${environment.apiUri}/auth/ValidateChangePasswordId?Id=${id}`)
  };

 function sendLink(userName:string) {
    return axios.get(`${environment.apiUri}/auth/SendLinkFogetPassword?UserName=${userName}`)
  };
  


function logout() {
    localStorage.removeItem('user');
}