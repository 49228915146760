import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../../auth/actions/alert.actions";
import { DataDialogProps } from "../../../../models/data.dialog.props";
import { Store } from "../../users/models/store";
import { roleConstants } from "../constants/role.constants";
import { Role } from "../models/role";
import { roleService } from "../role.service";

export const roleActions = {
    openModalRole,
    getRoles,
    getStores,
    getRoleWithDetail,
    add,
    edit
};

function openModalRole(openRole:DataDialogProps<Role>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: roleConstants.OPEN_MODAL_ROLE, openRole });
    };
}
 
function getRoles() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        roleService.getRoles()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: roleConstants.GET_ROLES_REQUEST } }
    function success(roles:Role[]) { return { type: roleConstants.GET_ROLES_SUCCESS, roles } }
    function failure(error:any) { return { type: roleConstants.GET_ROLES_FAILURE, error } }
}
function getStores() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        roleService.getStores()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: roleConstants.GET_STORES_REQUEST } }
    function success(stores:Store[]) { return { type: roleConstants.GET_STORES_SUCCESS, stores } }
    function failure(error:any) { return { type: roleConstants.GET_STORES_FAILURE, error } }
}

function getRoleWithDetail(roleId: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        roleService.getRoleWithDetail(roleId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
 
    function request() { return { type: roleConstants.GET_ROLE_DETAIL_REQUEST } }
    function success(role:Role) { return { type: roleConstants.GET_ROLE_DETAIL_SUCCESS, role } }
    function failure(error:any) { return { type: roleConstants.GET_ROLE_DETAIL_FAILURE, error } }
}


function add(role: Role) {
    return (dispatch: Dispatch) => {
        dispatch(request(role));
        roleService.add(role)
            .then((result)=> { 
                    dispatch(success(result.data, role));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, role));
                dispatch(alertActions.error(message));
            });
    };

    function request(role: Role) { return { type: roleConstants.ADD_ROLE_REQUEST, role} }
    function success(roles:Role[], role: Role) { return { type: roleConstants.ADD_ROLE_SUCCESS, roles, role } }
    function failure(error:any, role: Role) { return { type: roleConstants.ADD_ROLE_FAILURE, error , role} }
}

function edit(role: Role) {
    return (dispatch: Dispatch) => {
        dispatch(request(role));
        roleService.edit(role)
            .then((result)=> { 
                    dispatch(success(result.data, role));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, role));
                dispatch(alertActions.error(message));
            });
    };

    function request(role: Role) { return { type: roleConstants.EDIT_ROLE_REQUEST, role } }
    function success(roles:Role[], role: Role) { return { type: roleConstants.EDIT_ROLE_SUCCESS, roles, role } }
    function failure(error:any, role: Role) { return { type: roleConstants.EDIT_ROLE_FAILURE, error, role } }
}
