import { categoryConstants } from "../constants/category.constants";
import { Category } from "../models/category";
import { CategoryState } from "../models/category.state";
import { ProductGroup } from "../models/product_group";

const initialState: CategoryState = { 
    loading: true, 
    error: "",
    categoriesSearch:  Array<Category>(), 
    productGroupsSearch:  Array<ProductGroup>(), 
    openSelectCategory: {
        open: false,
        select: [],
        type: 0,
        loading: false,
    },
    openSelectProductGroup: {
        open: false,
        select: [],
        type: 0,
        loading: false,
    }
};

export function categories(state:CategoryState = initialState, action:any) {
    switch (action.type) {

        case categoryConstants.OPEN_MODAL_SEARCH_CATEGORY:
            return {
                ...state,
                openSelectCategory: action.openSelectCategory
            };

        case categoryConstants.OPEN_MODAL_SEARCH_PRODUCT_GROUP:
            return {
                ...state,
                openSelectProductGroup: action.openSelectProductGroup
            };

        case categoryConstants.SEARCH_CATEGORY_REQUEST:
            return {
                ...state,
                openSelectCategory: {
                    open: state.openSelectCategory.open,
                    select: state.openSelectCategory.select,
                    type: state.openSelectCategory.type,
                    loading: true
                }
            };
        case categoryConstants.SEARCH_CATEGORY_SUCCESS:
            return {
                ...state,
                openSelectCategory: {
                    open: state.openSelectCategory.open,
                    select: action.categories,
                    type: state.openSelectCategory.type,
                    loading: false
                }
            };
        case categoryConstants.SEARCH_CATEGORY_FAILURE:
            return {
                ...state,
                openSelectCategory: {
                    open: state.openSelectCategory.open,
                    select: state.openSelectCategory.select,
                    type: state.openSelectCategory.type,
                    loading: false
                }
            };

             case categoryConstants.SEARCH_PRODUCT_GROUP_REQUEST:
            return {
                ...state,
                openSelectProductGroup: {
                    open: state.openSelectProductGroup.open,
                    select: state.openSelectProductGroup.select,
                    type: state.openSelectProductGroup.type,
                    loading: true
                }
            };
        case categoryConstants.SEARCH_PRODUCT_GROUP_SUCCESS:
            return {
                ...state,
                openSelectProductGroup: {
                    open: state.openSelectProductGroup.open,
                    select: action.productGroups,
                    type: state.openSelectProductGroup.type,
                    loading: false
                }
            };
        case categoryConstants.SEARCH_PRODUCT_GROUP_FAILURE:
            return {
                ...state,
                openSelectProductGroup: {
                    open: state.openSelectProductGroup.open,
                    select: state.openSelectProductGroup.select,
                    type: state.openSelectProductGroup.type,
                    loading: false
                }
            };
        default:
            return state
    }
}