import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect }  from 'react';
import { Button } from 'primereact/button';
import { reportActions } from './actions/report.actions';
import TableRenderers from 'react-pivottable/TableRenderers';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import { months, years } from '../../helpers/consts';
import { Dropdown } from 'primereact/dropdown';
import { MonthlySales } from './models/monthly_sales';

function MonthSalesPage() {
    const monthlySales: MonthlySales[]= useSelector((state:any) => state.reports.monthlySales);
    const loading: boolean= useSelector((state:any) => state.reports.loading);
    const [submit, setSubmit] = React.useState<boolean>();
    const [month, setMonth] = React.useState<number>(new Date().getMonth()+ 1);
    const [year, setYear] = React.useState<number>(new Date().getFullYear());
    const [pivotState, setPivotState] = React.useState();
    const dispatch = useDispatch();
    const [currentInputs, setCurrenInputs] = React.useState<MonthlySales[]>(monthlySales);


    useEffect(() => {
        setCurrenInputs(monthlySales);
    },[monthlySales]);


    const handSearch = () => {

        setSubmit(true)
        dispatch(reportActions.searchMonthlySales(month, year));
    }
    



        // const renderHeader = () => {
        //         return (
        //             <div className="flex justify-content-between align-items-center">
        //                 <h5>Articulos/Suscripciones</h5>
        //                 <span className="p-input-icon-left mb-2">
        //                     <Button  style={{width:"200px"}} disabled={!inputs.customerId || loading || cannotEdit()} label="Agregar" onClick={handAdd} icon="pi pi-plus" iconPos="left"  />
        //                 </span>
        //             </div>
        //         )
        //     }
    
 
    // const header = renderHeader();
 
   

   function handleMonth(e:any) {
        const { name, value } = e.target;
        setMonth(value);

   }
   function handleYear(e:any) {
        const { name, value } = e.target;
        setYear(value);
   }

    return (
        <div className="card">
            <div className="p-fluid grid mt-3">
                 <div className="field col-2">  
                    <span className="p-float-label">
                        <Dropdown inputId="dropdownmonth" name="month" value={month} options={months} onChange={handleMonth} optionLabel="name" optionValue="month" />
                        <label htmlFor="dropdownmonth">Mes</label>
                        {(submit&& !month) ?<small id="month-help" className="block p-error">Seleccione el mes</small>: <></> }
                    </span>
                </div>
                <div className="field col-2">
                    <span className="p-float-label">
                        <Dropdown inputId="dropdownYear" name="year" value={year} options={years} onChange={handleYear} optionLabel="name" optionValue="name" />
                        
                        <label htmlFor="dropdownYear">Año</label>
                        {(submit&& !month) ?<small id="year-help" className="block p-error">Seleccione el año</small>: <></> }
                    </span>
                </div>

                <div className=" col-2 ">
                    <Button label="Buscar" onClick={handSearch} icon="pi pi-search" iconPos="left"  />
                </div>
                 {loading ? <SkeletonComponent/> :
                <div className="col-12">

                         <PivotTableUI  
                        rows= {[ 'Fecha']}//, 'vat', 'subTotal', 'total' ,,  'Cliente'  
                        data={(currentInputs??[]).map((item)=> { 
                            return {
                                Fecha: item.createdDateString,
                                Producto: item.item,
                                Cliente: item.customer,
                                Dia: item.day,
                                Impuesto: item.vat,
                                SubTotal: item.subTotal,
                                Total: item.total
                            }}) }                
                        aggregatorName={"Sum"}
                         vals={[ 'Total']}
                        // valueFilter={['vat']}
                   
                        hiddenFromDragDrop={['itemId']}
                        onChange={(s:any) => setPivotState(s)}
                        renderers={Object.assign({}, TableRenderers)}
                        localeStrings={"es"}
                        {...pivotState as any}
                       
                    />
                </div>
                }
            </div>
        </div>
    );
}

export { MonthSalesPage };

