import axios from 'axios';
import environment from '../../environment';

export const durationService = {
    get
};

function get() {
    return axios.get(`${environment.apiUri}/shared/durations`)
  };

