import { homeConstants } from "../constants/home.constants";
import { HomeState } from "../models/home.state";
import { WeekData } from "../models/week-data";

const initialState: HomeState = { 
    loading: false, 
    data:new WeekData(),
    error: "",    
};

export function home(state:HomeState = initialState, action:any) {
    switch (action.type) {

        case homeConstants.GET_HOME_DATA_REQUEST:
            return {
                ...state,
                data: new WeekData(),
                loading:true
            };
        case homeConstants.GET_HOME_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data
            };
        case homeConstants.GET_HOME_DATA_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}