import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { DataDialogProps } from "../../../models/data.dialog.props";
import { customerConstants } from "../constants/customer.constants";
import { Customer } from "../models/customer";
import { CustomerInClock } from "../models/customer_in_clock";
import { customerService } from "../services/customer.service";
import { Device } from "../models/device";

export const customerActions = {
    openModalSearchCustomer,
    openModalCustomer,
    openEnrolFinger,
    closeEnrolFinger,
    searchByDescriptionDialog, 
    getTopTenCustomers,
    getCustomersInClock,
    searchByDescription,
    //uploadImage,
    getEmployees,
    add,
    edit,
    removeFingerPermanent,
    syncFinger,
    updateSelectCustomer,
    removeFinger,
    getDevices,
    openNewEnrolFinger,
    closeNewEnrolFinger,
    saveFingePrint,
    markAsEmployee,
    unmarkAsEmployee
};

// function openModalSearchCustomer(openSelectCustomer:DataDialogProps<Customer[]>) {
//     return (dispatch: Dispatch) => {
//         dispatch(  { type: customerConstants.OPEN_MODAL_SEARCH_CUSTOMER, openSelectCustomer });
//     };
// }

function getDevices() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        customerService.getDevices()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: customerConstants.GET_DEVICES_REQUEST } }
    function success(devices:Device[]) { return { type: customerConstants.GET_DEVICES_SUCCESS,  devices} }
    function failure(error:any) { return { type: customerConstants.GET_DEVICES_FAILURE, error } }
}

function saveFingePrint(customerId:string, fingerPrint:string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        customerService.saveFingePrint(customerId, fingerPrint)
            .then((result)=> { 
                    dispatch(success());
                    (window as any).electron.close();
                    dispatch(alertActions.success("Huella registrada correctamente"));
                    dispatch(getTopTenCustomers() as any);
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message + "Intente nuevamente"));
            });
    };
    function request() { return { type: customerConstants.SAVE_FINGERPRINT_REQUEST } }
    function success() { return { type: customerConstants.SAVE_FINGERPRINT_SUCCESS} }
    function failure(error:any) { return { type: customerConstants.SAVE_FINGERPRINT_FAILURE, error } }
}


function openModalSearchCustomer(openSelectCustomer:DataDialogProps<Customer[]>) {
    return (dispatch: Dispatch) => {
        dispatch(request(openSelectCustomer));
        customerService.getTopTenCustomers()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request(openSelectCustomer:DataDialogProps<Customer[]>) { return { type: customerConstants.OPEN_MODAL_SEARCH_CUSTOMER_REQUEST, openSelectCustomer } }
    function success(customers:Customer[]) { return { type: customerConstants.OPEN_MODAL_SEARCH_CUSTOMER_SUCCESS,  customers} }
    function failure(error:any) { return { type: customerConstants.OPEN_MODAL_SEARCH_CUSTOMER_FAILURE, error } }
}




function openModalCustomer(openCustomer:DataDialogProps<Customer>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: customerConstants.OPEN_MODAL_CUSTOMER, openCustomer });
    };
}

function closeEnrolFinger(openEnrolFinger:DataDialogProps<(string)>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: customerConstants.CLOSE_MODAL_ENROL_FINGER, openEnrolFinger });
    };
}
function updateSelectCustomer(customer:Customer) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: customerConstants.UPDATE_SELECT_CUSTOMER, customer });
    };
}


function openEnrolFinger(customerId: string ,openEnrolFinger:DataDialogProps<string>) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        customerService.enrolFinger(customerId)
            .then((result)=> { 
                    dispatch(success(result.data, openEnrolFinger));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: customerConstants.OPEN_MODAL_ENROL_FINGER_REQUEST } }
    function success(pin:number, openEnrolFinger:DataDialogProps<string>) { return { type: customerConstants.OPEN_MODAL_ENROL_FINGER_SUCCESS, pin , openEnrolFinger} }
    function failure(error:any) { return { type: customerConstants.OPEN_MODAL_ENROL_FINGER_FAILURE, error } }
}
function openNewEnrolFinger(customerId: string ,openNewEnrolFinger:DataDialogProps<string>) {
    return (dispatch: Dispatch) => {
        dispatch(open(customerId,openNewEnrolFinger));
    };
    function open(customerId:string, openNewEnrolFinger:DataDialogProps<string>) { return { type: customerConstants.OPEN_MODAL_NEW_ENROL_FINGER , customerId, openNewEnrolFinger} }
}
function closeNewEnrolFinger(openNewEnrolFinger:DataDialogProps<string>) {
    return (dispatch: Dispatch) => {
        dispatch(close(openNewEnrolFinger));
    };
    function close(openNewEnrolFinger:DataDialogProps<string>) { return { type: customerConstants.CLOSE_MODAL_NEW_ENROL_FINGER , openNewEnrolFinger} }
}


function getTopTenCustomers() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        customerService.getTopTenCustomers()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: customerConstants.GET_TOP_CUSTOMERS_REQUEST } }
    function success(customers:Customer[]) { return { type: customerConstants.GET_TOP_CUSTOMERS_SUCCESS, customers } }
    function failure(error:any) { return { type: customerConstants.GET_TOP_CUSTOMERS_FAILURE, error } }
}

function getEmployees() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        customerService.getEmployees()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: customerConstants.GET_EMPLOYEES_REQUEST } }
    function success(employees:Customer[]) { return { type: customerConstants.GET_EMPLOYEES_SUCCESS, employees } }
    function failure(error:any) { return { type: customerConstants.GET_EMPLOYEES_FAILURE, error } }
}

function getCustomersInClock() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        customerService.getCustomersInClock()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: customerConstants.GET_CUSTOMERS_IN_CLOCK_REQUEST } }
    function success(customersInClock:CustomerInClock[]) { return { type: customerConstants.GET_CUSTOMERS_IN_CLOCK_SUCCESS, customersInClock } }
    function failure(error:any) { return { type: customerConstants.GET_CUSTOMERS_IN_CLOCK_FAILURE, error } }
}


function syncFinger(customer:Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request(customer));
        customerService.syncFinger(customer.customerId!)
            .then((result)=> { 
                    dispatch(success(customer));
                    dispatch(alertActions.success("Solicitud enviada, en unos segundos será sincronizada"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(customer,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(customer:Customer) { return { type: customerConstants.SYNC_FINGER_REQUEST, customer } }
    function success(customer:Customer) { return { type: customerConstants.SYNC_FINGER_SUCCESS, customer } }
    function failure(customer:Customer,error:any) { return { type: customerConstants.SYNC_FINGER_FAILURE , customer,error } }
}

function removeFinger(customer:Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request(customer));
        customerService.removeFinger(customer.customerId!)
            .then((result)=> { 
                    dispatch(success(customer));
                    dispatch(alertActions.success("Solicitud enviada, en unos segundos será sincronizada"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(customer,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(customer: Customer) { return { type: customerConstants.REMOVE_FINGER_REQUEST, customer } }
    function success(customer:Customer) { return { type: customerConstants.REMOVE_FINGER_SUCCESS, customer } }
    function failure(customer:Customer,error:any) { return { type: customerConstants.REMOVE_FINGER_FAILURE,customer, error } }
}
function removeFingerPermanent(customer:Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request(customer));
        customerService.removeFingerPermanent(customer.customerId!)
            .then((result)=> { 
                    dispatch(success(customer, result.data));
                    dispatch(alertActions.success("Solicitud enviada, en unos segundos será sincronizada"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(customer,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(customer: Customer) { return { type: customerConstants.REMOVE_FINGER_PERMANENT_REQUEST, customer } }
    function success(customer:Customer, customers: Customer[]) { return { type: customerConstants.REMOVE_FINGER_PERMANENT_SUCCESS, customer, customers } }
    function failure(customer:Customer,error:any) { return { type: customerConstants.REMOVE_FINGER_PERMANENT_FAILURE,customer, error } }
}


function searchByDescription(description: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        customerService.searchByDescription(description)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_REQUEST } }
    function success(customers:Customer[]) { return { type: customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_SUCCESS, customers } }
    function failure(error:any) { return { type: customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_FAILURE, error } }
}
function searchByDescriptionDialog(description: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        customerService.searchByDescription(description)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_REQUEST } }
    function success(customers:Customer[]) { return { type: customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_SUCCESS, customers } }
    function failure(error:any) { return { type: customerConstants.SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_FAILURE, error } }
}

// function uploadImage(customer: Customer,file: File) {
//     return (dispatch: Dispatch) => {
//         dispatch(request(customer));
//         customerService.uploadFile(file)
//             .then((result)=> { 
//                     customer.fileId = result.data.fileId;
//                     customer.photoUri = result.data.path;
//                     dispatch(success(customer));
//                     dispatch(alertActions.success("Imagen agregada"));
//                 },

//             ).catch((error)=>{
//                 const message =
//                 (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
//                 dispatch(failure(customer,message));
//                 dispatch(alertActions.error(message));
//             });
//     };
//     function request(customer: Customer) { return { type: customerConstants.UPLOAD_IMAGE_REQUEST , customer} }
//     function success(customer:Customer) { return { type: customerConstants.UPLOAD_IMAGE_SUCCESS, customer } }
//     function failure(customer: Customer,error:any) { return { type: customerConstants.UPLOAD_IMAGE_FAILURE,customer, error } }
// }




function add(customer: Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request(customer));
        customerService.add(customer)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success("Cliente agregado"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(customer,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(customer: Customer) { return { type: customerConstants.ADD_CUSTOMER_REQUEST , customer} }
    function success(customer:Customer) { return { type: customerConstants.ADD_CUSTOMER_SUCCESS, customer } }
    function failure(customer: Customer,error:any) { return { type: customerConstants.ADD_CUSTOMER_FAILURE,customer, error } }
}

function edit(customer: Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request(customer));
        customerService.edit(customer)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success("Cliente editado"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(customer,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(customer: Customer) { return { type: customerConstants.EDIT_CUSTOMER_REQUEST , customer} }
    function success(customer:Customer) { return { type: customerConstants.EDIT_CUSTOMER_SUCCESS, customer } }
    function failure(customer: Customer,error:any) { return { type: customerConstants.EDIT_CUSTOMER_FAILURE,customer, error } }
}

function markAsEmployee(customer:Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request(customer));
        customerService.markAsEmployee(customer.customerId!)
            .then((result)=> { 
                    dispatch(success(customer, result.data));
                    dispatch(alertActions.success("Solicitud enviada, en unos segundos será sincronizada"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(customer,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(customer: Customer) { return { type: customerConstants.MARK_AS_EMPLOYEE_REQUEST, customer } }
    function success(customer:Customer, customers: Customer[]) { return { type: customerConstants.MARK_AS_EMPLOYEE_SUCCESS, customer, customers } }
    function failure(customer:Customer,error:any) { return { type: customerConstants.MARK_AS_EMPLOYEE_FAILURE,customer, error } }
}

function unmarkAsEmployee(customer:Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request(customer));
        customerService.unmarkAsEmployee(customer.customerId!)
            .then((result)=> { 
                    dispatch(success(customer, result.data));
                    dispatch(alertActions.success("Solicitud enviada, en unos segundos será sincronizada"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(customer,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(customer: Customer) { return { type: customerConstants.REMOVE_MARK_AS_EMPLOYEE_REQUEST, customer } }
    function success(customer:Customer, customers: Customer[]) { return { type: customerConstants.REMOVE_MARK_AS_EMPLOYEE_SUCCESS, customer, customers } }
    function failure(customer:Customer,error:any) { return { type: customerConstants.REMOVE_MARK_AS_EMPLOYEE_FAILURE,customer, error } }
}