import { ImageProps } from '../models/image.props';
import environment from '../environment';
import { Image } from 'primereact/image';
  function ImageSrc(props: ImageProps) {
    const { src, width, height} = props;
    const urlLogo = "https://firebasestorage.googleapis.com/v0/b/stickerplus-5187e.appspot.com/o/logo.png?alt=media&token=34ec12f4-f72c-4389-937f-559f68f5815e";
    return (
      <Image preview  src={ environment.isProduction ? `${environment.apiUri}/files/DownloadFile?Path=${src}` || "" : urlLogo}   width={width? width: "100%"} height={height? height: "100%"} />
      //  <img src={ `${environment.apiUri}/files/DownloadFile?Path=${src}` || ""} height={height? height: "100%"} width={width? width: "100%"} style={{maxHeight:"210px", objectFit: "cover"}}  alt="" />
    );
  }
    
  export { ImageSrc };
