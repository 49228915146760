export class SuscriptionHistorical { 
    suscriptionHistoricalId?: string;
    customerId?: string;
    customer?: string;
    phone?: string;
    photoUri?:string;
    startingDate?: Date;
    endingDate?: Date;
    daysLeft?: number;
    
    constructor(datos?: SuscriptionHistorical) {
        if (datos != null) {
        this.suscriptionHistoricalId = datos.suscriptionHistoricalId;
        this.customerId = datos.customerId;
        this.customer = datos.customer;
        this.phone = datos.phone;
        this.photoUri = datos.photoUri;
        this.startingDate = datos.startingDate;
        this.endingDate = datos.endingDate;
        this.daysLeft = datos.daysLeft;
        return;
        }

    }
}