import { Dispatch } from 'redux';
import { alertActions } from '../../auth/actions/alert.actions';
import { DataDialogProps } from '../../models/data.dialog.props';
import { filepickerConstants } from './filepicker.constants';
import { filepickerService } from './filepicker.service';
import { FileUpload } from './models/file_upload';


export const filepickerActions = {
    uploadFile,
    cleanFile,
    openModalCamera
};

function cleanFile(){
    return (dispatch: Dispatch) => {
        dispatch(  { type: filepickerConstants.CLEAN_FILE});
    };
}

function openModalCamera( openCamera:DataDialogProps<string>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: filepickerConstants.OPEN_MODAL_CAMERA, openCamera });
    };
}
function uploadFile(file: File) {
     return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        filepickerService.uploadFile(file)
            .then(
                (types )=> {
                dispatch(success(types.data))
                dispatch(alertActions.success("Imagen subida"));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: filepickerConstants.UPLOAD_FILE_REQUEST } }
    function success(file:FileUpload) { return { type: filepickerConstants.UPLOAD_FILE_SUCCESS, file } }
    function failure(error:any) { return { type: filepickerConstants.UPLOAD_FILE_FAILURE, error } }
}
