import { Item } from "../../../pages/items/models/item";
import { searchBarConstants } from "../constants/search_bar.constants";
import { SearchBarState } from "../models/search_bar.state";

const initialState: SearchBarState = { 
    loading: false, 
    error: "",
    showResultPreview:false,
    items:  Array<Item>(), 
    openSelectItem: {
        open: false,
        select: [],
        type: 0,
        loading: false,
    }
};

export function searchBar(state:SearchBarState = initialState, action:any) {
    switch (action.type) {

        case searchBarConstants.OPEN_MODAL_SEARCH_ITEM:
            return {
                ...state,
                openSelectItem: action.openSelectItem
            };

        
        case searchBarConstants.CHANGE_PREVIEW:
            return {
                ...state,
                showResultPreview: action.showResultPreview
            };

        case searchBarConstants.SEARCH_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                showResultPreview: false,
                items: action.items
            };
        case searchBarConstants.SEARCH_ITEM_SUCCESS:
            return {
                ...state,
                loading: false, 
                showResultPreview: true,
                items: action.items
                // openSelectItem: {
                //     open: state.openSelectItem.open,
                //     select: action.items,
                //     type: state.openSelectItem.type,
                //     loading: false
                // }
            };
        case searchBarConstants.SEARCH_ITEM_FAILURE:
            return {
                ...state,
                showResultPreview: false,
                loading: false,
                // openSelectItem: {
                //     open: state.openSelectItem.open,
                //     select: state.openSelectItem.select,
                //     type: state.openSelectItem.type,
                //     loading: false
                // }
            };
        default:
            return state
    }
}