import { Dispatch } from "@reduxjs/toolkit";

import { DataDialogProps } from "../../../models/data.dialog.props";
import { printPdfConstants } from "../constants/print-pdf.constants";
export const printPdfActions = {
    openPrintPdf
};
function openPrintPdf(openPrintPdf:DataDialogProps<string>) {
    return { type: printPdfConstants.OPEN_MODAL_PRINT_PDF, openPrintPdf 
    };
}
// function openPrintPdf(openPrintPdf:DataDialogProps<string>) {
//     return (dispatch: Dispatch) => {
//         dispatch(  { type: printPdfConstants.OPEN_MODAL_PRINT_PDF, openPrintPdf });
//     };
// }