import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../auth/actions/alert.actions";
import { VatItem } from "./models/vat-item";
import { vatItemConstants } from "./vat-item.constants";
import { vatItemService } from "./vat-item.service";


export const vatItemActions = {
    get
};

function get() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        vatItemService.get()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: vatItemConstants.GET_VAT_ITEM_REQUEST } }
    function success(vatItems:VatItem[]) { return { type: vatItemConstants.GET_VAT_ITEM_SUCCESS, vatItems } }
    function failure(error:any) { return { type: vatItemConstants.GET_VAT_ITEM_FAILURE, error } }
}