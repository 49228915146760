import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../components/SkeletonComponet';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { authActions } from '../../../auth/actions/auth.actions';


  function DialogRestartPasswordExternal() {
    const dispatch = useDispatch();    
    const openRestartExternal  = useSelector((state: any) => state.authentication.openRestartExternal as DataDialogProps<string>);
    const [userName, setUserName] = React.useState<string>();

    const handleClose = () => {
      dispatch(authActions.openModalRestartExternal({
        open : false,
        select : "",
        type : 0,
        loading: false
      }));
      setUserName("");
    };
  

    function sendLinkEmail(){
        if(userName){
            dispatch(authActions.sendLink(userName));
        }
    }     

    function handleChange(e:any) {
        const {value } = e.target;
        setUserName(value);
    }

    const footer = (
        <div>
            <Button disabled={openRestartExternal?.loading} onClick={sendLinkEmail} label="Restablecer contraseña" icon="pi pi-id-card"/>
        </div>
    );

    return (
        <Dialog header="Restablecer contraseña" footer={footer} visible={openRestartExternal?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '30vw'}}>
            <div>
                {openRestartExternal?.loading ? <SkeletonComponent/>:
                <div className="p-fluid grid mt-3">
                  
                        <div className="field col-12 md:col-12">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                                <InputText id="userName" name="userName" value={userName} onChange={handleChange} />
                                <label htmlFor="userName">Usuario</label>
                            </span>
                        </div>
                    
                </div>
            }
            </div>
        </Dialog>
    );
  }
    
  export { DialogRestartPasswordExternal };