import { Store } from "../../users/models/store";
import { roleConstants } from "../constants/role.constants";
import { Role } from "../models/role";
import { RoleState } from "../models/role.state";


const initialState: RoleState = { 
    loading: true, 
    error: "",
    roles:  Array<Role>(), 
    stores:  Array<Store>(), 
    openRole: {
        open: false,
        select: new Role(),
        type: 0,
        loading: false,
    }
} ;

export function roles(state:RoleState = initialState, action:any) {
    switch (action.type) {

        case roleConstants.OPEN_MODAL_ROLE:
            return {
                ...state,
                openRole: action.openRole
            };
        case roleConstants.GET_ROLES_REQUEST:
            return {
                ...state,
                loading: true,
                roles:[]
            };
        case roleConstants.GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.roles
            };
        case roleConstants.GET_ROLES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case roleConstants.GET_STORES_REQUEST:
            return {
                ...state,
                loading: true,
                stores:[]
            };
        case roleConstants.GET_STORES_SUCCESS:
            return {
                ...state,
                loading: false,
                stores: action.stores
            };
        case roleConstants.GET_STORES_FAILURE:
            return {
                ...state,
                loading: false,
            };

         case roleConstants.GET_ROLE_DETAIL_REQUEST:
            return {
                ...state,
                openRole: {
                    open: true,
                    select: state.openRole.select,
                    type: state.openRole.type,
                    loading: true,
                }
            };
        case roleConstants.GET_ROLE_DETAIL_SUCCESS:
            return {
                ...state,
                openRole: {
                    open: true,
                    select: action.role,
                    type: state.openRole.type,
                    loading: false,
                }
            };
        case roleConstants.GET_ROLE_DETAIL_FAILURE:
            return {
                ...state,
                openRole: {
                    open: true,
                    select: action.role,
                    type: state.openRole.type,
                    loading: false,
                }
            };

        case roleConstants.ADD_ROLE_REQUEST:
            return {
                ...state,
                openRole: {
                    open: true,
                    select: state.openRole.select,
                    type: 0,
                    loading: true,
                }
            };
        case roleConstants.ADD_ROLE_SUCCESS:
                //TODO mostrar mensaje de agregado
                console.log("Agregado");
            return {
                ...state,
                roles: action.roles,
                openRole: {
                    open: false,
                    select: new Role(),
                    type: 0,
                    loading: false,
                }
            };
        case roleConstants.ADD_ROLE_FAILURE:
                //TODO mostrar mensaje error
                console.log("ERROR");
            return {
                ...state,
                openRole: {
                    open: true,
                    select: action.role,
                    type: 0,
                    loading: false,
                }
            };

        case roleConstants.EDIT_ROLE_REQUEST:
            return {
                ...state,
                openRole: {
                    open: true,
                    select: action.role,
                    type: 1,
                    loading: true,
                }
            };
        case roleConstants.EDIT_ROLE_SUCCESS:
                //TODO mostrar mensaje de agregado
                console.log("Editado");
            return {
                ...state,
                roles: action.roles,
                openRole: {
                    open: false,
                    select: new Role(),
                    type: 1,
                    loading: false,
                }
            };
        case roleConstants.EDIT_ROLE_FAILURE:
                //TODO mostrar mensaje error
                console.log("ERROR");
            return {
                ...state,
                openRole: {
                    open: true,
                    select: action.role,
                    type: 1,
                    loading: false,
                }
            };
        default:
            return state
    }
}