import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { reportConstants } from "../constants/report.constants";
import { CustomerLoss } from "../models/customer_loss";
import { DiarySales } from "../models/diary_sales";
import { MonthlySales } from "../models/monthly_sales";
import { SalesByItem } from "../models/sales_by_item";
import { reportService } from "../report.service";


export const reportActions = {
    searchDiarySales,
    searchMonthlySales,
    searchSalesByItem,
    searchCustomersLoss,
    cleanReports    
};

function cleanReports() {
    return { type: reportConstants.CLEAN_REPORTS };
}

function searchDiarySales(fromDate:string, toDate: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        reportService.searchDiarySales(fromDate, toDate)
            .then((result)=> { 
                    dispatch(success(result.data));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: reportConstants.DIARY_SALES_REQUEST, } }
    function success(diarySales:DiarySales[]) { return { type: reportConstants.DIARY_SALES_SUCCESS, diarySales } }
    function failure(error:any) { return { type: reportConstants.DIARY_SALES_FAILURE, error } }
}

function searchMonthlySales(month:number, year: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        reportService.searchMonthlySales(month, year)
            .then((result)=> { 
                    dispatch(success(result.data));
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: reportConstants.MONTHLY_SALES_REQUEST } }
    function success(monthlySales:MonthlySales[]) { return { type: reportConstants.MONTHLY_SALES_SUCCESS, monthlySales } }
    function failure(error:any) { return { type: reportConstants.MONTHLY_SALES_FAILURE, error } }
}

function searchSalesByItem(month: number, year: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        reportService.searchSalesByItem(month, year)
            .then((result)=> { 
                    dispatch(success(result.data));
                },
                         

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: reportConstants.SALES_BY_ITEM_REQUEST } }
    function success(salesByItems:SalesByItem[]) { return { type: reportConstants.SALES_BY_ITEM_SUCCESS, salesByItems } }
    function failure(error:any) { return { type: reportConstants.SALES_BY_ITEM_FAILURE,  error } }
}



function searchCustomersLoss(months: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        reportService.searchCustomersLoss(months)
            .then((result)=> { 
                    dispatch(success(result.data));
                },
                         

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request() { return { type: reportConstants.CUSTOMERS_LOSS_REQUEST } }
    function success(customersLoss:CustomerLoss[]) { return { type: reportConstants.CUSTOMERS_LOSS_SUCCESS, customersLoss } }
    function failure(error:any) { return { type: reportConstants.CUSTOMERS_LOSS_FAILURE, error } }
}
