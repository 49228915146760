import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../auth/actions/alert.actions";
import { durationConstants } from "./duration.constants";
import { durationService } from "./duration.service";
import { Duration } from "./models/duration";

export const durationActions = {
    get
};

function get() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        durationService.get()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: durationConstants.GET_DURATION_REQUEST } }
    function success(durations:Duration[]) { return { type: durationConstants.GET_DURATION_SUCCESS, durations } }
    function failure(error:any) { return { type: durationConstants.GET_DURATION_FAILURE, error } }
}