import { Permission } from "../../../../auth/models/permission";
import { TreeNodeDto } from "../../../../models/tree.node.dto";
import { permissionConstants } from "../constants/permission.constants";
import { PermissionsState } from "../models/permission.state";

const initialState: PermissionsState = { 
    loading: true, 
    error: "",
    permissions:  Array<TreeNodeDto>(), 
    openPermission: {
        open: false,
        select: new Permission(),
        type: 0,
        loading: false,
    }
} ;

export function permissions(state:PermissionsState = initialState, action:any) {
    switch (action.type) {

        case permissionConstants.OPEN_MODAL_PERMISSION:
            return {
                ...state,
                openPermission: action.openPermission
            };
        case permissionConstants.GET_PERMISSIONS_REQUEST:
            return {
                ...state,
                loading: true,
                permissions:[]
            };
        case permissionConstants.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: action.permissions
            };
        case permissionConstants.GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case permissionConstants.ADD_PERMISSION_REQUEST:
            return {
                ...state,
                openPermission: {
                    open: true,
                    select: state.openPermission.select,
                    type: 0,
                    loading: true,
                }
            };
        case permissionConstants.ADD_PERMISSION_SUCCESS:
                //TODO mostrar mensaje de agregado
                console.log("Agregado");
            return {
                ...state,
                permissions: action.permissions,
                openPermission: {
                    open: false,
                    select: new Permission(),
                    type: 0,
                    loading: false,
                }
            };
        case permissionConstants.ADD_PERMISSION_FAILURE:
                //TODO mostrar mensaje error
                console.log("ERROR");
            return {
                ...state,
                openPermission: {
                    open: true,
                    select: action.permission,
                    type: 0,
                    loading: false,
                }
            };

        case permissionConstants.EDIT_PERMISSION_REQUEST:
            return {
                ...state,
                openPermission: {
                    open: true,
                    select: action.permission,
                    type: 1,
                    loading: true,
                }
            };
        case permissionConstants.EDIT_PERMISSION_SUCCESS:
                //TODO mostrar mensaje de agregado
                console.log("Editado");
            return {
                ...state,
                permissions: action.permissions,
                openPermission: {
                    open: false,
                    select: new Permission(),
                    type: 1,
                    loading: false,
                }
            };
        case permissionConstants.EDIT_PERMISSION_FAILURE:
                //TODO mostrar mensaje error
                console.log("ERROR");
            return {
                ...state,
                openPermission: {
                    open: true,
                    select: action.permission,
                    type: 1,
                    loading: false,
                }
            };
        default:
            return state
    }
}