import axios from 'axios';
import environment from '../../../environment';
import { Role } from './models/role';

  export const roleService = {
      getRoles,
      getStores,
      getRoleWithDetail,
      add,
      edit
  };

  function getRoles() {
    return axios.get(`${environment.apiUri}/role`)
  };

  function getStores() {
    return axios.get(`${environment.apiUri}/store`)
  };
  
  function getRoleWithDetail(roleId: number) {
    return axios.get(`${environment.apiUri}/role/RoleWithDetail/${roleId}`)
  };
  
  function add(role: Role) {
    return axios.post(`${environment.apiUri}/role`, role)
  };

  function edit(role: Role) {
    return axios.put(`${environment.apiUri}/role`, role)
  };