export const categoryConstants = {
    SEARCH_CATEGORY_REQUEST: 'SEARCH_CATEGORY_REQUEST',
    SEARCH_CATEGORY_SUCCESS: 'SEARCH_CATEGORY_SUCCESS',
    SEARCH_CATEGORY_FAILURE: 'SEARCH_CATEGORY_FAILURE',

    SEARCH_PRODUCT_GROUP_REQUEST: 'SEARCH_PRODUCT_GROUP_REQUEST',
    SEARCH_PRODUCT_GROUP_SUCCESS: 'SEARCH_PRODUCT_GROUP_SUCCESS',
    SEARCH_PRODUCT_GROUP_FAILURE: 'SEARCH_PRODUCT_GROUP_FAILURE',

    OPEN_MODAL_SEARCH_CATEGORY: 'OPEN_MODAL_SEARCH_CATEGORY',
    OPEN_MODAL_SEARCH_PRODUCT_GROUP: 'OPEN_MODAL_SEARCH_PRODUCT_GROUP',
};