import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { DiarySales } from './models/diary_sales';
import { Calendar } from 'primereact/calendar';
import { reportActions } from './actions/report.actions';
import TableRenderers from 'react-pivottable/TableRenderers';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import 'react-pivottable/pivottable.css';

function DiarySalesPage() {
    const diarySales: DiarySales[]= useSelector((state:any) => state.reports.diarySales);
    const loading: boolean= useSelector((state:any) => state.reports.loading);
    const [fromDate, setFromDate] = React.useState<Date>();
    const [toDate, setToDate] = React.useState<Date>();
    const [submit, setSubmit] = React.useState<boolean>(false);
    const [pivotState, setPivotState] = React.useState();
    const dispatch = useDispatch();
    const [currentInputs, setCurrenInputs] = React.useState<DiarySales[]>(diarySales);

    const handSearch = () => {
        setSubmit(true)
        dispatch(reportActions.searchDiarySales(fromDate?.toISOString() ?? "", toDate?.toISOString()??""));
    }

    useEffect(() => {
        setCurrenInputs(diarySales);
    },[diarySales]);

   function handleFromDate(e:any) {
        const { value } = e.target;
        setFromDate(value);
   }

   function handleToDate(e:any) {
        const {value } = e.target;
        setToDate(value);
   }
 
    return (
        <div className="card">
            <div className="p-fluid grid">
                <div className="field col-2">  
                    <label htmlFor="fromDate" className="block">Desde</label>
                    <Calendar id="fromDate" value={fromDate!= undefined ?  new Date(fromDate??"") :undefined}  onChange={handleFromDate}></Calendar>
                    {(submit&& !fromDate) ?<small id="name-help" className="block p-error">Seleccione la fecha Inicial</small>: <></> }           
                </div>
                <div className="field col-2">
                    <label htmlFor="toDate" className="block">Hasta</label>
                    <Calendar id="toDate" value={toDate!= undefined ?  new Date(toDate??"") :undefined} onChange={handleToDate}></Calendar>
                    {(submit&& !toDate) ?<small id="name-help" className="block p-error">Seleccione la fecha final</small>: <></> }
                </div>

                <div className=" col-2 mt-5">
                    <Button label="Buscar" onClick={handSearch}  icon="pi pi-search" iconPos="left"  />
                </div>
                 {loading ? <SkeletonComponent/> :
                <div className="col-12">
                    <PivotTableUI  
                        rows= {[ 'Fecha',  'Cliente']}//, 'vat', 'subTotal', 'total'    
                        data={(currentInputs??[]).map((item)=> { 
                            return {
                                Fecha: item.createdDateString,
                                Producto: item.item,
                                Cliente: item.customer,
                                Dia: item.day,
                                Impuesto: item.vat,
                                SubTotal: item.subTotal,
                                Total: item.total
                            }}) }
                        aggregatorName={"Sum"}
                         vals={[ 'Total']}
                        hiddenFromDragDrop={['itemId']}
                        onChange={(s:any) => setPivotState(s)}
                        renderers={Object.assign({}, TableRenderers)}
                        localeStrings={"es"}
                        {...pivotState as any}
                    />
                </div>
                }
            </div>
        </div>
    );
}

export { DiarySalesPage };

