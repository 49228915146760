import { Link} from 'react-router-dom';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import Logo from'../assets/images/logo.png';
import { Menu } from 'primereact/menu';
import { authActions } from '../auth/actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { MegaMenu } from 'primereact/megamenu';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SearchBar } from './search_bar/SearchBar';
import { User } from '../pages/settings/users/models/user';

interface propsTopBar{
    layoutColorMode: string,
    onToggleMenuClick: (value: any) => void,
    mobileTopbarMenuActive: boolean,
    onMobileTopbarMenuClick: (value: any) => void,
    onMobileSubTopbarMenuClick: (value: any) => void,
}


export const AppTopbar = (props: propsTopBar) => {
const dispatch = useDispatch();
const user: User= useSelector((state:any) => state.authentication.user);
useEffect(() => {
   document.title = user.company??"The Gym"
 
}, []);


let items = [
    {
        label: 'Ver Perfil', 
        icon: 'pi pi-fw pi-user',
        command: () => {
            console.log("Perfil");
        }
    },
    {
        label: 'Cerrar Sesión',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
            dispatch(authActions.logout()); 
        }
    }
];
const menu = useRef<Menu>(null);
    return (

        <div className="layout-topbar">
            <Link to="/home" className="layout-topbar-logo">
                {/* <img src={props.layoutColorMode === 'light' ? Logo : Logo} alt="Logo Diunsa"/> */}
                <img src={user.logoUri} alt="Logo"/>
                <span> {user.company}</span>
            </Link>



            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

          
            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>
            
             {/* <SearchBar/>
            <div className="left-menu">
                <div className="menu-item">
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <div className="profile" onClick={(event) => menu?.current?.toggle(event)}>
                        <span className="profile-text">Hola, {user.userName}</span>
                        <i className="pi pi-caret-down mt-2"></i>
                    </div>  
                </div>

            </div> */}

             <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li>
                    <button className="p-link layout-topbar-button"  onClick={props.onMobileSubTopbarMenuClick}>
                        {/* <i className="pi pi-reply"/> */}
                        Hola, {user.userName?.toLocaleLowerCase()}
                    </button>                    
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={()=>{dispatch(authActions.logout()); }}>
                        <i className="pi pi-unlock"/>
                        <span>Salir</span>
                    </button>
                </li> 
            </ul>

        </div>
    );
}
