import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Logo from'../../assets/images/logo.png';
import { Button } from 'primereact/button';
import { authActions } from '../../auth/actions/auth.actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import { SkeletonComponent } from '../../components/SkeletonComponet';

function RestartPasswordPage() {
    const [inputs, setInputs] = useState({ password1: '', password2: ''});
    const { password1, password2 } = inputs;
    const loading = useSelector((state:any) => state.authentication.loading);
    const loggedIn = useSelector((state:any) => state.authentication.loggedIn);
    const tempUser = useSelector((state:any) => state.authentication.tempUser);
    const validId = useSelector((state:any) => state.authentication.validId);
    const dispatch = useDispatch();
    const { id } = useParams(); 
    const navigate = useNavigate();

    useEffect(() => {
        if(id == undefined || id== ""){
            navigate("/login/ingresar");
            return;
        }
        dispatch(authActions.validateChangePasswordId(id));
    }, []);

    useEffect(()=>{
        validId==1 &&loggedIn && navigate('/home')
    }, [loggedIn])


     useEffect(() => {
        validId==2  && navigate("/login/ingresar");
    }, [validId]);

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    function handleSubmit(e:any) {
        e.preventDefault();
        if (password1 && password2 && validId ==1 && id) {
            dispatch(authActions.changePassword({ restartId: id, password: password1}, tempUser.token));
        }
    }
    const header = <h6>Ingrese una contraseña</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                <li>Contener una letra minúscula</li>
                <li>Contener una letra mayuscula</li>
                <li>Por lo menos un numero</li>
                <li>La contraseña debe tener al menos de 8 caracteres</li>
            </ul>
        </React.Fragment>
    );

    return (
        <React.Fragment>
        {
            loading
            ? <SkeletonComponent/>
            :
            <form onSubmit={handleSubmit}  autoComplete="false">
                <img width="40%" src={Logo} alt="Logo Diunsa"/>
                <h4>Bienvenido {tempUser?.userName}</h4>
                <p className="font-light">A continuación ingrese su nueva contraseña</p>
                <span className="p-float-label mt-5">
                    <Password id="password1" value={password1 || ""}  name="password1" onChange={handleChange} header={header} footer={footer} 
                    strongLabel="Fuerte" weakLabel='Débil' mediumLabel='Mas o menos' promptLabel='Ingrese una contraseña fuerte' />
                    <label htmlFor="password1">Contraseña</label>
                </span>
                <span className="p-float-label mt-5 mb-4">
                    <Password id="password2" value={password2 || ""}  name="password2" onChange={handleChange} header={header} footer={footer} 
                    strongLabel="Fuerte" weakLabel='Débil' mediumLabel='Mas o menos' promptLabel='Ingrese una contraseña fuerte' />
                    <label htmlFor="password2" >Repertir Contraseña</label>
                </span>
                <Button label="Cambiar Contraseña" type="submit" disabled={loading || (!password1 || !password2) || password1 != password2} loading={loading}/>
            </form>     
        }
        </React.Fragment>
    );
}

export { RestartPasswordPage };

