import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { DataDialogProps } from "../../../models/data.dialog.props";
import { itemConstants } from "../constants/item.constants";
import { itemService } from "../item.service";
import { Item } from "../models/item";

export const itemActions = {
    get,
    edit,   
    add,
    openModalSearchItem,
    searchByDescriptionList,
    openModalItem,
    searchByDescription,
    searchItemById
};

function openModalSearchItem(openSelectItem:DataDialogProps<(Item[])>) {
    return (dispatch: Dispatch) => {
        dispatch(request(openSelectItem));
        itemService.getTops()
            .then((result)=> { 
                    dispatch(success(result.data, openSelectItem));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, openSelectItem));
                dispatch(alertActions.error(message));
            });
    };
    function request(openSelectItem:DataDialogProps<(Item[])>) { return { type: itemConstants.OPEN_MODAL_SEARCH_ITEM_REQUEST, openSelectItem } }
    function success(items:Item[], openSelectItem:DataDialogProps<(Item[])>) { return { type: itemConstants.OPEN_MODAL_SEARCH_ITEM_SUCCESS, items, openSelectItem } }
    function failure(error:any, openSelectItem:DataDialogProps<(Item[])>) { return { type: itemConstants.OPEN_MODAL_SEARCH_ITEM_FAILURE, error, openSelectItem } }
}

function openModalItem(openItem:DataDialogProps<(Item)>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: itemConstants.OPEN_MODAL_ITEM, openItem });
    };
}
 


function get() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        itemService.get()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: itemConstants.GET_ITEM_REQUEST } }
    function success(items:Item[]) { return { type: itemConstants.GET_ITEM_SUCCESS, items } }
    function failure(error:any) { return { type: itemConstants.GET_ITEM_FAILURE, error } }
}


function edit(item: Item) {
    return (dispatch: Dispatch) => {
        dispatch(request(item));
        itemService.edit(item)
            .then((result)=> { 
                    dispatch(success( item, result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(item,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(item: Item ) { return { type: itemConstants.EDIT_ITEM_REQUEST , item} }
    function success(item: Item , items:Item[]) { return { type: itemConstants.EDIT_ITEM_SUCCESS,item, items } }
    function failure(item: Item ,error:any) { return { type: itemConstants.EDIT_ITEM_FAILURE,item, error } }
}
function add(item: Item) {
    return (dispatch: Dispatch) => {
        dispatch(request(item));
        itemService.add(item)
            .then((result)=> { 
                    dispatch(success( item, result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(item,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(item: Item ) { return { type: itemConstants.ADD_ITEM_REQUEST , item} }
    function success(item: Item , items:Item[]) { return { type: itemConstants.ADD_ITEM_SUCCESS,item, items } }
    function failure(item: Item ,error:any) { return { type: itemConstants.ADD_ITEM_FAILURE,item, error } }
}

function searchByDescription(description: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        itemService.searchDescription(description)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_REQUEST } }
    function success(items:Item[]) { return { type: itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_SUCCESS, items } }
    function failure(error:any) { return { type: itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_FAILURE, error } }
}
function searchByDescriptionList(description: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        itemService.searchDescription(description)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_REQUEST } }
    function success(items:Item[]) { return { type: itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_SUCCESS, items } }
    function failure(error:any) { return { type: itemConstants.SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_FAILURE, error } }
}





function searchItemById(itemCode: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        itemService.searchItemById(itemCode)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: itemConstants.SEARCH_ITEM_BY_ID_REQUEST } }
    function success(item:Item) { return { type: itemConstants.SEARCH_ITEM_BY_ID_SUCCESS, item } }
    function failure(error:any) { return { type: itemConstants.SEARCH_ITEM_BY_ID_FAILURE, error } }
}

