import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../../auth/actions/alert.actions";
import { deviceTaskConstants } from "../constants/device-task.constants";
import { deviceTaskService } from "../device-service.service";
import { DeviceTask } from "../models/device-task";

export const deviceTaskActions = {
    openDoor,
    get,
    getUsersInClockManual,
    syncAllFingers,
    removeAllFingers

};

// function openModalUser(openUser:DataDialogProps<User>) {
//     return (dispatch: Dispatch) => {
//         dispatch(  { type: userConstants.OPEN_MODAL_USER, openUser });
//     };
// }

function get() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        deviceTaskService.get()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: deviceTaskConstants.GET_DEVICE_TASK_REQUEST } }
    function success(tasks:DeviceTask[]) { return { type: deviceTaskConstants.GET_DEVICE_TASK_SUCCESS, tasks } }
    function failure(error:any) { return { type: deviceTaskConstants.GET_DEVICE_TASK_FAILURE, error } }
}
function openDoor() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        deviceTaskService.openDoor()
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success("Se abrira la puerta en un instante"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: deviceTaskConstants.OPEN_DOOR_REQUEST } }
    function success(tasks: DeviceTask) { return { type: deviceTaskConstants.OPEN_DOOR_SUCCESS ,tasks} }
    function failure(error:any) { return { type: deviceTaskConstants.OPEN_DOOR_FAILURE, error } }
}
function getUsersInClockManual() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        deviceTaskService.getUsersInClockManual()
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success("Se sincronizaran los usuarios en un momento"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: deviceTaskConstants.GET_USERS_IN_CLOCK_REQUEST } }
    function success(tasks: DeviceTask) { return { type: deviceTaskConstants.GET_USERS_IN_CLOCK_SUCCESS ,tasks} }
    function failure(error:any) { return { type: deviceTaskConstants.GET_USERS_IN_CLOCK_FAILURE, error } }
}
function syncAllFingers() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        deviceTaskService.syncAllFingers()
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success("Se sincronizaran todos los clientes"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: deviceTaskConstants.GET_DEVICE_TASK_REQUEST } }
    function success(tasks: DeviceTask) { return { type: deviceTaskConstants.GET_DEVICE_TASK_SUCCESS ,tasks} }
    function failure(error:any) { return { type: deviceTaskConstants.GET_DEVICE_TASK_FAILURE, error } }
}
function removeAllFingers() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        deviceTaskService.removeAllFingers()
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success("Se eliminaran todos los clientes"));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: deviceTaskConstants.GET_DEVICE_TASK_REQUEST } }
    function success(tasks: DeviceTask) { return { type: deviceTaskConstants.GET_DEVICE_TASK_SUCCESS ,tasks} }
    function failure(error:any) { return { type: deviceTaskConstants.GET_DEVICE_TASK_FAILURE, error } }
}
