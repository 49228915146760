import { DataDialogProps } from "../../models/data.dialog.props";
import { filepickerConstants } from "./filepicker.constants";
import { FileUpload } from "./models/file_upload";

export interface FilePickerState{
    loading: boolean, 
    error: string, 
    file: FileUpload,
    openCamera: DataDialogProps<string>
}

const initialState: FilePickerState = { 
    loading: false, 
    error: "", 
    file: {},
    openCamera:{
        open:false,
        select: "",
        type: 0,
        loading: false
    },
};

export function filePicker(state = initialState, action:any) :FilePickerState {
    switch (action.type) {

        case filepickerConstants.CLEAN_FILE:
            return {
                ...state,
                file: {}
            }
        case filepickerConstants.OPEN_MODAL_CAMERA:
        return {
            ...state,
            openCamera: action.openCamera,
        };

        case filepickerConstants.UPLOAD_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case filepickerConstants.UPLOAD_FILE_SUCCESS:
              
            return {
                ...state,
                loading: false,
                file: action.file
                
            };
        case filepickerConstants.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
    
        default:
            return state
    }
}
