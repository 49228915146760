import axios from 'axios';
import { Permission } from '../../../auth/models/permission';
import environment from '../../../environment';

export const permissionService = {
    get,
    add,
    edit
};

 function get() {
    return axios.get(`${environment.apiUri}/permission`)
  };
  
  function add(permission: Permission) {
    return axios.post(`${environment.apiUri}/permission`, permission)
  };

  function edit(permission: Permission) {
    return axios.put(`${environment.apiUri}/permission`, permission)
  };
  
  
