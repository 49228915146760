import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';

import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { TypeDialog } from '../../../helpers/type_dialog';
import { printPdfActions } from '../actions/item.actions';
import printJS from 'print-js'

  function DialogPrintPdf(props: DialogProps<string>) {
    const dispatch = useDispatch();    
    const openPrintPdf  = useSelector((state: any) => state.printPdf.openPrintPdf as DataDialogProps<string>);
    useEffect(() => {
         //setInputs(openPrintPdf?.select)
    }, [openPrintPdf]);


    const getLink = ()=>{
      return `data:application/pdf;base64,${openPrintPdf.select}`;
    }
    const handleClose = () => {
      dispatch(printPdfActions.openPrintPdf({
        open : false,
        select :"",
        type : TypeDialog.add,
        loading: false
      }));
    };

    const onDownload = () =>{
      const linkSource = getLink();
      const downloadLink = document.createElement("a");
      const fileName = "reporte.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    const onPrint = () => {
      printJS({printable: openPrintPdf.select, type: 'pdf', base64: true,   modalMessage:"Cargando"})
    };

    
    const footer = (
        <div>
            <Button loading={openPrintPdf.loading} disabled={openPrintPdf.loading} onClick={onDownload} label="Descargar"  icon="pi pi-download"/>
            <Button loading={openPrintPdf.loading} disabled={openPrintPdf.loading} onClick={onPrint} label="Imprimir"  icon="pi pi-print"/>
        </div>
    );

    
    return (
        <Dialog header={"Imprimir" } footer={footer} maximizable  visible={openPrintPdf?.open??false} position="top" onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div className='p-fluid grid'>  
              <object style={{marginTop:"5px", width:"100%", minHeight:"500px", maxHeight:"100%"}}  data={getLink()} type="application/pdf" width="100%" height="100%">
                <p>Tu navegador no soporta el plugin PDF, en lugar de visualizarlo puedes
                  <a onClick={()=>{onDownload()}}>click aquí
                para descargar en el PDF.</a></p>
              </object>
            </div>
        </Dialog>
    );
  }
    
  export { DialogPrintPdf };