import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../components/SkeletonComponet';
import { TreeNodeDto } from '../../../models/tree.node.dto';
import { permissionActions } from './actions/permission.actions';
import { Permission } from '../../../auth/models/permission';
import { DialogPermission } from './components/dialog.permission';
import { Tree } from 'primereact/tree';

function ListPermissionPage() {
   const permissions: TreeNodeDto[] = useSelector((state:any) => state.permissions.permissions as TreeNodeDto[]);
   const loading: boolean= useSelector((state:any) => state.permissions.loading);
    
    const dispatch = useDispatch();

    useEffect(() => { 
        dispatch(permissionActions.get()); 
    },[]);

    const addRole = ()=>{    
        let permission = new Permission();
        permission.fatherId =0;
       //TODO  permission.subPermission = [];
        permission.typeId = 0;
        dispatch(permissionActions.openModalPermission({
            open : true,
            select :permission,
            type : 0,
            loading: false
        }));
    }

    const nodeTemplate = (node:any, options:any) => {
            let label = <span>{node.label}</span>;
            const handleEdit = ()=>{
                let permission = new Permission(node);
                permission.name = node.label;
                permission.path = node.data;
                //TODO permission.subPermission = [];            
                dispatch(permissionActions.openModalPermission({
                    open : true,
                    select : permission,
                    type : 1,
                    loading: false
                }));
            }
            const handleAdd = ()=>{
                let permission = new Permission();
                permission.fatherId = node.permissionId;
                //TODO permission.subPermission = [];
                permission.typeId = 0;
                dispatch(permissionActions.openModalPermission({
                    open : true,
                    select : permission,
                    type : 0,
                    loading: false
                }));
            }
            return (
                <span className={options.className}>
                    <span>{label}</span> 
                    <Button onClick={handleEdit} className="p-button-text p-button-sm"  label="Editar" />  
                    <Button onClick={handleAdd} className="p-button-text p-button-sm p-button-secondary"  label="Agregar Hijo" /> 
                </span>
            )
        }

    return (
        <div className="card">
            {loading ? <SkeletonComponent/> :
               <div className="col-12 md:col-12">         
                    <div className="flex justify-content-between align-items-center">
                        <h5 className="m-0">Listado de Permisos</h5>
                        <span className="p-input-icon-left">
                            <Button onClick={addRole} label="Agregar elemento Raíz" icon="pi pi-check" />
                        </span>
                    </div>

                    <Tree value={permissions??[]} nodeTemplate={nodeTemplate}  />
                </div>
            }
            <DialogPermission></DialogPermission>
        </div>
     
    );
}

export { ListPermissionPage };