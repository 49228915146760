export const offerConstants = {
    GET_OFFERS_REQUEST: 'GET_OFFERS_REQUEST',
    GET_OFFERS_SUCCESS: 'GET_OFFERS_SUCCESS',
    GET_OFFERS_FAILURE: 'GET_OFFERS_FAILURE',

    GET_TYPE_ITEM_OFFER_REQUEST: 'GET_TYPE_ITEM_OFFER_REQUEST',
    GET_TYPE_ITEM_OFFER_SUCCESS: 'GET_TYPE_ITEM_OFFER_SUCCESS',
    GET_TYPE_ITEM_OFFER_FAILURE: 'GET_TYPE_ITEM_OFFER_FAILURE',

    GET_OFFER_TYPE_REQUEST: 'GET_OFFER_TYPE_REQUEST',
    GET_OFFER_TYPE_SUCCESS: 'GET_OFFER_TYPE_SUCCESS',
    GET_OFFER_TYPE_FAILURE: 'GET_OFFER_TYPE_FAILURE',

    ADD_OFFER_REQUEST: 'ADD_OFFER_REQUEST',
    ADD_OFFER_SUCCESS: 'ADD_OFFER_SUCCESS',
    ADD_OFFER_FAILURE: 'ADD_OFFER_FAILURE',

    EDIT_OFFER_REQUEST: 'EDIT_OFFER_REQUEST',
    EDIT_OFFER_SUCCESS: 'EDIT_OFFER_SUCCESS',
    EDIT_OFFER_FAILURE: 'EDIT_OFFER_FAILURE',



    OPEN_MODAL_OFFER: 'OPEN_MODAL_OFFER',
};