
import { useSelector } from 'react-redux';
import {
    Navigate ,
    RouteProps,
    useLocation,
} from 'react-router-dom';
import { User } from '../pages/settings/users/models/user';

interface PrivateRouteProps extends RouteProps {
    component: any;
    path: string
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const  user  = useSelector((state: any) => state.authentication.user as User);
    let hasPermission = (user?.permissions??[]).filter((item)=> item.path === props.path);
    const { pathname } = useLocation();
    let canGo = user && hasPermission.length >0 ;
    let elseCanGoHome = user && hasPermission.length === 0 && props.path!== '/home';
    return (
               canGo?
                  props.component 
                : elseCanGoHome
                ?
                  <Navigate 
                        to='/home'
                        // state ={{ from: pathname}}
                        />
                :
                <Navigate
                    to= '/login/ingresar'
                    replace={true}
                    state={{ from: pathname }}
                />);
};

export default PrivateRoute;
