export const customerConstants = {
    GET_TOP_CUSTOMERS_REQUEST: 'GET_TOP_CUSTOMERS_REQUEST',
    GET_TOP_CUSTOMERS_SUCCESS: 'GET_TOP_CUSTOMERS_SUCCESS',
    GET_TOP_CUSTOMERS_FAILURE: 'GET_TOP_CUSTOMERS_FAILURE',


    GET_EMPLOYEES_REQUEST: 'GET_EMPLOYEES_REQUEST',
    GET_EMPLOYEES_SUCCESS: 'GET_EMPLOYEES_SUCCESS',
    GET_EMPLOYEES_FAILURE: 'GET_EMPLOYEES_FAILURE',

    GET_CUSTOMERS_IN_CLOCK_REQUEST: 'GET_CUSTOMERS_IN_CLOCK_REQUEST',
    GET_CUSTOMERS_IN_CLOCK_SUCCESS: 'GET_CUSTOMERS_IN_CLOCK_SUCCESS',
    GET_CUSTOMERS_IN_CLOCK_FAILURE: 'GET_CUSTOMERS_IN_CLOCK_FAILURE',

    SEARCH_CUSTOMER_BY_DESCRIPTION_REQUEST: 'SEARCH_CUSTOMER_BY_DESCRIPTION_REQUEST',
    SEARCH_CUSTOMER_BY_DESCRIPTION_SUCCESS: 'SEARCH_CUSTOMER_BY_DESCRIPTION_SUCCESS',
    SEARCH_CUSTOMER_BY_DESCRIPTION_FAILURE: 'SEARCH_CUSTOMER_BY_DESCRIPTION_FAILURE',


    ADD_CUSTOMER_REQUEST: 'ADD_CUSTOMER_REQUEST',
    ADD_CUSTOMER_SUCCESS: 'ADD_CUSTOMER_SUCCESS',
    ADD_CUSTOMER_FAILURE: 'ADD_CUSTOMER_FAILURE',

    EDIT_CUSTOMER_REQUEST: 'EDIT_CUSTOMER_REQUEST',
    EDIT_CUSTOMER_SUCCESS: 'EDIT_CUSTOMER_SUCCESS',
    EDIT_CUSTOMER_FAILURE: 'EDIT_CUSTOMER_FAILURE',

    UPLOAD_IMAGE_REQUEST: 'UPLOAD_IMAGE_REQUEST',
    UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_FAILURE: 'UPLOAD_IMAGE_FAILURE',

    SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_REQUEST: 'SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_REQUEST',
    SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_SUCCESS: 'SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_SUCCESS',
    SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_FAILURE: 'SEARCH_CUSTOMER_BY_DESCRIPTION_DIALOG_FAILURE',

    OPEN_MODAL_SEARCH_CUSTOMER: 'OPEN_MODAL_SEARCH_CUSTOMER',
    OPEN_MODAL_CUSTOMER: 'OPEN_MODAL_CUSTOMER',
    OPEN_MODAL_CAMERA: 'OPEN_MODAL_CAMERA',


    CLOSE_MODAL_ENROL_FINGER: 'CLOSE_MODAL_ENROL_FINGER',
    UPDATE_SELECT_CUSTOMER: 'UPDATE_SELECT_CUSTOMER',

    OPEN_MODAL_SEARCH_CUSTOMER_REQUEST: 'OPEN_MODAL_SEARCH_CUSTOMER_REQUEST',
    OPEN_MODAL_SEARCH_CUSTOMER_SUCCESS: 'OPEN_MODAL_SEARCH_CUSTOMER_SUCCESS',
    OPEN_MODAL_SEARCH_CUSTOMER_FAILURE: 'OPEN_MODAL_SEARCH_CUSTOMER_FAILURE',


    OPEN_MODAL_ENROL_FINGER_REQUEST: 'OPEN_MODAL_ENROL_FINGER_REQUEST',
    OPEN_MODAL_ENROL_FINGER_SUCCESS: 'OPEN_MODAL_ENROL_FINGER_SUCCESS',
    OPEN_MODAL_ENROL_FINGER_FAILURE: 'OPEN_MODAL_ENROL_FINGER_FAILURE',

    OPEN_MODAL_NEW_ENROL_FINGER: 'OPEN_MODAL_NEW_ENROL_FINGER',
    CLOSE_MODAL_NEW_ENROL_FINGER: 'CLOSE_MODAL_NEW_ENROL_FINGER',

    SYNC_FINGER_REQUEST: 'SYNC_FINGER_REQUEST',
    SYNC_FINGER_SUCCESS: 'SYNC_FINGER_SUCCESS',
    SYNC_FINGER_FAILURE: 'SYNC_FINGER_FAILURE',

    REMOVE_FINGER_REQUEST: 'REMOVE_FINGER_REQUEST',
    REMOVE_FINGER_SUCCESS: 'REMOVE_FINGER_SUCCESS',
    REMOVE_FINGER_FAILURE: 'REMOVE_FINGER_FAILURE',

    REMOVE_FINGER_PERMANENT_REQUEST: 'REMOVE_FINGER_PERMANENT_REQUEST',
    REMOVE_FINGER_PERMANENT_SUCCESS: 'REMOVE_FINGER_PERMANENT_SUCCESS',
    REMOVE_FINGER_PERMANENT_FAILURE: 'REMOVE_FINGER_PERMANENT_FAILURE',

    MARK_AS_EMPLOYEE_REQUEST: 'MARK_AS_EMPLOYEE_REQUEST',
    MARK_AS_EMPLOYEE_SUCCESS: 'MARK_AS_EMPLOYEE_SUCCESS',
    MARK_AS_EMPLOYEE_FAILURE: 'MARK_AS_EMPLOYEE_FAILURE',

    REMOVE_MARK_AS_EMPLOYEE_REQUEST: 'REMOVE_MARK_AS_EMPLOYEE_REQUEST',
    REMOVE_MARK_AS_EMPLOYEE_SUCCESS: 'REMOVE_MARK_AS_EMPLOYEE_SUCCESS',
    REMOVE_MARK_AS_EMPLOYEE_FAILURE: 'REMOVE_MARK_AS_EMPLOYEE_FAILURE',

    GET_DEVICES_REQUEST: 'GET_DEVICES_REQUEST',
    GET_DEVICES_SUCCESS: 'GET_DEVICES_SUCCESS',
    GET_DEVICES_FAILURE: 'GET_DEVICES_FAILURE',

    SAVE_FINGERPRINT_REQUEST: 'SAVE_FINGERPRINT_REQUEST',
    SAVE_FINGERPRINT_SUCCESS: 'SAVE_FINGERPRINT_SUCCESS',
    SAVE_FINGERPRINT_FAILURE: 'SAVE_FINGERPRINT_FAILURE',

};