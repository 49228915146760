export const filepickerConstants = {

    CLEAN_FILE: 'CLEAN_FILE',
    OPEN_MODAL_CAMERA: 'OPEN_MODAL_CAMERA',


    UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
    UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',

};
