import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../../auth/actions/alert.actions";
import { Permission } from "../../../../auth/models/permission";
import { DataDialogProps } from "../../../../models/data.dialog.props";
import { TreeNodeDto } from "../../../../models/tree.node.dto";
import { permissionConstants } from "../constants/permission.constants";
import { permissionService } from "../permission.service";

export const permissionActions = {
    openModalPermission,
    get,
    add,
    edit
};

function openModalPermission(openPermission:DataDialogProps<Permission>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: permissionConstants.OPEN_MODAL_PERMISSION, openPermission });
    };
}
 
function get() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        permissionService.get()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: permissionConstants.GET_PERMISSIONS_REQUEST } }
    function success(permissions:TreeNodeDto[]) { return { type: permissionConstants.GET_PERMISSIONS_SUCCESS, permissions } }
    function failure(error:any) { return { type: permissionConstants.GET_PERMISSIONS_FAILURE, error } }
}

function add(permission: Permission) {
    return (dispatch: Dispatch) => {
        dispatch(request(permission));
        permissionService.add(permission)
            .then((result)=> { 
                    dispatch(success(result.data, permission));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, permission));
                dispatch(alertActions.error(message));
            });
    };

    function request(permission: Permission) { return { type: permissionConstants.ADD_PERMISSION_REQUEST, permission} }
    function success(permissions:TreeNodeDto[], permission: Permission) { return { type: permissionConstants.ADD_PERMISSION_SUCCESS, permissions, permission } }
    function failure(error:any, permission: Permission) { return { type: permissionConstants.ADD_PERMISSION_FAILURE, error , permission} }
}

function edit(permission: Permission) {
    return (dispatch: Dispatch) => {
        dispatch(request(permission));
        permissionService.edit(permission)
            .then((result)=> { 
                    dispatch(success(result.data, permission));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message, permission));
                dispatch(alertActions.error(message));
            });
    };

    function request(permission: Permission) { return { type: permissionConstants.EDIT_PERMISSION_REQUEST, permission } }
    function success(permissions:TreeNodeDto[], permission: Permission) { return { type: permissionConstants.EDIT_PERMISSION_SUCCESS, permissions, permission } }
    function failure(error:any, permission: Permission) { return { type: permissionConstants.EDIT_PERMISSION_FAILURE, error, permission } }
}
