import { OfferDetail } from "./offer_detail";

export class Offer {
    offerId?: number;
    offerTypeId?: number;
    description?: string;
    userId?: number;
    createdDate?: Date;
    validFromDate?: Date;
    validToDate?: Date;
    priority?: number;
    typeItemOfferId?: number;
    active?: boolean;
    detail?: OfferDetail[];
    
    constructor(datos?: Offer) {
        if (datos != null) {
        this.offerId = datos.offerId;
        this.offerTypeId = datos.offerTypeId;
        this.description = datos.description;
        this.userId = datos.userId;
        this.createdDate = datos.createdDate;
        this.validFromDate = datos.validFromDate;
        this.validToDate = datos.validToDate;
        this.priority = datos.priority;
        this.typeItemOfferId = datos.typeItemOfferId;
        this.active = datos.active;
        this.detail = datos.detail;
        return;
        }
        this.detail= [];
    }
}