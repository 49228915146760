import axios from 'axios';
import { User } from './models/user';
import environment from '../../../environment';


export const userService = {
    getUsers,
    generateLink,
    sendLinkEmail,
    sendLinkSms,
    add,
    edit
};

 function getUsers() {
    return axios.get(`${environment.apiUri}/user`)
  };
  
 function add(user: User) {
    return axios.post(`${environment.apiUri}/user`, user)
  };
  
 function edit(user: User) {
    return axios.put(`${environment.apiUri}/user`, user)
  };
  
 function generateLink(userId: number) {
    return axios.get(`${environment.apiUri}/user/GenerateEncodeId/${userId}`)
  };
  
 function sendLinkEmail(userId: number) {
    return axios.get(`${environment.apiUri}/user/SendEmailWithLink/${userId}`)
  };
 function sendLinkSms(userId: number) {
    return axios.get(`${environment.apiUri}/user/SendSMSWithLink/${userId}`)
  };
  
