import axios from 'axios';
import environment from '../../environment';
import { Offer } from './models/offer';

export const offerService = {
    get,
    getTypeItem,
    getOfferType,
    add,
    edit
};

 function get() {
    return axios.get(`${environment.apiUri}/offer`)
  };

 function getTypeItem() {
    return axios.get(`${environment.apiUri}/offer/GetTypeItem`)
  };
 function getOfferType() {
    return axios.get(`${environment.apiUri}/offer/GetOfferType`)
  };

  function add(offer: Offer) {
    return axios.post(`${environment.apiUri}/offer`, offer)
  };

  function edit(offer: Offer) {
    return axios.put(`${environment.apiUri}/offer`, offer)
  };
  
  
