export const permissionConstants = {
    GET_PERMISSIONS_REQUEST: 'GET_PERMISSIONS_REQUEST',
    GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
    GET_PERMISSIONS_FAILURE: 'GET_PERMISSIONS_FAILURE',

    ADD_PERMISSION_REQUEST: 'ADD_PERMISSION_REQUEST',
    ADD_PERMISSION_SUCCESS: 'ADD_PERMISSION_SUCCESS',
    ADD_PERMISSION_FAILURE: 'ADD_PERMISSION_FAILURE',

    EDIT_PERMISSION_REQUEST: 'EDIT_PERMISSION_REQUEST',
    EDIT_PERMISSION_SUCCESS: 'EDIT_PERMISSION_SUCCESS',
    EDIT_PERMISSION_FAILURE: 'EDIT_PERMISSION_FAILURE',

    OPEN_MODAL_PERMISSION: 'OPEN_MODAL_PERMISSION',
};