import { VatItemState } from "./models/vat-item.state";
import { vatItemConstants } from "./vat-item.constants";

const initialState: VatItemState = { 
    loading: true, 
    error: "",
    vatItems:[]
};

export function vatItems(state:VatItemState = initialState, action:any) {
    switch (action.type) {
        case vatItemConstants.GET_VAT_ITEM_REQUEST:
            return {
                ...state,
                vatItems: [],
                loading: true
            };
        case vatItemConstants.GET_VAT_ITEM_SUCCESS:
            return {
                ...state,
                vatItems: action.vatItems,    
                loading: false
            };
        case vatItemConstants.GET_VAT_ITEM_FAILURE:
            return {
                ...state,
                vatItems: state.vatItems,    
                loading: false
            };
        
        default:
            return state
    }
}