import { useDispatch, useSelector } from 'react-redux';
import { Suscription } from './models/suscription';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import React from 'react';
import './Suscription.css';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Item } from '../items/models/item';
import { customerActions } from '../customer/actions/customer.actions';
import { DialogSelectCustomer } from '../customer/components/dialog.select-customer';
import { Customer } from '../customer/models/customer';
import { DialogSelectItem } from '../items/components/dialog.select-item';
import { itemActions } from '../items/actions/item.actions';
import { TypeDialog } from '../../helpers/type_dialog';
import { suscriptionActions } from './actions/suscription.actions';
import { format, getDateFormat } from '../../helpers/format';
import { SuscriptionDetail } from './models/suscription-detail';
import { DebounceInput } from 'react-debounce-input';
import { ImageSrc } from '../../components/image-src';
import Swal from 'sweetalert2';
import { DialogPrintPdf } from '../print-pdf/components/dialog.print-pdf';
import { PaymentMethod } from './components/payment-method';
import { DiaryClose } from './components/diary-close';
import { User } from '../settings/users/models/user';

function SuscriptionPage() {
    const suscription: Suscription= useSelector((state:any) => state.suscription.suscription);
    const loading: boolean= useSelector((state:any) => state.suscription.loading);
    const [inputs, setInputs] = React.useState<Suscription>(suscription);
    const user:User  = useSelector((state: any) => state.authentication.user as User);
    const dispatch = useDispatch();
    useEffect(() => {
            setInputs(suscription)
        }, [suscription]);

    const handAdd = () => {
        dispatch(itemActions.openModalSearchItem({
            open : true,
            select : [],
            type : TypeDialog.add,
            loading: false
        }));
    }



    const onSave = () => {
        showPaymentMethod();

        // if((inputs?.detail??[]).length ===0) return;
        
    }

    const onPrintComprobant = () => {
        dispatch(suscriptionActions.printComprobant(inputs.suscriptionId!));
    }
    const onPrintInvoice = () => {
        dispatch(suscriptionActions.printInvoice(inputs.suscriptionId!));
    }
    const onPost = () => {
        if((inputs?.detail??[]).length ===0) return;
       //TODO  dispatch(suscriptionActions.save(inputs));
        Swal.fire({
            title: '¿Deseá facturar?',
            text: 'Se le solicitara eliga su punto de facturación',
            footer: '',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Si',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                
            }
        });
    }
 
    const onClean = () => {
        dispatch(suscriptionActions.cleanSuscription());
    }
 
    const onPreview = () => {
        dispatch(suscriptionActions.preview(inputs.suscriptionId??""));
    }

    const onPrintDiaryClose= () => {
        dispatch(suscriptionActions.openModalDiaryClose({
            open : true,
            select :"",
            type : TypeDialog.add,
            loading: false
        }));
    }

    const onNext = () => {
        dispatch(suscriptionActions.next(inputs.suscriptionId??""));
    }

 

    const searchCustomer = () =>{
        dispatch(customerActions.openModalSearchCustomer({
            open : true,
            select :[],
            type : TypeDialog.add,
            loading: true
        }));
    }
  
    const showPaymentMethod = () =>{
        dispatch(suscriptionActions.openModalPaymentMethod({
            open : true,
            select :suscription.total??0,
            type : TypeDialog.add,
            loading: false
        }));
    }


    const onSelectCustomer = (customer: Customer)=>{
        dispatch(suscriptionActions.selectCustomer(customer.customerId??""));
    }
    const onPaymentMethod = (customer: Customer)=>{
        dispatch(suscriptionActions.selectCustomer(customer.customerId??""));
    }


    const onSelectClosePrint = ()=>{
        
    }


    const onSelectItem = (item: Item)=>{
        dispatch(suscriptionActions.addLine(inputs, item.itemId!));
    }
    const onRemove = (item: SuscriptionDetail)=>{
        dispatch(suscriptionActions.removeLine(inputs, item.itemId!));
    }

        const renderHeader = () => {
                return (
                    <div className="flex justify-content-between align-items-center">
                        <h5>Artículos/Suscripciones</h5>
                        <span className="p-input-icon-left mb-2">
                            <Button  style={{width:"200px"}} disabled={!inputs.customerId || loading || cannotEdit()} label="Agregar artículo" onClick={handAdd} icon="pi pi-plus" iconPos="left"  />
                        </span>
                    </div>
                )
            }
    const cannotEdit = () :boolean =>{

        var is = (inputs.suscriptionId??"").toString().length > 1;
        return is;
    }
    const cannotPost = () :boolean =>{

        var is = (inputs.suscriptionId??"").toString().length > 1 // TODO and DOC NUM;
        return is;
    }
    const header = renderHeader();
 
    const actionBodyTemplate = (item:SuscriptionDetail) => {
         return <span className="p-buttonset">
            <Button className="p-button-danger" icon="pi pi-trash" disabled={cannotEdit()} iconPos="left" onClick={(event:any)=>{onRemove(item) }} />
        </span>

    }

    const priceBodyTemplate = (row:SuscriptionDetail, options:any) => {
        return (
            <span className={options.className}>
                <span>L {format((row.price??0),2)}</span>
            </span>
        )
    }
    const vatBodyTemplate = (row:SuscriptionDetail, options:any) => {
        return (
            <span className={options.className}>
                <span>L {format((row.vat??0),2)}</span>
            </span>
        )
    }
    const totalBodyTemplate = (row:SuscriptionDetail, options:any) => {
        return (
            <span className={options.className}>
                <span>L {format((row.total??0),2)}</span>
            </span>
        )
    }
    
    const hasPermissionToDiaryClose = ()=>{
        var permission = user.permissions.find(x=> x.path == "btn_diary_close");
        return permission;
    }
  
    // const startingDateBodyTemplate = (row:SuscriptionDetail, options:any) => {
    //     if(row.startingDate == null) return <span>No Aplica</span>
    //     let date = new Date(row.startingDate!);
    //     return <span>{date?.toLocaleDateString("es-US")}</span>
    // }
    // const endingDateBodyTemplate = (row:SuscriptionDetail, options:any) => {
    //     if(row.endingDate == null) return <span>No Aplica</span>
    //     let date = new Date(row.endingDate!);
    //     return <span>{date?.toLocaleDateString("es-US")}</span>
    // }
    const quantityBodyTemplate = (row:SuscriptionDetail, options:any) => {
        return (
            <span className={options.className}>

               
                 <DebounceInput
                        placeholder="Cantidad"
                        minLength={1}
                        style={{width:"100%", maxWidth:"80px"}}
                        disabled={cannotEdit()}
                        onChange={(event:any) => {
                            let pattern = /[^1-9]/g;
                            let text= event.target?.value??"";
                            if(!text.match(pattern)){
                                if((event.target?.value??"").length > 0){
                                dispatch(suscriptionActions.editLineQuantity(inputs, row.itemId!, parseFloat(event.target.value)));
                                }
                                else{
                                   event.target.value = row.quantity;
                                }
                            }
                            else{
                                event.target.value = row.quantity;
                            }
                        }} 
                        //onClick={(event:any)=>{event.target?.selectAll(); }}
                        value={row.quantity}
                        keyfilter="int"
                        debounceTimeout={400}
                        element={InputText as any} />
            </span>
        )
    }
    return (
        <div className="card">
          
                <div className="grid">
                        <div className="col-12">
                            <div className="flex justify-content-start">
                                <Button icon="pi pi-arrow-left" loading={loading} disabled={loading} onClick={onPreview} className="p-button-rounded p-button" aria-label="Anterior" />
                                <Button icon="pi pi-arrow-right" loading={loading} disabled={loading} onClick={onNext} className="p-button-rounded p-button" aria-label="Siguiente" />
                                <Button icon="pi pi-print" disabled={!cannotEdit()} loading={loading} onClick={onPrintComprobant} className="p-button-rounded p-button-danger" />
                                <Button icon="pi pi-file" loading={loading} label="Nuevo" onClick={onClean} className="p-button-rounded " />
                                <Button icon="pi pi-save" loading={loading} disabled={cannotEdit() || (inputs?.detail??[]).length ===0 }  label="Guardar" onClick={onSave} className="p-button-rounded p-button-danger" />
                                {
                                    cannotPost()? <Button icon="pi pi-save" loading={loading} disabled={cannotEdit()}  label="Facturar" onClick={onPost} className="p-button-rounded p-button-help" />
                                    :<div/>
                                }
                                {/* <Button icon="pi pi-print" loading={loading} label="Imprimir Factura" onClick={onPrintInvoice} className="p-button-rounded p-button-danger" /> */}

                            </div>
                                <div className="flex justify-content-end">
                                    <Button icon="pi pi-print" loading={loading} disabled={loading || !hasPermissionToDiaryClose()} onClick={onPrintDiaryClose} className="p-button-rounded p-button"  label="Cierre Díario"  aria-label="Anterior" />
                                    {/* <Button icon="pi pi-cart-plus" disabled={true} loading={loading}  onClick={onPreview} className="p-button-rounded  p-button-danger"  label="Gastos"  aria-label="Anterior" /> */}
                                </div>
                            </div>
                        <div className="col-12 mt-2">
                            <h4>Suscripción {inputs.suscriptionId}</h4>
                            <div className="grid">
                                <div className="col-9">
                                    <div className=" grid">
                                        <div className="field col-12 p-inputgroup">
                                            <InputText disabled={true} id="customer" className="block" value={inputs.customer || ""} readOnly={true}   placeholder="Busque un cliente"/>
                                            <Button disabled={cannotEdit()} icon="pi pi-search-plus" onClick={searchCustomer} label="Buscar Cliente"/>
                                        </div>
                                        <div className="field col-4 p-fluid">
                                            <label htmlFor="startingDate" className="block">Fecha Inicio</label>
                                            <InputText id="startingDate"disabled={true} value={getDateFormat(inputs.startingDate)} readOnly={true} className="block"/>
                                        </div>
                                        <div className="field col-4 p-fluid">
                                            <label htmlFor="endingDate" className="block">Fecha Finaliza</label>
                                            <InputText id="endingDate" disabled={true} value={getDateFormat(inputs.endingDate)} readOnly={true} className="block"/>
                                        </div>
                                        <div className="field col-4 p-fluid">
                                            <label htmlFor="daysLeft" className="block">Días restantes</label>
                                            <InputText id="daysLeft" disabled={true} value={(inputs?.leftDays??0).toString()} readOnly={true} className="block"/>
                                        </div>
                                      
                                    </div>

                                   
                                </div>
                                <div className="col-3">
                                    {
                                    (inputs.photoUri) ?
                                       <ImageSrc  height='150px' src={inputs.photoUri} />
                                    : <div></div>
                                    }
                                    
                                </div>


                                 <div className="col-12">
                                            {header}
                                            <DataTable  emptyMessage="Sin resultados" 
                                                    loading={loading} scrollable scrollHeight='300px' showGridlines  height={200}  disabled={loading}
                                                    value={inputs.detail??[]} responsiveLayout="scroll"  
                                                    size="small" stripedRows  >
                                                    <Column  filter filterType="contains" field="itemId" header="No"></Column>
                                                    <Column  filter filterType="contains" field="description" header="Descripción"></Column>
                                                    <Column  field="quantity" header="Cantidad" body={quantityBodyTemplate}></Column>
                                                    {/* <Column  field="startingDate" header="Inicio" body={startingDateBodyTemplate} ></Column>
                                                    <Column  field="endingDate" header="Fin" body={endingDateBodyTemplate}></Column> */}
                                                    <Column  field="days" header="Días"></Column>
                                                    <Column  filterType="contains" field="price" header="Precio" body={priceBodyTemplate}></Column>
                                                    <Column  field="vat" header="Impuesto" body={vatBodyTemplate}></Column>
                                                    <Column  field="total" header="Total" body={totalBodyTemplate}></Column>
                                                    <Column header="Acción" body={actionBodyTemplate} /> 
                                                </DataTable>
                                     
                                        </div>

                                    <div className="col-12">
                                        <div className="grid">
                                            <div className="col-9">
                                                <div className="s-total-body">
                                                    <p className="s-title">Total</p>
                                                    <p className="s-total">L {format(inputs?.total??0,2)}</p>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <p>Descuento: L 0</p>
                                                <p>ISV L {format(inputs?.vat??0,2)}</p>
                                                <p>SubTotal: L {format(inputs?.subTotal??0,2)}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                <DialogSelectCustomer onSelect={onSelectCustomer}/>
                <DialogSelectItem onSelect={onSelectItem}/>
                <DialogPrintPdf onClose={onSelectClosePrint}/>
                <PaymentMethod onClose={onPaymentMethod}/>
                <DiaryClose onClose={()=>{/*NOTHING*/ }}/>
                </div>
        
        </div>
    );
}

export { SuscriptionPage };

