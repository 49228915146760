import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../models/user';
import { DataDialogProps } from '../../../../models/data.dialog.props';
import { Dialog } from 'primereact/dialog';
import { userActions } from '../actions/auth.actions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../../components/SkeletonComponet';
import { alertActions } from '../../../../auth/actions/alert.actions';

  function DialogRestartPassword() {
    const dispatch = useDispatch();    
    const openRestart  = useSelector((state: any) => state.users.openRestart as DataDialogProps<User>);
    const link  = useSelector((state: any) => state.users.link as string);
    const [inputs, setInputs] = React.useState<User>(openRestart?.select);

    useEffect(() => {
         setInputs(openRestart?.select)
    }, [openRestart]);

    const handleClose = () => {
      setInputs(new User())
      dispatch(userActions.openModalRestart({
        open : false,
        select : new User(),
        type : 0,
        loading: false
      }));
    };
  
    function generateLink(){
            dispatch(userActions.generateLink(openRestart.select.userId!));
    }
    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }
    function copyToClipboard(){
        if(link == ""){
             dispatch(alertActions.error("Todavía no se ha generado el link"));
        }
        navigator.clipboard.writeText(link);
        dispatch(alertActions.success("Copiado correctamente"));
    }

    function sendLinkEmail(){
        dispatch(userActions.sendLinkEmail(openRestart.select.userId!));
    }  

    function sendLinkSms(){
        dispatch(userActions.sendLinkSms(openRestart.select.userId!));
    }    

    const footer = (
        <div>
            <Button disabled={openRestart.loading} onClick={sendLinkEmail} label="Mandar por correo" className="p-button-secondary" icon="pi pi-id-card"/>
            <Button disabled={openRestart.loading}  onClick={sendLinkSms} label="Mandar por SMS" className="p-button-danger" icon="pi pi-phone"/>
        </div>
    );

    return (
        <Dialog header="Restablecer contraseña" footer={footer} visible={openRestart?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div>
                {openRestart.loading ? <SkeletonComponent/>:
                <div className="p-fluid grid mt-3">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                 <i className="pi pi-search" />
                                <InputText id="userName" disabled name="userName" value={inputs.userName} onChange={handleChange} />
                                <label htmlFor="userName">Usuario</label>
                            </span>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText id="email" disabled name="email" value={inputs.email} onChange={handleChange} />
                                <label htmlFor="email">Correo Electronico</label>
                            </span>
                        </div>
                         <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText id="phone" disabled name="phone" value={inputs.phone} onChange={handleChange} />
                                <label htmlFor="phone">Celular</label>
                            </span>
                        </div>
                        <div className="field col-12 md:col-12">
                            <div className="p-inputgroup">
                                <Button label="Generar enlace" onClick={generateLink} icon="pi pi-external-link"/>
                                <InputText disabled value={link|| ""} placeholder="El enlace aparecera aquí" />
                                <Button disabled={!link} onClick={copyToClipboard} label="Copiar" icon="pi pi-external-link"/>
                            </div>
                        </div>
                </div>
            }
            </div>
        </Dialog>
    );
  }
    
  export { DialogRestartPassword };