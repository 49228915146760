import axios from 'axios';
import environment from '../../../environment';

export const homeService = {
  getHomeData,
    getCategories
};

 function getHomeData() {
    return axios.get(`${environment.apiUri}/report/Dashboard`)
  };

 function getCategories() {
    return axios.get(`${environment.apiUri}/item/GetCategories`)
  };
