import { TreeNodeDto } from "../../../../models/tree.node.dto";

export class Role {
    roleId?: number;
    name?: string;
    createdBy?: number;
    createdDate?: Date;
    detail?: TreeNodeDto[];
    active?: boolean;
    
    constructor(datos?: Role) {
        if (datos != null) {
        this.roleId = datos.roleId;
        this.name = datos.name;
        this.createdBy = datos.createdBy;
        this.createdDate = datos.createdDate;
        this.active = datos.active;
        this.detail = datos.detail;
        return;
        }
        this.detail= [];
    }
}
