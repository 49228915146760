import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../components/SkeletonComponet';
import { Role } from './models/role';
import { roleActions } from './actions/role.actions';
import { DialogRole } from './components/dialog.role';
import { Badge } from 'primereact/badge';
import { TemplatePaginator } from '../../../components/TemplatePaginator';

function ListRolePage() {
   const roles: Role[] = useSelector((state:any) => state.roles.roles as Role[]);
   const loading: boolean= useSelector((state:any) => state.roles.loading);
    
    const dispatch = useDispatch();


    useEffect(() => { 
        dispatch(roleActions.getRoles()); 
    },[]);

    const handleClose = () => { 
      };

    const addRole = ()=>{    
        dispatch(roleActions.openModalRole({
            open : true,
            select : new Role(),
            type : 0,
            loading: false
        }));
        dispatch(roleActions.getRoleWithDetail(0));
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">Listado de Roles</h5>
                <span className="p-input-icon-left">
                    <Button label="Agregar nuevo" onClick={addRole} icon="pi pi-pencil" iconPos="left"  />
                    {
                        /* 
                            <i className="pi pi-search" />
                            <InputText placeholder="Buscador..." />
                         */
                    }
                </span>
            </div>
        )
    }
    const header = renderHeader();

    const actionBodyTemplate = (rowData:Role) => {
            const showDialog =()=>{
                dispatch(roleActions.openModalRole({
                    open : true,
                    select : rowData,
                    type : 1,
                    loading: false
            }));
            dispatch(roleActions.getRoleWithDetail(rowData.roleId!));
        }
        return  <span className="p-buttonset">
                    <Button label="Editar" icon="pi pi-pencil" iconPos="left" onClick={showDialog} />
                </span>
    }

    const statusBodyTemplate = (rowData:Role) => {
        return <Badge value={rowData.active? "Activo" : "Inactivo"} severity={rowData.active ? undefined: "danger"} ></Badge>
    }
    const dateBodyTemplate = (rowData:Role) => {
        let date = new Date(rowData.createdDate!);
        return <span>{date?.toLocaleDateString("es-HN")}</span>
    }
    return (    
    <div className="grid mt-1">
        <div className="col-12 card">
            {loading ? <SkeletonComponent/> :
              <DataTable  emptyMessage="Sin resultados" value={roles} paginatorTemplate={TemplatePaginator as any} 
                    responsiveLayout="scroll"  rows={10}  size="small" stripedRows header={header} >
                    <Column field="roleId" header="No"></Column>
                    <Column field="name" header="Nombre"></Column>
                    <Column field="createdDate" header="Fecha Creación" body={dateBodyTemplate}></Column>
                    <Column field="active" body={statusBodyTemplate} header="Estado"></Column>
                    <Column header="Acción" body={actionBodyTemplate} />
                </DataTable>
            }
            <DialogRole onClose={handleClose}></DialogRole>
        </div>
    </div>
    );
}

export { ListRolePage };