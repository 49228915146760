import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDialogProps } from '../../../../models/data.dialog.props';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { SkeletonComponent } from '../../../../components/SkeletonComponet';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Permission } from '../../../../auth/models/permission';
import { permissionActions } from '../actions/permission.actions';
import { Dropdown } from 'primereact/dropdown';

  function DialogPermission() {
    const dispatch = useDispatch();    
    const openPermission  = useSelector((state: any) => state.permissions.openPermission as DataDialogProps<Permission>);
    const [inputs, setInputs] = React.useState<Permission>(openPermission?.select);
    const [submitted, setSubmitted] = React.useState(false);

     useEffect(() => {
         setInputs(openPermission?.select)
         setSubmitted(false)
    }, [openPermission]);

    const typeSelectItems = [
        {label: 'Seleccione un Tipo', value: '0'},
        {label: 'Pantalla', value: '1'},
        {label: 'Boton', value: '2'},
    ];


    const handleClose = () => {
      setInputs(new Permission())
      dispatch(permissionActions.openModalPermission({
        open : false,
        select : new Permission(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };
  
    function handleChangeCheck(e:any)  {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: e.target.checked  }));
    };
    

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.name && inputs.icon && inputs.path) {
          if(openPermission?.type ===0){
            dispatch(permissionActions.add(inputs));
          }
          if(openPermission?.type ===1){
            dispatch(permissionActions.edit(inputs));
          }
      }
    }

  function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }
   const footer = (
        <div>
            <Button disabled={openPermission.loading} onClick={handleSubmit} label={openPermission?.type ===0 ? "Agregar": "Editar"} icon="pi pi-check"/>
        </div>
    );


    return (
        <Dialog header={openPermission?.type ===0 ? "Agregar": "Editar"} footer={footer} visible={openPermission?.open??false} position="top" onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '40vw'}}>
            <div>
                <div className="p-fluid grid mt-3"> 
                     {openPermission.loading ? <SkeletonComponent/> : 
                        <React.Fragment>
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText id="name" name="name" value={inputs?.name || ""} onChange={handleChange} />
                                    <label htmlFor="name">Descripción</label>
                                </span>
                            </div>
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label p-input-icon-left">
                                    <Dropdown value={inputs?.typeId?.toString() || ""}  name="typeId" options={typeSelectItems} onChange={handleChange} placeholder="Seleccione un tipo"/>
                                    <label htmlFor="path">Tipo</label>
                                </span>
                            </div>
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label p-input-icon-left">
                                    <i className="pi pi-directions-alt" />
                                    <InputText id="path" name="path" value={inputs?.path || ""} onChange={handleChange} />
                                    <label htmlFor="path">Ruta</label>
                                </span>
                            </div>
                            <div className="field col-12 md:col-6">
                                <span className="p-float-label p-input-icon-left">
                                    <i className="pi pi-th-large" />
                                    <InputText id="icon" name="icon" value={inputs?.icon || ""} onChange={handleChange} />
                                    <label htmlFor="path">Icono</label>
                                </span>
                            </div>
                            {
                                openPermission?.type == 1?
                                    <div className="col-12 md:col-12">  
                                        <Checkbox inputId="cbActive" onChange={handleChangeCheck} name="active" checked={inputs?.active??false}></Checkbox>
                                        <label htmlFor="cbActive" className="p-checkbox-label">Activo</label>
                                    </div>
                                : <div></div>
                            }
                        </React.Fragment>
                     }                   
                </div>
            </div>
        </Dialog>
    );
  }
    
  export { DialogPermission };