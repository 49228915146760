export class Permission {
    permissionId?: number;
    name?: string;
    typeId?: number;
    fatherId?: number;
    path?: string;
    icon?: string;
    position?: number;
    companyId?: string;
    active?: boolean;
    createdDate?: Date;
    createdBy?: number;
    
    constructor(datos?: Permission) {
        if (datos != null) {
        this.name = datos.name;
        this.typeId = datos.typeId;
        this.fatherId = datos.fatherId;
        this.path = datos.path;
        this.position = datos.position;
        this.permissionId = datos.permissionId;
        this.icon = datos.icon;
        this.companyId = datos.companyId;
        this.active = datos.active;
        this.createdDate = datos.createdDate;
        this.createdBy = datos.createdBy;
        return;
        }
    }
    
}
