import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DiarySales } from './models/diary_sales';
import { reportActions } from './actions/report.actions';
import 'react-pivottable/pivottable.css';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import { DataTable } from 'primereact/datatable';
import { DebounceInput } from 'react-debounce-input';
import { InputText } from 'primereact/inputtext';
import { TemplatePaginator } from '../../components/TemplatePaginator';
import { ImageSrc } from '../../components/image-src';
import { CustomerLoss } from './models/customer_loss';
import Logo from'../../assets/images/logo.png';

import { SelectButton } from 'primereact/selectbutton';

interface CustomerLossPageProps {
    message: string;
  }

const CustomerLossPage : React.FC<CustomerLossPageProps> = ({ message }) => {
    const customerLossInternal: DiarySales[]= useSelector((state:any) => state.reports.customersLoss);
    const loading: boolean= useSelector((state:any) => state.reports.loading);
    const [fromDate, setFromDate] = React.useState<Date>();
    const [submit, setSubmit] = React.useState<boolean>(false);
    const [pivotState, setPivotState] = React.useState();
    const dispatch = useDispatch();
    const options = [{value:'0.2', name:"Última semana"}, {value:'1', name:"Último Mes"},{value:'2', name:'Últimos 2 Meses'} ,{value:'6', name:'Últimos 6 Meses'} ,{value:'100', name:'Todos'}];
    const [value, setValue] = React.useState();
     const [customersLoss, setCustomersLoss] = React.useState<DiarySales[]>(customerLossInternal);

    const handSearch = () => {

        setSubmit(true)
        dispatch(reportActions.searchCustomersLoss(value??1));
    }
     useEffect(() => {
        
        setCustomersLoss(customerLossInternal);
    },[customerLossInternal]);

    console.log(message)
 
 


        // const renderHeader = () => {
        //         return (
        //             <div className="flex justify-content-between align-items-center">
        //                 <h5>Articulos/Suscripciones</h5>
        //                 <span className="p-input-icon-left mb-2">
        //                     <Button  style={{width:"200px"}} disabled={!inputs.customerId || loading || cannotEdit()} label="Agregar" onClick={handAdd} icon="pi pi-plus" iconPos="left"  />
        //                 </span>
        //             </div>
        //         )
        //     }
    
 
    // const header = renderHeader();
 
   

   function handleFromDate(e:any) {
        const { name, value } = e.target;
        setFromDate(value);

   }

   const renderHeader = () => {
    return (
        <div className="flex justify-content-between align-items-center">
                <span className="p-input-icon-left" >
                { loading ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                 <DebounceInput
                    placeholder="Buscar"
                    minLength={1}
                    style={{width:"100%"}}
                    onChange={event => {
                        // if((event.target?.value??"").length > 1){
                        //     dispatch(customerActions.searchByDescription(event.target.value));
                        // }
                    }} 
                    value=""
                    debounceTimeout={400}
                    element={InputText as any} />
            </span>
              <span className="p-input-icon-left">
                {/* <Button label="Agregar nuevo" onClick={handAdd} icon="pi pi-pencil" iconPos="left"  /> */}
            </span>
        </div>
    )
}

const photoBodyTemplate = (customer: CustomerLoss)=>{
    return  (customer.photoUri) ?
        <ImageSrc height="40px" width="60px" src={customer.photoUri} />
        :<img height="40px" width="40px"src={ Logo || ""} alt="" />
    }

      const endingDateBodyTemplate = (row:CustomerLoss, options:any) => {
        if(row.endingDate == null) return <span>No Aplica</span>
        let date = new Date(row.endingDate!);
        return <span>{date?.toLocaleDateString("es-US")}</span>
    }

   const header = renderHeader();
    return (
        <div className="card">
            <div className="p-fluid grid">
                 <div className="field col-6">                
                 
                        <div className="card flex justify-content-center">
                            <SelectButton value={value} onChange={(e) => setValue(e.value)} optionValue='value' optionLabel="name" options={options} />
                        </div>
                </div>
   
                <div className=" col-2 mt-5">
                    <Button  label="Buscar" data-testid="btn-buscar" onClick={handSearch} disabled={!value} icon="pi pi-search" iconPos="left"  />
                </div>
                 {loading ? <SkeletonComponent/> :
                <div className="col-12">
                    {/* {header} */}
                    {/* <DataTable  emptyMessage="Sin resultados" 
                        loading={loading} scrollable scrollHeight='500px' showGridlines  height={200}  disabled={loading}
                        value={diarySales??[]} responsiveLayout="scroll"  
                        size="small" stripedRows  headerColumnGroup={headerGroup} >
                            <Column  filter filterMatchMode="contains"  filterType="contains" field="createdDateString" header="Fecha"></Column>
                            <Column  filter filterMatchMode="contains"  filterType="contains" field="subTotal" header="Sub Total"></Column>
                            <Column  filter filterMatchMode="contains"  filterType="contains" field="vat" header="Impuesto"></Column>
                            <Column  filter filterMatchMode="contains"  filterType="contains" field="total" header="Total"></Column>
                        </DataTable> */}

                    {/* <WebDataRocksReact.Pivot 
         toolbar={true}
        // componentFolder="https://cdn.webdatarocks.com/"
         width="100%"
        
        report={{ dataSource: {
			data:diarySales??[],
		}, localization: "https://cdn.webdatarocks.com/loc/es.json"}}
		
        /> */}
        <div className="grid mt-3">
                <div className="card col-12">
                    {/* {header} */}
                   <DataTable  emptyMessage="Sin resultados" paginatorTemplate={TemplatePaginator as any} 
                        loading={loading} paginator rows={10}  disabled={loading}
                        value={customersLoss??[]} responsiveLayout="scroll"  
                        size="small" stripedRows  >
                        <Column filter filterType="contains" field="photoId" header="Foto" body={photoBodyTemplate}></Column>
                        <Column filter filterType="contains" field="customerId" header="No"></Column>
                        <Column sortable filter filterType="contains" field="name" header="Nombre"></Column>
                        <Column sortable filter filterType="contains" field="dni" header="Identidad" ></Column>
                        <Column sortable filter filterType="contains" field="phone" header="Teléfono"></Column>
                        <Column sortable filter filterType="contains" field="endingDate" header="Última compra" body={endingDateBodyTemplate} ></Column>
                        <Column sortable filter hidden filterType="contains" field="email" header="Correo" ></Column>
                        {/* <Column header="Acción" body={actionBodyTemplate} /> */}
                    </DataTable>
                </div>
        </div>            
                </div>
                }
            </div>
        </div>
    );
}

export {CustomerLossPage};

