import axios from 'axios';
import environment from '../../environment';
import { FileUpload } from './models/file_upload';

export const filepickerService = {
    uploadFile
};


  function uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);
  
    return axios.post<FileUpload>(environment.apiUri + `/Files/UploadFile/`, formData)
  };

