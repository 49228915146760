import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DialogProps } from '../../../models/dialog.props';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { TypeDialog } from '../../../helpers/type_dialog';

import { suscriptionActions } from '../actions/suscription.actions';
import { User } from '../../settings/users/models/user';
import { Suscription } from '../models/suscription';
import { Fieldset } from 'primereact/fieldset';
import { Calendar } from 'primereact/calendar';


  function DiaryClose(props: DialogProps<string>) {
    const { onClose} = props;
    const dispatch = useDispatch();    
    const openDiaryClose  = useSelector((state: any) => state.suscription.openDiaryClose as DataDialogProps<number>);
    const suscription: Suscription= useSelector((state:any) => state.suscription.suscription);
    const [inputs, setInputs] = useState<Date | undefined>(new Date(new Date().toLocaleDateString()));
    const user:User  = useSelector((state: any) => state.authentication.user as User);

    const handleClose = () => {
      dispatch(suscriptionActions.openModalDiaryClose({
        open : false,
        select :"",
        type : TypeDialog.add,
        loading: false
      }));
    };
 

    useEffect(() => {
        setInputs(new Date(new Date().toLocaleDateString()))
   }, [openDiaryClose]);


    function handleSubmit(e:any) {
        e.preventDefault();
        dispatch(suscriptionActions.printDiaryClose(inputs?.toISOString() ??"" ));
    }

    
    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs( value );
    }



    const footer = (
        <div>
           <Button loading={openDiaryClose.loading} disabled={openDiaryClose.loading || !inputs} label="Buscar" onClick={handleSubmit} icon="pi pi-seach"/>
        </div>
    );

    const getMinDate = ()=>{
        let result = new Date();
        result.setDate(result.getDate() -5);
        return result;
    }
   
    return (
        <Dialog  header={"Cierre diario"}  footer={footer} visible={openDiaryClose?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '45vw'}}>
            <div className='p-fluid grid mt-2'>   

                <div className="col-12">
                    <div className="grid">                               
                                <div className="col-12">
                                    <Fieldset legend="Fecha">
                                       <div className="p-inputgroup flex-1">

                       
                                       
                                            {/* <InputText id="endingDate" name="endingDate" value={} onChange={handleChange} className={(!inputs?.endingDate) ? "block p-invalid":"block"}/> */}
                                            <Calendar disabled={openDiaryClose?.loading} id="endingDate"  name="endingDate" value={inputs || undefined} maxDate={new Date()} minDate={getMinDate()} onChange={handleChange}></Calendar>
                                            <br />
                                            {/* {(!inputs) ?<small id="endingDate-help" className="p-error">Ingrese la fecha final</small>: <></> } */}
                                       

                                        </div>
                                    </Fieldset>
                                

                        </div>
                    </div>
                </div>      
            </div>
        </Dialog>
    );
  }
    
  export { DiaryClose };