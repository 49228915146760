import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataDialogProps } from '../../../models/data.dialog.props';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DialogProps } from '../../../models/dialog.props';
import { InputText } from 'primereact/inputtext';
import {DebounceInput} from 'react-debounce-input';
import { Category } from '../models/category';
import { categoryActions } from '../actions/category.actions';
import { TemplatePaginator } from '../../../components/TemplatePaginator';

  function DialogSelectCategory(props: DialogProps<Category>) {
    const { onClose} = props;
    const dispatch = useDispatch();    
    const openSelectCategory  = useSelector((state: any) => state.categories.openSelectCategory as DataDialogProps<Category[]>);

    const handleClose = () => {
      dispatch(categoryActions.openModalSearchCategory({
        open : false,
        select :[],
        type : 0,
        loading: false
      }));
    };

     const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                    <span className="p-input-icon-left" style={{width:"100%"}}>
                    { openSelectCategory.loading ? <i className="pi pi-spin pi-spinner" />: <i className="pi pi-search" /> }
                     <DebounceInput
                        placeholder="Buscar"
                        minLength={1}
                        style={{width:"100%"}}
                        onChange={event => {
                            if((event.target?.value??"").length > 1){
                                dispatch(categoryActions.searchCategoryByDescription(event.target.value));
                            }
                        }} 
                        value=""
                        debounceTimeout={400}
                        element={InputText as any} />
                </span>
                {/* <h5 className="m-0">{ "Seleccione un tipo de item"}</h5>
                    <Button style={{width: "170px"}} label={"Agregar " + typeItem} icon="pi pi-plus" /> */}
            </div>
        )
    }

    const handOnSelectItem = (category: Category)=>{
        onClose!(category);
        handleClose();
    }

    const header = renderHeader();
  
    return (
        <Dialog header={"Listado de Categorías"} position="top" visible={openSelectCategory?.open??false} onHide={handleClose} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '55vw'}}>
            <div>  
                <DataTable  emptyMessage="Sin resultados" loading={openSelectCategory.loading} 
                    paginatorTemplate={TemplatePaginator as any} rows={10} disabled={openSelectCategory.loading} selectionMode="single" 
                    value={openSelectCategory.select} responsiveLayout="scroll" 
                    onSelectionChange={e => handOnSelectItem(e.value)} size="small" stripedRows header={header} >
                    <Column field="categoryId" header="No"></Column>
                    <Column field="description" header="Nombre"></Column>
                    {/* <Column field="active" body={statusBodyTemplate} header="Estado"></Column>
                    <Column header="Acción" body={actionBodyTemplate} /> */}
                </DataTable>
            </div>
        </Dialog>
    );
  }
    
  export { DialogSelectCategory };