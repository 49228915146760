export const userConstants = {
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

    GENERATE_LINK_REQUEST: 'GENERATE_LINK_REQUEST',
    GENERATE_LINK_SUCCESS: 'GENERATE_LINK_SUCCESS',
    GENERATE_LINK_FAILURE: 'GENERATE_LINK_FAILURE',

    SEND_LINK_EMAIL_REQUEST: 'SEND_LINK_EMAIL_REQUEST',
    SEND_LINK_EMAIL_SUCCESS: 'SEND_LINK_EMAIL_SUCCESS',
    SEND_LINK_EMAIL_FAILURE: 'SEND_LINK_EMAIL_FAILURE',

    SEND_LINK_SMS_REQUEST: 'SEND_LINK_SMS_REQUEST',
    SEND_LINK_SMS_SUCCESS: 'SEND_LINK_SMS_SUCCESS',
    SEND_LINK_SMS_FAILURE: 'SEND_LINK_SMS_FAILURE',

    

    OPEN_MODAL_USER: 'OPEN_MODAL_USER',
    OPEN_MODAL_RESTART: 'OPEN_MODAL_RESTART',
};