import { WeekDataDetail } from "./week-data-detail";
import { WeekDataWeek } from "./week-data-week";

export class WeekData { 
    title?: string;
    date?: number;
    detail?: WeekDataDetail[];
    weeks?: WeekDataWeek[];
    
    constructor(datos?: WeekData) {
        if (datos != null) {
        this.title = datos.title;
        this.date = datos.date;
        this.detail = datos.detail;
        this.weeks = datos.weeks;
        return;
    }
    this.detail = [];
    this.weeks = [];
    }
}