import { Menu } from "../../../../auth/models/menu";
import { Permission } from "../../../../auth/models/permission";

export class User {
    userId?: number;
    name?: string;
    userName?: string;
    password?: string;
    customerId?:string;
    customer?: string;
    email?: string;
    phone?: string;
    role?: string;
    company?: string;
    companyId?: string;
    logoUri?: string;
    homePath?: string;
    roleId?: number;
    storeId?: number;
    documentSize?: number;
    active?: boolean;
    createdBy?: number;  
    createdDate?: Date;
    permissions: Permission[];
    menu: Menu[];



    constructor(datos?: User) {
        if (datos != null) {
        this.userId = datos.userId;
        this.name = datos.name;
        this.userName = datos.userName;
        this.password = datos.password;
        this.role = datos.role;
        this.roleId = datos.roleId;
        this.customer = datos.customer;
        this.customerId = datos.customerId;
        this.email = datos.email;
        this.phone = datos.phone;
        this.storeId = datos.storeId;
        this.homePath = datos.homePath;
        this.documentSize = datos.documentSize;
        this.active = datos.active;
        this.logoUri = datos.logoUri;
        this.company = datos.company;
        this.companyId = datos.companyId;
        this.permissions = datos.permissions;
        this.menu = datos.menu;
        this.createdBy = datos.createdBy;
        this.createdDate = datos.createdDate;
        return;
        }
    this.permissions = [];
    this.menu = [];
    }
}
