import { durationConstants } from "./duration.constants";
import { DurationState } from "./models/duration.state";

const initialState: DurationState = { 
    loading: true, 
    error: "",
    durations:[]
};

export function durations(state:DurationState = initialState, action:any) {
    switch (action.type) {
        case durationConstants.GET_DURATION_REQUEST:
            return {
                ...state,
                durations: [],
                loading: true
            };
        case durationConstants.GET_DURATION_SUCCESS:
            return {
                ...state,
                durations: action.durations,    
                loading: false
            };
        case durationConstants.GET_DURATION_FAILURE:
            return {
                ...state,
                durations: state.durations,    
                loading: false
            };
        
        default:
            return state
    }
}