import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SkeletonComponent } from '../../components/SkeletonComponet';
import { itemActions } from './actions/item.actions';
import { Item } from './models/item';
import { Galleria } from 'primereact/galleria';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { format } from '../../helpers/format';

function ItemDetailPage() {
    const loading: boolean= useSelector((state:any) => state.items.loading);
    const item  = useSelector((state: any) => state.items.item as Item);
    const { id } = useParams(); 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if(id == undefined || id== ""){
            navigate("/home");
            return;
        }
        dispatch(itemActions.searchItemById(id));
    }, [id]);

const responsiveOptions = [
       {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

 const thumbnailTemplate = (item:any) => {
        return <img src={item} onError={(e: any) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{ width: '100%',  maxHeight:'60px', display: 'block' }} />
    }

   const itemTemplate = (item:any) => {
        return <img src={item} onError={(e:any) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{ width: '100%', display: 'block' }} />
    }
    return (
        <div className="card">
            {loading ? <SkeletonComponent/> :
                <div className="p-fluid grid  mt-3">
                    {/* <div className="col-12 md:col-6 xl:col-6">
                        <Galleria value={(item?.images??[]).map((item)=> item.uri)} responsiveOptions={responsiveOptions} thumbnailsPosition="left" numVisible={2} style={{ maxWidth: '100%', }}
                            item={itemTemplate} thumbnail={thumbnailTemplate} />
                    </div> */}
                    <div className="col-12 md:col-6 xl:col-6 item-detail">
                        <h4>{item.name}</h4>
                        <p>Código del Producto</p>
                        <h6>{item.itemId}</h6>
            
                        <div className="grid">
                            <div className="col-6">
                                <div className="item-price">
                                    {/* <p className="sub-title-price">Precio Anterior</p>
                                    <span className="last-price">L { format(item?.priceVat??0,2)}</span>
                                    <p className="title-price">Precio Oferta</p>
                                    <h3 className="new-price">L { format(item?.priceDiscount??0,2)}</h3> */}
                                </div>
                            </div>

                            {/* <div className="col-6">
                                <h5>Stock</h5>
                                <h3>{item.stock}</h3>
                            </div> */}
                        </div>
                        <div className="grid">
                            <div className="col-4 quantity">
                                {/* <label htmlFor="horizontal">Cantidad</label> */}
                                <InputNumber inputId="horizontal" value={3} onValueChange={(e) => console.log(e)} showButtons buttonLayout="horizontal" step={1}
                                    decrementButtonClassName="p-button" incrementButtonClassName="p-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                            </div>
                            <div className="col-6">
                                <Button  label="Agregar" icon="pi pi-check" />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <br /><br /><br /><br /><br />
        </div>
       
    );
}

export { ItemDetailPage };