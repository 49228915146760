export const itemConstants = {
    GET_ITEM_REQUEST: 'GET_ITEM_REQUEST',
    GET_ITEM_SUCCESS: 'GET_ITEM_SUCCESS',
    GET_ITEM_FAILURE: 'GET_ITEM_FAILURE',

    EDIT_ITEM_REQUEST: 'EDIT_ITEM_REQUEST',
    EDIT_ITEM_SUCCESS: 'EDIT_ITEM_SUCCESS',
    EDIT_ITEM_FAILURE: 'EDIT_ITEM_FAILURE',

    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',


    SEARCH_ITEM_SELECT_BY_DESCRIPTION_REQUEST: 'SEARCH_ITEM_SELECT_BY_DESCRIPTION_REQUEST',
    SEARCH_ITEM_SELECT_BY_DESCRIPTION_SUCCESS: 'SEARCH_ITEM_SELECT_BY_DESCRIPTION_SUCCESS',
    SEARCH_ITEM_SELECT_BY_DESCRIPTION_FAILURE: 'SEARCH_ITEM_SELECT_BY_DESCRIPTION_FAILURE',

    SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_REQUEST: 'SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_REQUEST',
    SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_SUCCESS: 'SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_SUCCESS',
    SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_FAILURE: 'SEARCH_ITEM_SELECT_BY_DESCRIPTION_LIST_FAILURE',

    SEARCH_ITEM_BY_ID_REQUEST: 'SEARCH_ITEM_BY_ID_REQUEST',
    SEARCH_ITEM_BY_ID_SUCCESS: 'SEARCH_ITEM_BY_ID_SUCCESS',
    SEARCH_ITEM_BY_ID_FAILURE: 'SEARCH_ITEM_BY_ID_FAILURE',


    OPEN_MODAL_SEARCH_ITEM_REQUEST: 'OPEN_MODAL_SEARCH_ITEM_REQUEST',
    OPEN_MODAL_SEARCH_ITEM_SUCCESS: 'OPEN_MODAL_SEARCH_ITEM_SUCCESS',
    OPEN_MODAL_SEARCH_ITEM_FAILURE: 'OPEN_MODAL_SEARCH_ITEM_FAILURE',

    OPEN_MODAL_ITEM: 'OPEN_MODAL_ITEM',
};