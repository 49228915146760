import { Skeleton } from 'primereact/skeleton';

function SkeletonComponent() {
    return (
        <div className="field col-12 md:col-12">
            <Skeleton className="mb-2"></Skeleton>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height="2rem" className="mb-2"></Skeleton>
            <Skeleton width="10rem" height="4rem"></Skeleton>
        </div>   
    );
}

export { SkeletonComponent };